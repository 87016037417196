import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../../config";
const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Customer = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [addCustomer, setAddCustomer] = useState([]);
  const [customerToDelete, setcustomerToDelete] = useState(null);
  const [editCustomer, seteditCustomer] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [AddCustomer, setAddNewCustomer] = useState({
    name: "",
    f_name: "",
    cnic: "",
    phone: "",
    whatsapp: "",
    city: "",
    email: "",
    gender: "",
    dob: "",
    image: null,
    document: null,
    address: "",
  });

  function tog_list() {
    setmodal_list(!modal_list);
  }

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };

  const token = sessionStorage.getItem("token");
  const groupId = sessionStorage.getItem("groupId");
  const loginId = sessionStorage.getItem("login");


  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddNewCustomer({ ...AddCustomer, [name]: value });
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setAddNewCustomer((prevCustomer) => ({
        ...prevCustomer,
        image: file,
      }));
    }
  };

  const handleDocumentChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setAddNewCustomer((prevCustomer) => ({
        ...prevCustomer,
        document: file,
      }));
    }
  };

  // Fetch Categorys data when the component mounts
  const fetchCustomerData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomers(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };
  useEffect(() => {
    fetchCustomerData();
  }, []);

  // Save data in database
  const handleAddCustomer = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);



    if (!loginId) {
      console.error("User not logged in.");
      // Handle the case where the user is not logged in
      return;
    }

    if (!hasPermission("add_account")) {
      console.error("User does not have permission to add an Account.");
      return;
    }

    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("name", AddCustomer.name);
    formData.append("f_name", AddCustomer.f_name);
    formData.append("cnic", AddCustomer.cnic);
    formData.append("phone", AddCustomer.phone);
    formData.append("whatsapp", AddCustomer.whatsapp);
    formData.append("city", AddCustomer.city);
    formData.append("email", AddCustomer.email);
    formData.append("gender", AddCustomer.gender);
    formData.append("dob", AddCustomer.dob);
    formData.append("address", AddCustomer.address);

    if (AddCustomer.image !== null) {
      formData.append("image", AddCustomer.image);
    }
    if (AddCustomer.document !== null) {
      formData.append("document", AddCustomer.document);
    }

    try {
      const response = await fetch(`${baseUrl}/accounts/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        setAddCustomer([...addCustomer, data]);
        setAddNewCustomer({
          name: "",
          f_name: "",
          cnic: "",
          phone: "",
          whatsapp: "",
          city: "",
          email: "",
          gender: "",
          dob: "",
          image: null,
          document: null,
          address: "",
        });
        setmodal_list(false);
        fetchCustomerData();
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        console.error("Failed to add the Customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the Customer:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // fetch data to edit customer

  const handleEditCustomer = async (customer) => {
    try {
      const response = await fetch(`${baseUrl}/accounts/${customer.id}`);
      if (response.ok) {
        const data = await response.json();

        // Set previous image and document URLs

        // Update the editCustomer state
        seteditCustomer(data);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the customer for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the customer:", error);
    }
  };

  // fecth data to Save edit or update
  const handleSaveEdit = async () => {
    if (!editCustomer || !hasPermission("change_block")) return;

    const formData = new FormData();
    // Include login in the form data
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("name", editCustomer.name);
    formData.append("f_name", editCustomer.f_name);
    formData.append("cnic", editCustomer.cnic);
    formData.append("phone", editCustomer.phone);
    formData.append("whatsapp", editCustomer.whatsapp);
    formData.append("city", editCustomer.city);
    formData.append("email", editCustomer.email);
    formData.append("dob", editCustomer.dob);
    formData.append("gender", editCustomer.gender);
    formData.append("address", editCustomer.address);

    // Check if a new document is selected, if not, append the existing document
    if (editCustomer.document instanceof File) {
      formData.append("document", editCustomer.document);
    }

    // Check if a new image is selected, if not, append the existing image
    if (editCustomer.image instanceof File) {
      formData.append("image", editCustomer.image);
    }

    try {
      const response = await fetch(`${baseUrl}/accounts/${editCustomer.id}/`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        const updatedCustomerData = await response.json();
        console.log("Customer updated:", updatedCustomerData);

        setCustomers((prevCustomers) =>
          prevCustomers.map((customer) =>
            customer.id === updatedCustomerData.id
              ? updatedCustomerData
              : customer
          )
        );

        setmodal_edit(false);
        seteditCustomer(null);
      } else {
        console.error("Failed to edit the Customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the Customer:", error);
    }
  };

  // Delete Customer

  const handleDeleteCustomer = async (customer) => {
    if (customer) {
      try {
        const response = await fetch(`${baseUrl}/accounts/${customer.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("Customer Deleted:", customer.id);

          // Remove the deleted customer from the customers state
          setCustomers((prevcustomer) =>
            prevcustomer.filter((c) => c.id !== customer.id)
          );

          setmodal_delete(false);
          setShowAlertDelete(true);

          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the Customer:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the Customer:", error);
      }
    }
  };

  // Print Function
  const navigate = useNavigate();
  const handlePrint = () => {
    // Pass the data to the Print Customer list component
    navigate("/customer/customer-print/", { state: { data: customers } });
  };
  const handlePrintCustomer = (customer) => {
    navigate("/customer/single-customer-print/", {
      state: { customer: customer },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Customer" breadcrumbItem=" Customer Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">Customer</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {hasPermission("add_account") && (
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => setmodal_list(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Customer
                            </Button>
                          )}
                          <Button
                            color="primary"
                            className="add-btn "
                            onClick={handlePrint}
                          >
                            <span class="mdi mdi-printer"></span>
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="code">
                              Customer Name
                            </th>
                            <th className="sort" data-sort="name">
                              Father Name
                            </th>
                            <th className="sort" data-sort="credi_limit">
                              CNIC
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Phone
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Email
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Image
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Document
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {customers.map((customer, index) => (
                            <tr key={customer.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="name">{customer.name}</td>
                              <td className="f_name">{customer.f_name}</td>
                              <td className="name">{customer.cnic}</td>
                              <td className="name">{customer.phone}</td>
                              <td className="name">{customer.email}</td>
                              <td className="img">
                                <img
                                  src={customer.image}
                                  alt="Customer"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    marginTop: "20px",
                                  }}
                                />
                              </td>
                              <td className="img">
                                <img
                                  src={customer.document}
                                  alt="Customer"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    marginTop: "20px",
                                  }}
                                />
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <Button
                                    color="primary"
                                    className="add-btn "
                                    onClick={() =>
                                      handlePrintCustomer(customer)
                                    }
                                  >
                                    <span class="mdi mdi-printer"></span>
                                  </Button>
                                  {hasPermission("change_account") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => {
                                          handleEditCustomer(customer);
                                        }}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {hasPermission("delete_account") && (
                                    <Button
                                      className="btn btn-sm btn-danger edit-item-btn"
                                      onClick={() => {
                                        setcustomerToDelete(customer);
                                        setmodal_delete(true);
                                      }}
                                      color="soft-danger"
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}

      <Modal
        isOpen={modal_list}
        toggle={tog_list}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          Add Customer
        </ModalHeader>
        <form
          className="tablelist-form"
          encType="multipart/form-data"
          onSubmit={handleAddCustomer}
        >
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-4">
                <label htmlFor="name-field" className="form-label">
                  Customer Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  className="form-control"
                  placeholder="Enter Customer Name"
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-sm-4">
                <label htmlFor="f_name-field" className="form-label">
                  Father Name
                </label>
                <input
                  type="text"
                  id="f_name"
                  name="f_name"
                  className="form-control"
                  placeholder="Enter Father Name"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-4">
                <label htmlFor="f_name-field" className="form-label">
                  CNIC#
                </label>
                <input
                  type="text"
                  id="cnic"
                  name="cnic"
                  className="form-control"
                  placeholder="Enter CNIC"
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-3">
                <label htmlFor="f_name-field" className="form-label">
                  Phone#
                </label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  className="form-control"
                  placeholder="Enter Phone"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="whatsapp-field" className="form-label">
                  Whatsapp
                </label>
                <input
                  type="text"
                  id="whatsapp"
                  name="whatsapp"
                  className="form-control"
                  placeholder="Enter Whatsapp"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="whatsapp-field" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  className="form-control"
                  placeholder="Enter City"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="name-field" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email-field"
                  name="email"
                  className="form-control"
                  placeholder="Enter Email"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-2">
                <label htmlFor="gender-field" className="form-label">
                  Gender
                </label>
                <select
                  className="form-control"
                  name="gender"
                  id="gender-field"
                  onChange={handleInputChange}
                  value={AddCustomer.gender}
                >
                  <option value="">Select</option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                  <option value="O">Other</option>
                </select>
              </div>
              <div className="col-sm-3">
                <label htmlFor="dob-field" className="form-label">
                  DOB
                </label>
                <input
                  type="date"
                  id="dob"
                  name="dob"
                  className="form-control"
                  placeholder="Enter Date Birth"
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-7">
                <label htmlFor="name-field" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="address-field"
                  name="address"
                  className="form-control"
                  placeholder="Enter Address"
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-6">
                <label htmlFor="image-field" className="form-label">
                  Add Image
                </label>
                <input
                  type="file"
                  id="image"
                  name="image" // Ensure the 'name' attribute corresponds to the state key
                  className="form-control"
                  onChange={handleImageChange}
                />
                {/* Display image preview if available */}
                {AddCustomer.imagePreview && (
                  <img
                    src={AddCustomer.imagePreview}
                    alt="Image Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      marginTop: "20px",
                    }}
                  />
                )}
              </div>

              <div className="col-sm-6">
                <label htmlFor="document-field" className="form-label">
                  Add Document
                </label>
                <input
                  type="file"
                  id="document"
                  name="document" // Ensure the 'name' attribute corresponds to the state key
                  className="form-control"
                  onChange={handleDocumentChange}
                />
                {/* Display document preview if available */}
                {AddCustomer.documentPreview && (
                  <img
                    src={AddCustomer.documentPreview}
                    alt="Document Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      marginTop: "20px",
                    }}
                  />
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={!hasPermission("add_account") || isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Add modal end  */}

      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Customer
        </ModalHeader>
        <form className="tablelist-form" encType="multipart/form-data">
          <ModalBody>
            {editCustomer && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="name-field" className="form-label">
                      Customer Name
                    </label>
                    <input
                      type="text"
                      id="name-field"
                      className="form-control"
                      placeholder="Enter Customer"
                      value={editCustomer.name}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="f_name-field" className="form-label">
                      Father Name
                    </label>
                    <input
                      type="text"
                      id="f_name-field"
                      className="form-control"
                      placeholder="Enter Customer"
                      value={editCustomer.f_name}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          f_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="cnic-field" className="form-label">
                      CNIC
                    </label>
                    <input
                      type="text"
                      id="cnic-field"
                      className="form-control"
                      placeholder="Enter Customer"
                      value={editCustomer.cnic}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          cnic: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="cnic-field" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      id="cnic-field"
                      className="form-control"
                      placeholder="Enter Customer"
                      value={editCustomer.phone}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Whatsapp
                    </label>
                    <input
                      type="text"
                      id="whatsapp-field"
                      className="form-control"
                      placeholder="Enter Customer"
                      value={editCustomer.whatsapp}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          whatsapp: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      id="whatsapp-field"
                      className="form-control"
                      placeholder="Enter Customer"
                      value={editCustomer.city}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          city: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      id="email-field"
                      className="form-control"
                      placeholder="Enter Email"
                      value={editCustomer.email}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="gender-field" className="form-label">
                      Gender
                    </label>
                    <select
                      className="form-control"
                      name="gender"
                      id="gender-field"
                      value={editCustomer.gender}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          gender: e.target.value, // Update the status value
                        })
                      }
                    >
                      <option value="">Choose Gender</option>
                      <option value="M"> Male</option>
                      <option value="F"> Female</option>
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="dob-field" className="form-label">
                      DOB
                    </label>
                    <input
                      type="date"
                      id="dob-field"
                      className="form-control"
                      placeholder="Enter Date "
                      value={editCustomer.dob}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          dob: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      id="address-field"
                      className="form-control"
                      placeholder="Enter Address"
                      value={editCustomer.address}
                      onChange={(e) =>
                        seteditCustomer({
                          ...editCustomer,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="image" className="form-label">
                      Image
                    </label>
                    <input
                      type="file"
                      id="image"
                      className="form-control"
                      onChange={handleImageChange}
                    />
                    {editCustomer.image && (
                      <div>
                        {editCustomer.image instanceof File ? (
                          <img
                            src={URL.createObjectURL(editCustomer.image)}
                            alt="Product Image"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          <img
                            src={editCustomer.image}
                            alt="Product Image"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="image" className="form-label">
                      Edit Image Document
                    </label>
                    <input
                      type="file"
                      id="image"
                      className="form-control"
                      onChange={handleDocumentChange}
                    />
                    {editCustomer.document && (
                      <div>
                        {editCustomer.document instanceof File ? (
                          <img
                            src={URL.createObjectURL(editCustomer.document)}
                            alt="Product Image"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          <img
                            src={editCustomer.document}
                            alt="Product Image"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  seteditCustomer(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (customerToDelete) {
                  handleDeleteCustomer(customerToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Customer Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Customer Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Customer;
