import React from 'react';
import { Navigate, Route } from 'react-router-dom';

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = sessionStorage.getItem('login') !== null;
  
  console.log('IsAuthenticated:', isAuthenticated);

  return isAuthenticated ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;
