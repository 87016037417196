import React, { useState, useEffect } from "react";
import {
  // Import necessary components and styles
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Category = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [Categorys, setCategory] = useState([]);
  const [CategoryToDelete, setCategoryToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const [newCategory, setNewCategory] = useState({
    name: "",
  });
  const [editCategory, setEditCategory] = useState(null);


    // Fetch Categorys data when the component mounts
    const fetchData = async () => {
      try {
        const groupId = sessionStorage.getItem("groupId");

        if (!groupId) {
          console.error("Group ID not found in the session.");
          return;
        }

        const response = await fetch(`${baseUrl}/category/`);
        if (response.ok) {
          const data = await response.json();
          // Filter data based on the current groupId
          const filteredData = data.filter(
            (item) => item.group.toString() === groupId
          );
          setCategory(filteredData);
        } else {
          console.error("Error fetching Categorys:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching Categorys:", error);
      }
    };
    useEffect(() => {
    fetchData();
  }, []);

  const handleCategoryChange = (e, field) => {
    // Update newCategory state based on user input
    setNewCategory({
      ...newCategory,
      [field]: e.target.value,
    });
  };

  // Function to search the Table Data
  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Function to handle adding a new Category
  const handleAddCategory = async (e) => {
    e.preventDefault();

    try {
      setIsAddButtonDisabled(true); // Disable the button

      const token = sessionStorage.getItem(`token`);
      const groupId = sessionStorage.getItem(`groupId`);
      const loginId = sessionStorage.getItem(`login`);

      if (!loginId) {
        console.error("User not logged in.");
        // Handle the case where the user is not logged in
        return;
      }

      // Adjust the request payload
      const requestBody = {
        name: newCategory.name,
        group: groupId,
        user: loginId,
      };

      const response = await fetch(`${baseUrl}/category/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginId}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 201) {
        const data = await response.json();
        setSuccessMessage("Category Added", data);
        setmodal_list(false);
        setCategory([...Categorys, data]);
        setNewCategory({
          name: "",
        });
        setTimeout(() => {
          setSuccessMessage(null);
        }, 1000);
      } else {
        const errorData = await response.json();
        console.error("Failed to add the Category:", errorData);
      }
    } catch (error) {
      console.error("Error adding the Category:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  useEffect(() => {
    let timeout;
    if (showAlert || successMessage) {
      timeout = setTimeout(() => {
        setShowAlert(false);
        setSuccessMessage(null);
      }, 1000);
    }
    return () => clearTimeout(timeout); // Clear timeout on unmount or state change
  }, [showAlert, successMessage]);

  // Function to handle editing a Category
  const handleEditCategory = (Category) => {
    setEditCategory(Category);
    setmodal_edit(true);
  };

  // Function to save edited Category
  const handleSaveEdit = async () => {
    if (!editCategory) return;

    try {
      const response = await fetch(`${baseUrl}/category/${editCategory.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editCategory),
      });

      if (response.status === 200) {
        const updatedCategory = await response.json();
        console.log("Category updated:", updatedCategory);

        const updatedCategorys = Categorys.map((Category) =>
          Category.id === updatedCategory.id ? updatedCategory : Category
        );

        setCategory(updatedCategorys);
        setmodal_edit(false);
        setEditCategory(null);
      } else {
        console.error("Failed to edit the Category:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the Category:", error);
    }
  };

  // Function to handle deleting a Category
  const handleDeleteCategory = async (Category) => {
    if (Category) {
      try {
        const response = await fetch(`${baseUrl}/category/${Category.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("Category deleted:", Category.id);

          // Remove the deleted Category from the Categorys state
          setCategory((prevCategorys) =>
            prevCategorys.filter((r) => r.id !== Category.id)
          );

          setmodal_delete(false); // Close the delete modal
          setShowAlertDelete(true);

          // Show the alert after successful deletion
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the Category:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the Category:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Category" breadcrumbItem="Category Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">Category</h1> */}
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4"></div>
                  </div>

                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => setmodal_list(true)}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Category
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>

                            <th className="sort" data-sort="name">
                              Name
                            </th>
                            <th
                              className="no-print"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {Categorys.map((Category, index) => (
                            <tr key={Category.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="name">{Category.name}</td>
                              <td className="">
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() =>
                                        handleEditCategory(Category)
                                      }
                                      color="soft-success"
                                    >
                                      <i className="ri-edit-2-line"></i>
                                    </button>
                                  </div>
                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn action-button"
                                    onClick={() => {
                                      setCategoryToDelete(Category);
                                      setmodal_delete(true);
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          setmodal_list(!modal_list);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_list(!modal_list);
          }}
        >
          Add Category
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddCategory}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-12">
                <label htmlFor="name-field" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="text-field"
                  className="form-control"
                  placeholder="Enter Name"
                  required
                  value={newCategory.name}
                  onChange={(e) => handleCategoryChange(e, "name")}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled} // Apply the disabled state here
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Edit Modal */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Category
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editCategory && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editCategory.name}
                      onChange={(e) =>
                        setEditCategory({
                          ...editCategory,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditCategory(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (CategoryToDelete) {
                  handleDeleteCategory(CategoryToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Your other JSX components */}

      {/* Success Alert Modal */}
      {successMessage && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setSuccessMessage(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Saved</h4>
                    <p className="mb-0">Data Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Category;
