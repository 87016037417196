import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const OverView = () => {
  const [installmentData, setInstallmentData] = useState([]);
  const [plotData, setPlotData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const currentDate = new Date();

  const getPlotName = (plotID) => {
    const plot = plotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  const getCustomerName = (customerID) => {
    const customerName = customerData.find(
      (customer) => customer.id === customerID
    );
    return customerName ? customerName.name : "N/A";
  };

  const handleStartDateChange = (e) => {
    setSelectedStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setSelectedEndDate(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const response = await fetch(`${baseUrl}/installment-data/`);
      if (response.ok) {
        const data = await response.json();
        const filteredResult = data.filter(filterByDate);
        setFilteredData(filteredResult);
      } else {
        console.error("Error fetching installment data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching installment data:", error);
    }
  };

  const filterByDate = (installments) => {
    const promiseDate = new Date(installments.promise_date);

    if (selectedStartDate && selectedEndDate) {
      const start = new Date(selectedStartDate);
      const end = new Date(selectedEndDate);

      return promiseDate >= start && promiseDate <= end;
    }

    return promiseDate >= currentDate;
  };

  useEffect(() => {
    const accounts = async () => {
      try {
        const groupId = sessionStorage.getItem("groupId");

        if (!groupId) {
          console.error("Group ID not found in the session.");
          return;
        }

        const plotResponse = await fetch(`${baseUrl}/plot/`);
        if (plotResponse.ok) {
          const plotData = await plotResponse.json();
          setPlotData(plotData);
        } else {
          console.error("Error fetching plot data:", plotResponse.statusText);
        }

        const customerResponse = await fetch(`${baseUrl}/accounts/`);
        if (customerResponse.ok) {
          const customerData = await customerResponse.json();
          setCustomerData(customerData);
        } else {
          console.error(
            "Error fetching customer data:",
            customerResponse.statusText
          );
        }

        const installmentResponse = await fetch(`${baseUrl}/installment-data/`);
        if (installmentResponse.ok) {
          const installmentData = await installmentResponse.json();
          const filteredData = installmentData.filter(
            (item) => item.group.toString() === groupId
          );
          setInstallmentData(filteredData);
        } else {
          console.error(
            "Error fetching installment data:",
            installmentResponse.statusText
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    accounts();
  }, []);

  useEffect(() => {
    const initialFilteredData = installmentData.filter(filterByDate);
    setFilteredData(initialFilteredData);
  }, [installmentData, selectedStartDate, selectedEndDate]);

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardBody>
            <div className="text-muted">
              <Row>
                <div className="row">
                  <div className="col-md-6">
                    <h4>OverDate Installments</h4>
                  </div>

                  <div className="col-sm-3 inline-block">
    <label htmlFor="start_date">Start Date</label>
    <input
        type="date"
        className="form-control"
        name="start_date"
        value={selectedStartDate}
        onChange={handleStartDateChange}
    />
</div>


                  <div className="col-md-3">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="end_date"
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                    />
                  </div>
                </div>
              </Row>
            </div>
            <CardBody>
              <div id="customerList">
                <div className="table-responsive table-card mt-3 mb-1">
                  <table className="table align-middle table-nowrap" id="table">
                    <thead className="table-light">
                      <tr>
                        <th className="sort" data-sort="id">
                          Sr#
                        </th>
                        <th className="sort" data-sort="id">
                          Booking#
                        </th>
                        <th className="sort" data-sort="name">
                          Customer Name
                        </th>
                        <th className="sort" data-sort="name">
                          Plot
                        </th>
                        <th className="sort" data-sort="name">
                          Amount
                        </th>
                        <th className="sort" data-sort="name">
                          Balance
                        </th>
                        <th className="sort" data-sort="name">
                          Promise Date
                        </th>
                      </tr>
                    </thead>
                    <tbody className="list form-check-all" id="myTable">
                      {filteredData.map((installments, index) => (
                        <tr key={installments.id}>
                          <td className="srNumber">{index + 1}</td>
                          <td className="name">{installments.booking}</td>
                          <td className="customer_id">
                            {getCustomerName(installments.customer)}
                          </td>
                          <td className="customer_id">
                            {getPlotName(installments.plot)}
                          </td>
                          <td className="name">{installments.amount}</td>
                          <td className="name">{installments.amount}</td>
                          <td className="name">{installments.promise_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </CardBody>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default OverView;
