import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Row } from "reactstrap";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

// ... (previous code remains unchanged)

const PlotReport = () => {

  const [addPlotData, setPlotData] = useState([]);
  const [Booking, setBooking] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [showData, setShowData] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [Customer, setCustomer] = useState([]);
  const [installmentData, setInstallmentData] = useState([]);
  const [printDisabled, setPrintDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [transactions, setTransactions] = useState([]); // New state for transactions
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [getplots, setPlots] = useState([]);
  const [selectedPlot, setSelectedPlot] = useState("");

  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(`${baseUrl}/plot/`);
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlotData(filteredData);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // get Plot Name
  const getPlotName = (plotID) => {
    const plot = addPlotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = Customer.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };

  // Fetch data of Installments based on customer_id
  const fetchInstallmentData = async (customerId, plotId) => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }
      const response = await fetch(
        `${baseUrl}/installment-data/?accounts=${customerId}&plot=${plotId}`
      );
      const data = await response.json();
      const filteredData = data.filter(
        (item) => item.group.toString() === groupId
      );
      setInstallmentData(filteredData);
    } catch (error) {
      console.error("Error fetching installment data:", error);
    }
  };

  useEffect(() => {
    fetch(`${baseUrl}/accounts/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCustomer(data);

        const customerId = data[0]?.id;
        fetchInstallmentData(customerId);
      })
      .catch((error) => {
        console.error("Error fetching Customer:", error);
      });
  }, []);

  const handleCustomerSelect = async (custId) => {
    setSelectedCustomer(custId);
    setPlots([]);
    setInstallmentData([]);
    if (custId) {
      try {
        const response = await fetch(
          `${baseUrl}/receipt-booking/?customer_id=${custId}`
        );
        const data = await response.json();

        if (data.message) {
          console.warn(data.message);
          setPlots([]);
        } else {
          setPlots(data);
        }
      } catch (error) {
        console.error("Error fetching plots:", error);
        setPlots([]);
      }
    } else {
      setPlots([]);
    }
  };
  const handlePlotSelect = (plotId) => {
    setSelectedPlot(plotId);
    fetchInstallmentData(selectedCustomer, plotId);
  };
  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const plotId = e.target.elements.plot.value;
      const cId = e.target.elements.customer.value;
      const bookingResponse = await fetch(
        `${baseUrl}/booking/?plot=${plotId}&customer=${cId}`
      );
      const bookingData = await bookingResponse.json();

      const transactionsResponse = await fetch(
        `${baseUrl}/transactions/?plot=${plotId}`
      );
      const transactionsData = await transactionsResponse.json();
      
      console.log("Original Transactions Data:", transactionsData);
      

      let filteredBooking = bookingData;
      let filteredTransactions = transactionsData;


      if (cId !== "" && plotId !== "") {
        console.log("Applying Customer and Plot Filters");
        console.log("Before Filter - Booking:", filteredBooking);
        console.log("Before Filter - Transactions:", filteredTransactions);
  
        filteredBooking = filteredBooking.filter(
          (item) =>
            item.customer.toString() === cId && item.plot.toString() === plotId
        );
 filteredTransactions = filteredTransactions.filter(
  (item) =>
    item.account.toString() === cId && (!plotId || item.plot?.toString() === plotId)
);


  // console.log("After Filter - Transactions:", filteredTransactions);
}
      

      if (filteredBooking.length === 0 || filteredTransactions.length === 0) {
        setError(true);
        setBooking([]);
        setTransactions([]);
        setShowTable(false);
        setShowData(false);
        setPrintDisabled(false);
      } else {
        setBooking(filteredBooking);
        setTransactions(filteredTransactions);
        setShowTable(true);
        setError(false);
        setShowData(true);
        setPrintDisabled(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    }
  };

  const filteredData = installmentData.filter(
    (data) =>
      data.customer.toString() === selectedCustomer &&
      data.plot.toString() === selectedPlot
  );

  const totalClosingBalance = filteredData.reduce((total, item) => {
    const remainingBalance =
      parseFloat(item.amount) - parseFloat(item.paid || 0);
    return total + remainingBalance;
  }, 0);

  const calculateTotalReceivedBalance = () => {
    let total = 0;
    let received = 0;

    installmentData
      .filter(
        (data) =>
          data.customer.toString() === selectedCustomer &&
          data.plot.toString() === selectedPlot
      )
      .forEach((data) => {
        total += parseFloat(data.amount);
        received += parseFloat(data.paid ? data.paid : 0);
      });

    const balance = total - received;

    return { total, received, balance };
  };

  const { total, received, balance } = calculateTotalReceivedBalance();

  const closingBalance = totalClosingBalance;

  //   // Print Function
  const navigate = useNavigate();
  const handlePrint = () => {
    if (selectedCustomer && selectedPlot) {
      navigate("/plot-report/plot-report-print", {
        state: {
          BookingData: Booking,
          TransactionsData: transactions,
          ShowData: showData,
          TotalBalance: closingBalance,
          InstallmentData: installmentData,
          SelectedCustomer: selectedCustomer,
          SelectedPlot: selectedPlot,
        },
      });
    }
  };


  
  return (
    <React.Fragment>
      <div className="page-content">
        <form
          className="tablelist-form"
          encType="multipart/form-data"
          onSubmit={handleSearch}
        >
          <div id="AddBookingList">
            <Row className="g-4">
              <div className="col-sm-4">
                <label htmlFor="customer" className="form-label">
                  Customer
                </label>
                <select
                  className="form-control"
                  name="customer"
                  id="customer-field"
                  value={selectedCustomer}
                  
                  onChange={(e) => handleCustomerSelect(e.target.value)}
                >
                  <option value="">Choose Customer</option>
                  {Customer.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4">
                <label htmlFor="plot-field" className="form-label">
                  Plot
                </label>
                <select
                  className="form-control"
                  name="plot"
                  id="plot"
                  
                  onChange={(e) => handlePlotSelect(e.target.value)}
                >
                  <option value="">Choose Plot</option>
                  {getplots && getplots.length > 0 ? (
                    getplots.map((booking) => (
                      <option key={booking.plot.id} value={booking.plot.id}>
                        {booking.plot.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No plots available
                    </option>
                  )}
                </select>
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-1">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <Button
                  color="success"
                  className="add-btn"
                  id="create-btn"
                  type="submit"
                >
                  Search
                </Button>
              </div>
              <div className="col-md-1">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <Button
                  color="primary"
                  className="add-btn"
                  onClick={handlePrint}
                  disabled={printDisabled}
                >
                  <span>Print</span>
                </Button>
              </div>
            </Row>
          </div>
        </form>
        {/* Cutomer Information Data  */}

        <div className="table-responsive table-card mt-3">
          <h5 className="p-2" style={{ color: "white", background: "black" }}>
            Customer Information
          </h5>

          <table className="table align-middle table-nowrap" id="table">
            <thead className="table-light">
              <tr>
                <th>Customer Name</th>
                <th>Phone</th>
                <th>CNIC</th>
                <th>City</th>
              </tr>
            </thead>
            <tbody>
              {showData &&
                Booking.map((data) => (
                  <tr key={data.id}>
                    <td className="customer_id">
                      {getCustomerName(data.customer)}
                    </td>
                    <td>{data.phone}</td>
                    <td>{data.cnic}</td>
                    <td>{data.city}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <table className="table align-middle table-nowrap" id="table">
            <thead className="table-light">
              <tr>
                <th>Plot#</th>
                <th>Plot Size</th>
                <th>Dimension</th>
                <th>Price</th>
                <th>Total</th>
                <th>Booking Date</th>
              </tr>
            </thead>
            <tbody>
              {Booking.map((data) => (
                <tr key={data.id}>
                  <td className="plot_id">{getPlotName(data.plot)}</td>
                  <td>
                    {data.size} - {data.size_unit}
                  </td>
                  <td>{data.dimension}</td>
                  <td>{data.price}</td>
                  <td>{data.total}</td>
                  <td>{data.booking_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Installment Plan */}
        <div className="table-responsive table-card">
          <h5 className="p-2" style={{ color: "white", background: "black" }}>
            Installment Plan
          </h5>
          <table className="table align-middle table-nowrap" id="table">
            <thead className="table-light" style={{ color: "white" }}>
              <tr>
                <th>Total </th>
                <th>Advance</th>
                <th>Balance</th>
                <th>Package</th>
                <th>No.Of Installments</th>
                <th>Amount/Per Inst.</th>
              </tr>
            </thead>
            <tbody>
              {Booking.map((packages) => (
                <tr key={packages.id}>
                  <td>{packages.total}</td>
                  <td>{packages.advance}</td>
                  <td>{packages.balance}</td>
                  <td>{packages.package_choice}</td>
                  <td>{packages.no_Of_installment}</td>
                  <td>{packages.amount_package}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="table align-middle table-nowrap" id="table">
            <thead className="table-light">
              <tr>
                <th>Sr#</th>
                <th>Months</th>
                <th>Total</th>
                <th>Received</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
            {showData &&
  installmentData
    .filter(
      (data) =>
        data.customer.toString() === selectedCustomer &&
        data.plot.toString() === selectedPlot
    )
    .map((data, index) => (
      <tr
        key={data.id}
    
      >
        <td style={{fontWeight:400, color: data.balance === null ? "red" : data.balance == 0 ? "green" : data.balance > 0 ? "orange" : data.amount < data.balance ? "grey" : "inherit" }}>{index + 1}</td>
        <td style={{fontWeight:400, color: data.balance === null ? "red" : data.balance == 0 ? "green" : data.balance > 0 ? "orange" : data.amount < data.balance ? "grey" : "inherit" }}>{data.promise_date}</td>
        <td style={{fontWeight:400, color: data.balance === null ? "red" : data.balance == 0 ? "green" : data.balance > 0 ? "orange" : data.amount < data.balance ? "grey" : "inherit" }}>{data.amount}</td>
        <td style={{fontWeight:400, color: data.balance === null ? "red" : data.balance == 0 ? "green" : data.balance > 0 ? "orange" : data.amount < data.balance ? "grey" : "inherit" }}>{ data.paid ? data.paid : "0.00"}</td>
        <td style={{fontWeight:400, color: data.balance === null ? "red" : data.balance == 0 ? "green" : data.balance > 0 ? "orange" : data.amount < data.balance ? "grey" : "inherit" }}>{data.paid && data.paid !== "0"
            ? parseFloat(data.amount) - parseFloat(data.paid)
            : data.amount}
        </td>
      </tr>
    ))}

              <br />
              <br />
              <tr style={{ background: "black", color: "white" }}>
                <td colSpan={2} style={{ textAlign: "right", color: "red" }}>
                  <strong>Closing Balance:</strong>
                </td>
                <td colSpan="1">{total.toFixed(2)}</td>
                <td colSpan="1">{received.toFixed(2)}</td>
                <td colSpan="2">{balance.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="page-content">
          {error && ( // Show error message if error state is true
            <div className="alert alert-danger" role="alert">
              No data available for the selected criteria.
            </div>
          )}


        </div>
      </div>
    </React.Fragment>
  );
};

export default PlotReport;
