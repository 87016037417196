const SidebarData = [


    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
    },


    {
        label: "Category",
        icon: "mdi mdi-vector-circle",
        isHasArrow: true,
        url: "/category",
    },


    {
        label: "Block",
        icon: "mdi mdi-domain",
        isHasArrow: true,
        url: "/block",
    },


    {
        label: "Plot",
        icon: "mdi mdi-record-circle-outline",
        isHasArrow: true,
        url: "/plot",
    },


    {
        label: "Agent",
        icon: "mdi mdi-human-greeting",
        isHasArrow: true,
        url: "/agent",
    },

    {
        label: "CRM",
        icon: "mdi mdi-account",
        subItem: [
            { sublabel: "Leads", link: "/customer" },
            { sublabel: "Follow Up", link: "/follow-up " },
        ],
    },


    {
        label: "Packags",
        icon: "mdi mdi-plus-circle-multiple-outline",
        isHasArrow: true,
        url: "/packages",
    },



    {
        label: "Booking",
        icon: "mdi mdi-briefcase-variant-outline",
        subItem: [
            { sublabel: "Add Booking", link: "/booking" },
            { sublabel: "Booking List", link: "/show-booking" },
        ],
    },

    {
        label: "File Trasfer",
        icon: "mdi mdi-plus-circle-multiple-outline",
        isHasArrow: true,
        url: "/file-transefer/",
    },
    {
        label: "File Delivered",
        icon: "mdi mdi-plus-circle-multiple-outline",
        isHasArrow: true,
        url: "/file-delivered/",
    },

    {
        label: "Collect Installments",
        icon: "mdi mdi-plus-circle",
        isHasArrow: true,
        url: "/receipt/",
    },



    {
        label: "Reports",   
        icon: "mdi mdi-briefcase-variant-outline",
        subItem: [
            { sublabel: "Plot Report", link: "/plot-report" },
            { sublabel: "Account Report", link: "/journal-report" },
            { sublabel: "Recovery Report", link: "/recovery-report" },
            { sublabel: "Trial Balance Report", link: "/trial-balance-report/" },
            { sublabel: "Sales Report", link: "/sales-report/" },
        ],
    },


    {
        label: "Expense",
        icon: "mdi mdi-plus-circle",
        isHasArrow: true,
        url: "/expense/",
    },


    {
        label: "Accounts",
        icon: "mdi mdi-record-circle-outline",
        isHasArrow: true,
        url: "/accounts",
    },

    {
        label: "Purchase",
        icon: "mdi mdi-record-circle-outline",
        isHasArrow: true,
        url: "/purchase",
    },


    {
        label: "Investor",
        icon: "mdi mdi-record-circle-outline",
        isHasArrow: true,
        url: "/investor-profile",
    },




    {
        label: "Transactions",
        icon: "mdi mdi-briefcase-variant-cirlce",
        subItem: [
            { sublabel: "Receipt Voucher", link: "/receipt-voucher" },
            { sublabel: "Receipt Voucher List", link: "/view-receipt-voucher" },
            { sublabel: "Payment Voucher", link: "/payment-voucher" },
            { sublabel: "Payment Voucher List", link: "/view-payment-voucher" },
            { sublabel: "Journal Voucher", link: "/journal-voucher" },
        ],
    },



]
export default SidebarData;