import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import saharalogo from "../../assets/images/saharalogo.png";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";

const SingleCustomerPrint = () => {
  const location = useLocation();
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [Customer, setCustomer] = useState({
    id: "",
    c_name: "",
    // ... other fields
  });

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formattedDate = now.toLocaleDateString();
      const formattedTime = now.toLocaleTimeString();
      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchCustomerData = async (customerId) => {
      try {
        const response = await fetch(
          `https://erp.adroitsoft.co/api/customer/${customerId}`
        );
        if (response.ok) {
          const data = await response.json();
          setCustomer(data);
          setDataFetched(true);
        } else {
          console.error(
            "Failed to fetch the customer for printing:",
            response.statusText
          );
        }
      } catch (error) {
        console.error("Error printing the customer:", error);
      }
    };

    // Check if customer data is present in the location state
    if (location.state && location.state.customer) {
      setCustomer(location.state.customer);
      setDataFetched(true);
    } else {
      // If no customer data is present, fetch it using the ID from the route
      const customerId = location.pathname.split("/")[4];
      if (customerId) {
        fetchCustomerData(customerId);
      }
    }
  }, [location.state]);

  // Effect to automatically open the print dialog when data is fetched
  useEffect(() => {
    if (dataFetched) {
      window.print();
    }
  }, [dataFetched]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>

              <CardBody className="print-visible">
                <div id="CustomerList">
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div className="text-center">
                        <Link to="/">
                          <img
                            // src={saharalogo}
                            alt=""
                            height="80"
                            className="auth-logo logo-dark mx-auto"
                          />

                        </Link>
                      </div>
                    </div>

                    <div className="col-md-5 print-visible">


                      <div style={{ textAlign: "center" }}>
                        <h3>
                          <strong>UgiDeveloper</strong>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4 text-center print-visible">
                      <p>
                        <strong>Date:</strong> {currentDate}{" "}
                        <strong>Time:</strong> {currentTime}
                      </p>

                      {/* <p>
                        <strong>Contact Us:</strong> 0300-0986970
                      </p> */}
                    </div>
                  </div>
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                    <div className="col-md-12 print-visible">
                      <div style={{ textAlign: "center" }}>
                        <h5 className="bg-black text-white p-2">
                          <strong>Customer List</strong>
                        </h5>
                      </div>
                      <p
                        style={{ textAlign: "center" }}
                        className="print-visible"
                      ></p>
                    </div>
                    <div className="col-md-1 print-visible"></div>
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                  </div>
                  <div className="table-responsive table-card mt-3 mb-1 print-visible">
                    <table className="table table-bordered table-striped">

                      <tbody>
                        <tr>
                          <td>
                            <strong className="text-black">Customer Name:</strong>
                          </td>
                          <td>{Customer.name}</td>
                          <td>
                            <strong className="text-black">Father Name:</strong>
                          </td>
                          <td>{Customer.f_name}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong className="text-black">CNIC:</strong>
                          </td>
                          <td>{Customer.cnic}</td>
                          <td>
                            <strong className="text-black">Phone:</strong>
                          </td>
                          <td>{Customer.phone}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong className="text-black">Whatsapp:</strong>
                          </td>
                          <td>{Customer.whatsapp}</td>
                          <td>
                            <strong className="text-black">City:</strong>
                          </td>
                          <td>{Customer.city}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong className="text-black">Email:</strong>
                          </td>
                          <td>{Customer.email}</td>
                          <td>
                            <strong className="text-black">Gender:</strong>
                          </td>
                          <td>{Customer.gender}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong className="text-black">DOB:</strong>
                          </td>
                          <td>{Customer.dob}</td>
                          <td>
                            <strong className="text-black">Address:</strong>
                          </td>
                          <td>{Customer.address}</td>
                        </tr>
                        {/* ... (add similar rows for other fields) */}
                      </tbody>
                    </table>

                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

    </div>
  );
};

export default SingleCustomerPrint;
