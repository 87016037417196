import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  Form,
} from "reactstrap";
import { env } from "../../config";
import withRouter from "../../components/Common/withRouter";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import Select from "react-select";

// actions

const RegisterUser = () => {
  document.title = "Profile | Allied Tajar(ERP)";
  const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
    password: "",
    user_permissions: [],
  });

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    const clearMessages = () => {
      setSuccessMessage(null);
      setErrorMessage(null);
    };

    const successTimeout = setTimeout(clearMessages, 3000);

    return () => clearTimeout(successTimeout);
  }, [successMessage, errorMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    try {
      const userId = sessionStorage.getItem("login");

      if (!userId) {
        console.error("User Id not found in the session.");
        return;
      }

      const requestBody = {
        admin: parseInt(userId),
        username: userData.username,
        password: userData.password,
        email: userData.email,
        phone: userData.phone,
        first_name: userData.first_name,
        last_name: userData.last_name,
        user_permissions: selectedPermissions.map(
          (permission) => permission.value
        ),
      };
      console.log("Request Payload: ", requestBody);

      const response = await fetch(`${baseUrl}/users/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setSuccessMessage("User Registered successfully");
        setUserData({
          first_name: "",
          last_name: "",
          username: "",
          email: "",
          phone: "",
          password: "",
          user_permissions: "",
        });
        setErrorMessage(null);
      } else {
        const errorData = await response.json();
        console.error("Error Registering User:", errorData);
        setErrorMessage(
          errorData.password || errorData.authorize || "Failed to Add User"
        );
        setSuccessMessage(null);
      }
    } catch (error) {
      console.error("Error updating User Profile:", error);
      setErrorMessage("Error Registering User");
      setSuccessMessage(null);
    }
  };

    const fetchPermissions = async () => {
      try {
        const response = await fetch(`${baseUrl}/permissions/`);
        if (response.ok) {
          const data = await response.json();
          console.log("Fetched Permissions:", data);
          setPermissions(data);
        } else {
          console.error(
            "Failed to fetch permissions. Status:",
            response.status
          );
          const errorData = await response.json();
          console.error("Error data:", errorData);
        }
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };
    useEffect(() => {

    fetchPermissions();
  }, [baseUrl]);

  const handlePermissionChange = (selectedOptions) => {
    setSelectedPermissions(selectedOptions);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Allied Tajar" breadcrumbItem="Add User" />

          <Card>
            <CardBody>
              <Form className="form-horizontal" onSubmit={handleAddUser}>
                {successMessage && (
                  <Alert color="success">{successMessage}</Alert>
                )}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <div className="form-group">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="first_name"
                        value={userData.first_name}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label">Last Name</Label>
                      <Input
                        name="last_name"
                        value={userData.last_name}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Label className="form-label">User Name</Label>
                      <Input
                        name="username"
                        value={userData.username}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label">Email </Label>
                      <Input
                        name="email"
                        value={userData.email}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter User Email"
                        type="email"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        value={userData.password}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter Password"
                        type="password"
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label">Phone </Label>
                      <Input
                        name="phone"
                        value={userData.phone}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter Phone"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-12">
                      <Label className="form-label">Permissions</Label>
                      <Select
                        isMulti // This prop is crucial for multi-select
                        name="user_permissions"
                        options={permissions.map((permission) => ({
                          value: permission.id,
                          label: permission.name,
                        }))}
                        value={selectedPermissions}
                        onChange={handlePermissionChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="success">
                    Add
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RegisterUser);