import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Navigate, useNavigate, Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import withRouter from "../withRouter";

const ProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Admin");
  const [userRole, setUserRole] = useState("admin"); // Assuming you have the role information
  const navigate = useNavigate();

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("email");
    const storedUserRole = sessionStorage.getItem("role"); // Assuming you store the role in the session
    if (storedEmail) {
      setusername(storedEmail);
      setUserRole(storedUserRole || "admin"); // Default to admin if role is not available
    } else {
      setusername("Admin");
    }
  }, [props.success]);

  const handleLogout = () => {
    // Clear all session data and redirect to the login page
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <span className="d-none d-xl-inline-block ms-2 me-2">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to="/userprofile">
            <i className="ri-user-line align-middle me-2" />
            {props.t("Profile")}
          </DropdownItem>
          <DropdownItem tag={Link} to="/change-password">
            <i className="ri-lock-password-line align-middle me-2" />
            {props.t("Change Password")}
          </DropdownItem>

          {userRole === "admin" && (
            <React.Fragment>
              <DropdownItem tag={Link} to="/register">
                <i className="ri-user-line align-middle me-2" />
                {props.t("Add User")}
              </DropdownItem>

              <DropdownItem tag={Link} to="/users-list">
                <i className="ri-user-line align-middle me-2" />
                {props.t("Users List")}
              </DropdownItem>
            </React.Fragment>
          )}

          <DropdownItem tag={Link} to="auth-lock-screen">
            <i className="ri-lock-unlock-line align-middle me-2" />
            {props.t("Lock screen")}
          </DropdownItem>

          <div className="dropdown-divider" />
          <DropdownItem onClick={handleLogout}>
            <i className="ri-shut-down-line align-middle me-2 text-danger" />
            <span>{props.t("Logout")}</span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
