import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Follow = () => {
  const [modal_edit, setmodal_edit] = useState(false);
  const [customer_history_modal, setCustomerHistoryModal] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [CustomerFollowUp, setCustomerFollowUp] = useState([]);
  const [editCustomer, setEditCustomer] = useState(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [Users, setUser] = useState(false);
  const [customerHistory, setCustomerHistory] = useState(false);
  const [CustomerData, setCustomerData] = useState(false);
  const [CustomerCategory, setCustomerCategory] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [AddCustomerFollowUp, setAddNewCustomerFollowUp] = useState({
    customer_id: "",
    user_id: "",
    customer_category: "",
    promise_date: "",
    call_time: "",
    call_duration: "",
    remarks: "",
    status: "",
    attachments: null,
  });
  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/customer-follow-up/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomerFollowUp(filteredData);
      } else {
        console.error(
          "Error fetching Customer Follow-Up:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching Customer Follow-Up:", error);
    }
  };
  const fetchUsers = async () => {
    try {
      const adminId = sessionStorage.getItem("admin");

      if (!adminId) {
        console.error("Group ID not found in the session.");
        return;
      }
      const response = await fetch(`${baseUrl}/users/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current adminId
        const filteredData = data.filter((user) => user.admin == adminId);
        setUser(filteredData);
      } else {
        console.error("Error fetching User's:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching User:", error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomerData(filteredData);
      } else {
        console.error("Error fetching Accounts:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Accounts:", error);
    }
  };

  const fetchCustomerCategory = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/customer-category/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomerCategory(filteredData);
      } else {
        console.error("Error fetching Customer Category:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Customer  Category:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchUsers();
    fetchAccounts();
    fetchCustomerCategory();
  }, []);

  const getUserName = (userID) => {
    const foundUser = Users.find((user) => user.id === userID);
    return foundUser ? foundUser.username : "N/A";
  };
  const getCustomerName = (userID) => {
    const foundUser = CustomerData.find((user) => user.id === userID);
    return foundUser ? foundUser.name : "N/A";
  };

  // Fetch Categorys data when the component mounts
  const fetchCustomerData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomers(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };
  useEffect(() => {
    fetchCustomerData();
  }, []);

  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false;
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; 

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; 
            }
          }
        }

        if (!found) {
          showRow = false;
        }
      }

      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "customer_id") {
      // If the input is customer_id, handle it differently
      setAddNewCustomerFollowUp((prevState) => ({
        ...prevState,
        customer_id: value,
      }));
    } else {
      // For other inputs, update the state as usual
      setAddNewCustomerFollowUp((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setAddNewCustomerFollowUp((prevCustomer) => ({
        ...prevCustomer,
        attachments: file,
      }));
    }
  };

  const SaveCustomerFollowUp = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);

    const token = sessionStorage.getItem("token");
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }
    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("customer_id", selectedCustomerId);
    formData.append("user_id", AddCustomerFollowUp.user_id);
    formData.append("customer_category", AddCustomerFollowUp.customer_category);
    formData.append("promise_date", AddCustomerFollowUp.promise_date);
    formData.append("call_time", AddCustomerFollowUp.call_time);
    formData.append("call_duration", AddCustomerFollowUp.call_duration);
    formData.append("remarks", AddCustomerFollowUp.remarks);
    formData.append("status", AddCustomerFollowUp.status);

    if (AddCustomerFollowUp.attachments !== null) {
      formData.append("attachments", AddCustomerFollowUp.attachments);
    }

    try {
      const response = await fetch(`${baseUrl}/customer-follow-up/`, {
        method: "POST",
        headers: {},
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        setCustomerFollowUp([...CustomerFollowUp, data]);
        setAddNewCustomerFollowUp({
          customer_id: "",
          user_id: "",
          promise_date: "",
          name: "",
          call_time: "",
          call_duration: "",
          remarks: "",
          status: "",
          attachments: null,
        });
        setmodal_edit(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        console.error("Failed to add the Customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the Customer:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // fetch data to edit customer

  const handleEditCustomer = async (customer) => {
    try {
      const response = await fetch(`${baseUrl}/accounts/${customer.id}`);
      if (response.ok) {
        const data = await response.json();
        setEditCustomer(data);
        setSelectedCustomerId(customer.id);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the customer for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the customer:", error);
    }
  };

  const getCustomerHistory = async (customer) => {
    try {
      const response = await fetch(
        `${baseUrl}/customer-follow-up/?customer_id=${customer.id}`
      );
      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter(
          (entry) => entry.customer_id === customer.id
        );
        setCustomerHistory(filteredData);
        setSelectedCustomer(customer);
        setCustomerHistoryModal(true);
      } else {
        console.error(
          "Failed to fetch the customer history:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching customer history:", error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Follow" breadcrumbItem=" Follow Up" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr#
                            </th>
                            <th className="sort" data-sort="customer_name">
                              Customer Name
                            </th>
                            <th className="sort" data-sort="father_name">
                              Father Name
                            </th>
                            <th className="sort" data-sort="cinic">
                              CNIC
                            </th>
                            <th className="sort" data-sort="phone">
                              Phone
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {customers.map((customer, index) => (
                            <tr key={customer.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="name">{customer.name}</td>
                              <td className="f_name">{customer.f_name}</td>
                              <td className="name">{customer.cnic}</td>
                              <td className="name">{customer.phone}</td>

                              <td>
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() => {
                                        handleEditCustomer(customer); 
                                      }}
                                      color="soft-success"
                                    >
                                      <i className="mdi mdi-phone-plus"></i>
                                    </button>
                                  </div>
                                  <Button
                                    className="btn btn-sm btn-success edit-item-btn"
                                    onClick={() => {
                                      getCustomerHistory(customer);
                                    }}
                                    color="soft-primary"
                                  >
                                    <i className="mdi mdi-history"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Customer Leads Modal start */}

      <Modal
        isOpen={customer_history_modal}
        toggle={() => {
          setCustomerHistoryModal(!customer_history_modal);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-2"
          id="exampleModalLabel"
          toggle={() => {
            setCustomerHistoryModal(!customer_history_modal);
          }}
        >
          <h3>Leads History</h3>
        </ModalHeader>
        <form className="tablelist-form" encType="multipart/form-data">
          <ModalBody>
            {customerHistory && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th className="sort" data-sort="id">
                              Sr#
                            </th>
                            <th className="sort" data-sort="code">
                              Customer
                            </th>
                            <th className="sort" data-sort="name">
                              User
                            </th>
                            <th className="sort" data-sort="credi_limit">
                              Call Time
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Call Duration
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Promise
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Remarks
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Status
                            </th>
                          </tr>
                        </thead>
                        {customerHistory && (
                          <tbody className="list form-check-all" id="myTable">
                            {customerHistory.map((followUp, index) => (
                              <tr key={followUp.id}>
                                <td className="srNumber">{index + 1}</td>
                                <td className="customer_id">
                                  {getCustomerName(followUp.customer_id)}
                                </td>
                                <td className="user_id">
                                  {getUserName(followUp.user_id)}
                                </td>
                                <td className="call_time">
                                  {followUp.call_time}
                                </td>
                                <td className="call_duration">
                                  {followUp.call_duration}
                                </td>
                                <td className="promise_date">
                                  {followUp.promise_date}
                                </td>
                                <td className="remarks">{followUp.remarks}</td>
                                <td className="status">{followUp.status}</td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
        </form>
      </Modal>

      {/* Customer Leads modal end */}

      {/* Edit Modal start */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-2"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          <h3>Follow Up</h3>
        </ModalHeader>
        <form
          className="tablelist-form"
          encType="multipart/form-data"
          onSubmit={SaveCustomerFollowUp}
        >
          <ModalBody>
            {editCustomer && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="name-field" className="form-label">
                      Customer Name
                    </label>
                    <input
                      type="text"
                      id="name-field"
                      name="customer_id"
                      className="form-control"
                      placeholder="Customer"
                      readOnly
                      value={editCustomer.name}
                      onChange={(e) => {
                        setEditCustomer({
                          ...editCustomer,
                          name: e.target.value,
                        });
                        handleInputChange(e);
                      }}
                    />
                  </div>

                  <div className="col-sm-3">
                    <label htmlFor="cnic-field" className="form-label">
                      CNIC
                    </label>
                    <input
                      type="text"
                      id="cnic-field"
                      name="cnic"
                      className="form-control"
                      readOnly
                      placeholder="CNIC"
                      value={editCustomer.cnic}
                      onChange={(e) =>
                        setEditCustomer({
                          ...editCustomer,
                          cnic: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="cnic-field" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      id="cnic-field"
                      className="form-control"
                      placeholder="Phone"
                      readOnly
                      value={editCustomer.phone}
                      onChange={(e) =>
                        setEditCustomer({
                          ...editCustomer,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="whatsapp-field" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      id="whatsapp-field"
                      className="form-control"
                      placeholder="City"
                      readOnly
                      value={editCustomer.city}
                      onChange={(e) =>
                        setEditCustomer({
                          ...editCustomer,
                          city: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="user-field" className="form-label">
                      User
                    </label>
                    <select
                      className="form-control"
                      name="user_id"
                      id="user-field"
                      onChange={handleInputChange}
                    >
                      <option value="">Select User</option>
                      {Users.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.username}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-3">
                    <label htmlFor="f_name-field" className="form-label">
                      Promise Date
                    </label>
                    <input
                      type="date"
                      id="promise_date"
                      name="promise_date"
                      className="form-control"
                      placeholder="0 Minutes "
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="call_time-field" className="form-label">
                      Call Time
                    </label>
                    <input
                      type="time"
                      id="call_time"
                      name="call_time"
                      className="form-control"
                      placeholder="0 Minutes "
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="f_name-field" className="form-label">
                      Call Duration
                    </label>
                    <input
                      type="text"
                      id="call_duration"
                      name="call_duration"
                      className="form-control"
                      placeholder="0 Minutes "
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <label htmlFor="remarks-field" className="form-label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      placeholder="Remarks "
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="remarks-field" className="form-label">
                      Attachments
                    </label>
                    <input
                      type="file"
                      id="attachments"
                      name="attachments"
                      className="form-control"
                      placeholder="Remarks "
                      onChange={handleImageChange}
                    />
                  </div>

                  <div className="col-sm-3">
                    <label
                      htmlFor="customer_category-field"
                      className="form-label"
                    >
                      Customer Category
                    </label>
                    <select
                      className="form-control"
                      name="customer_category"
                      id="customer_category"
                      onChange={handleInputChange}
                    >
                      <option value="">Customer Category</option>
                      {CustomerCategory.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-2">
                    <label htmlFor="status-field" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-control"
                      name="status"
                      id="status-field"
                      onChange={handleInputChange}
                    >
                      <option value="">Select</option>
                      <option value="Pending">Pending</option>
                      <option value="InProcess">InProcess</option>
                      <option value="Meeting">Meeting</option>
                      <option value="Verified">Verified</option>
                    </select>
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditCustomer(null);
                }}
              >
                Close
              </button>

              <button
                type="submit"
                className="btn btn-success"
                id="edit-btn"
                disabled={isAddButtonDisabled}
              >
                Follow Up
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Modal of Success */}
      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Customer Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default Follow;
