import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Row } from "reactstrap";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

// ... (previous code remains unchanged)

const SalesReport = () => {
  const [addPlotData, setPlotData] = useState([]);
  const [Booking, setBooking] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Customer, setCustomer] = useState([]);
  const [printDisabled, setPrintDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);

  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(`${baseUrl}/plot/`);
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlotData(filteredData);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
      const fetchCustomerData = await fetch(`${baseUrl}/accounts/`);
      if (fetchCustomerData.ok) {
        const data = await fetchCustomerData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomer(filteredData);
      } else {
        console.error("Error fetching data:", fetchCustomerData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // get Plot Name
  const getPlotName = (plotID) => {
    const plot = addPlotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = Customer.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const fetchBookingData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");
      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }
      const fetchBookings = await fetch(`${baseUrl}/booking/`);
      if (fetchBookings.ok) {
        const data = await fetchBookings.json();
        const filteredData = data.filter(
          (item) =>
            item.group.toString() === groupId &&
            (!startDate ||
              new Date(item.booking_date) >= new Date(startDate)) &&
            (!endDate || new Date(item.booking_date) <= new Date(endDate))
        );
        setBooking(filteredData);
      } else {
        console.error(
          "Error fetching Bookings data:",
          fetchBookings.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      const response = await fetch(`${baseUrl}/booking/`);
      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter((item) => {
          const entryDate = new Date(item.booking_date);
          const startDateObj = startDate && new Date(startDate);
          const endDateObj = endDate && new Date(endDate);
          return (
            (!startDateObj || entryDate >= startDateObj) &&
            (!endDateObj || entryDate <= endDateObj)
          );
        });

        setBooking(filteredData);
        setPrintDisabled(false);
        setSearchClicked(true);
        setError(false);
      } else {
        console.error("Error fetching data:", response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchClicked) {
      fetchBookingData();
    }
  }, [startDate, endDate, searchClicked]);
  //   // Print Function
  const navigate = useNavigate();
  const handlePrint = () => {
    if (Booking) {
      navigate("/sales-report/sale-report-print/", {
        state: {
          Booking: Booking,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <form className="tablelist-form" encType="multipart/form-data">
          <div id="AddBookingList">
            <Row className="g-4">
              <div className="col-md-4">
                <label htmlFor="start_date">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="start_date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="end_date">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="end_date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className="col-md-1">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <Button
                  color="success"
                  className="add-btn"
                  type="button"
                  onClick={handleSearch}
                  disabled={loading}
                >
                  {loading ? "Searching..." : "Search"}
                </Button>
              </div>
              <div className="col-md-1">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <Button
                  color="primary"
                  className="add-btn"
                  onClick={handlePrint}
                  disabled={printDisabled}
                >
                  Print
                </Button>
              </div>
            </Row>
          </div>
        </form>

        <div className="table-responsive table-card mt-3">
          <h5
            className="p-2 text-center mt-4"
            style={{ color: "white", background: "black" }}
          >
            Sales Report
          </h5>

          <table
            className="table align-middle table-nowrap"
            id="customer-table"
          >
            <thead className="table-light">
              <tr>
                <th>Sr#</th>
                <th>Name</th>
                <th>CNIC</th>
                <th>Plot#</th>
                <th>SizeValue</th>
                <th>Total Amount</th>
                <th>Advance</th>
              </tr>
            </thead>

            <tbody>
              {Booking.map((booking, index) => (
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td className="customer_id">
                    {getCustomerName(booking.customer)}
                  </td>
                  <td>{booking.cnic}</td>
                  <td>{getPlotName(booking.plot)}</td>
                  <td>
                    {booking.size && booking.size + " " + booking.size_unit}
                  </td>
                  <td>{booking.total}</td>
                  <td>{booking.advance}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="page-content">
          {error && (
            <div className="alert alert-danger" role="alert">
              No data available for the selected criteria.
            </div>
          )}

          {/* Additional content if needed */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SalesReport;
