import React from "react";
import { Navigate } from "react-router-dom";

// Import Category
import Category from "../Pages/Category/Category";

// Import Block
import Block from "../Pages/Block/Block";

// Import Plot
import Plot from "../Pages/Plot/Plot";

// Import Agent
import Agent from "../Pages/Agent/Agent";

// Import Customer
import Customer from "../Pages/CRM/Customer.js";

// Import Follow
import Follow from "../Pages/CRM/Follow.js";

// Import packages
import Packages from "../Pages/Packages/Packages";

// Import Booking
import Booking from "../Pages/Booking/Booking";

// Import Booking
import ShowBooking from "../Pages/Booking/ShowBooking";
// Import FileTransfer
import FileTransfer from "../Pages/FileTransfer/FileTransfer.js";
// Import File Deliverd
import FileDelivered from "../Pages/FileDelivered/FileDelivered.js";



// Import File Deliverd
import Receipt from "../Pages/Receipt/Receipt.js";

// imprty plot report 
import PlotReport from "../Pages/Report/PlotReport.js";

// import Expense
import Expense from "../Pages/Expense/Expense.js";

// import Accounts
import Accounts from "../Pages/Accounts/Accounts.js";

// import Purchase 
import Purchase from "../Pages/Purchase/Purchase.js";

// import Investor Profile
import InvestorProfile from "../Pages/InvsetorPlan/InvestorPlan.js";

// import Payment Voucher 
import PaymentVoucher from "../Pages/PaymentVoucher/PaymentVoucher.js";

// ReceiptVoucher
import ReceiptVoucher from "../Pages/ReceiptVoucher/ReceiptVoucher.js";

// PaymentVoucherList
import PaymentVoucherList from "../Pages/PaymentVoucher/PaymentVoucherList.js";

// ReceiptVoucherList
import ReceiptVoucherList from "../Pages/ReceiptVoucher/ReceiptVoucherList.js";

// Journal Report
import JournalReport from "../Pages/Report/JournalReport.js";
// RecoveryReport
import RecoveryReport from "../Pages/Report/RecoveryReport.js";
//Dashboard
import Dashboard from "../Pages/Dashboard";
// Import Authentication pages
import Login from "../Pages/Authentication/Login";
import ForgetPasswordPage from "../Pages/Authentication/ForgetPassword";
import Logout from "../Pages/Authentication/Logout";
import UserProfile from "../Pages/Authentication/user-profile";
import changePassword from "../Pages/Authentication/change-password.js";
import Register from "../Pages/Authentication/Register.js";
import UsersList from "../Pages/Authentication/users-list.js";

// Import Authentication Inner Pages
import Login1 from "../Pages/AuthenticationPages/Login";
import Register1 from "../Pages/AuthenticationPages/Register";
import RecoverPassword from "../Pages/AuthenticationPages/RecoverPassword";
import LockScreen from "../Pages/AuthenticationPages/LockScreen";

// import Print 


//  Booking Print
import BookingPrint from "../Pages/Print/BookingPrint.js";
import CustomerPrint from "../Pages/Print/CustomerPrint.js";
import SingleCustomerPrint from "../Pages/Print/SingleCustomerPrint.js";
import PrintReceipt from "../Pages/Print/ReceiptPrint.js";
import PrintPlotReport from "../Pages/Print/PrintPlotReport.js";
import SingleBookingPrint from "../Pages/Print/SingleBookingPrint.js";
import ShowBookingPrint from "../Pages/Print/ShowBookingPrint.js";
import RecoveryPrint from "../Pages/Print/RecoveryReportPrint.js";
import AccountReportPrint from "../Pages/Print/AccountReportPrint.js";
import TrialBalance from "../Pages/Report/TrialBalance.js";
import SalesReport from "../Pages/Report/SalesReport.js";
import SalesReportPrint from "../Pages/Print/SalesReportPrint.js";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/category", component: <Category /> },
  { path: "/change-password", component: <changePassword /> },
  { path: "/register", component: <Register /> },
  { path: "/users-list", component: <UsersList /> },
  { path: "/block", component: <Block /> },
  { path: "/plot", component: <Plot /> },
  { path: "/agent", component: <Agent /> },
  { path: "/customer", component: <Customer /> },
  { path: "/follow-up", component: <Follow /> },
  { path: "/packages", component: <Packages /> },
  { path: "/booking", component: <Booking /> },
  { path: "/show-booking", component: <ShowBooking /> },
  { path: "/userprofile", component: <UserProfile /> },
  { path: "/file-transefer/", component: <FileTransfer /> },
  { path: "/file-delivered/", component: <FileDelivered /> },
  { path: "/receipt/", component: <Receipt /> },
  // Reports
  { path: "/plot-report/", component: <PlotReport /> },
  { path: "/journal-report/", component: <JournalReport /> },
  { path: "/recovery-report/", component: <RecoveryReport /> },
  { path: "/expense/", component: <Expense /> },
  { path: "/accounts/", component: <Accounts /> },
  { path: "/purchase/", component: <Purchase /> },
  { path: "/investor-profile/", component: <InvestorProfile /> },
  { path: "/payment-voucher/", component: <PaymentVoucher /> },
  { path: "/receipt-voucher/", component: <ReceiptVoucher /> },
  { path: "/view-payment-voucher/", component: <PaymentVoucherList /> },
  { path: "/view-receipt-voucher/", component: <ReceiptVoucherList /> },
    { path: "/trial-balance-report/", component: <TrialBalance /> },
    { path: "/sales-report/", component: <SalesReport /> },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },


  // Print Functionality

  { path: "/booking/booking-print/", component: <BookingPrint /> },
  // Customer Print
  { path: "/customer/customer-print/", component: <CustomerPrint /> },
  // single Customer Print
  { path: "/customer/single-customer-print/", component: <SingleCustomerPrint /> },
  // Receipt Print
  { path: "/receipt/receipt-print/", component: <PrintReceipt /> },
  // Plot Report Print
  { path: "/plot-report/plot-report-print/", component: <PrintPlotReport /> },
  // Recovery Report Print
  { path: "/recovery-report/recovery-report-print/", component: <RecoveryPrint /> },
  // Plot Report Print
  { path: "/journal-report/account-report-print/", component: <AccountReportPrint /> },
  // Sales Report Print
  { path: "/sales-report/sale-report-print/", component: <SalesReportPrint /> },

  // single print booking
  { path: "/booking/single-booking-print/", component: <SingleBookingPrint /> },
  // ShowBooking print booking
  { path: "/booking/show-booking-print/", component: <ShowBookingPrint /> },

];


const publicRoutes = [

  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },

  // Authentication Inner Pages
  { path: "/auth-login", component: <Login1 /> },
  { path: "/auth-register", component: <Register1 /> },
  { path: "/auth-recoverpw", component: <RecoverPassword /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },

];

export { authProtectedRoutes, publicRoutes };