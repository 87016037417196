import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Purchase = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [purchase, setpurchase] = useState([]);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [SupplierAccount, setSupplierAccount] = useState([]);
  const [AccountName, setAccountName] = useState([]);
  const [purchaseToDelete, setpurchaseToDelete] = useState(null);
  const [editpurchase, setEditpurchase] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [addPurchase, setAddPurchase] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState("");

  const [AddPurchase, setAddNewPurchase] = useState({
    date: "",
    account: "",
    description: "",
    amount: "",
    remaining_amount: "",
    moza: "",
    acer_no: "",
    khatooni_no: "",
    khata_no: "",
    total_marla: "",
    promise_date: "",
    document: null,
  });
  const token = sessionStorage.getItem("token");
  const groupId = sessionStorage.getItem("groupId");
  const loginId = sessionStorage.getItem("login");

  const navigate = useNavigate();

  function tog_list() {
    setmodal_list(!modal_list);
  }

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };
  // Fetch accounts data when the component mounts

  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/purchase-management/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setSupplierAccount(filteredData);
      } else {
        console.error("Error fetching Purcahse Data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataAccount = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAccountName(filteredData);
      } else {
        console.error("Error fetching Account Data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }
        if (!found) {
          showRow = false;
        }
      }
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  useEffect(() => {
    fetchData();
    fetchDataAccount();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "account") {
      setSelectedAccountId(value);
      setAddNewPurchase({ ...AddPurchase, account: value });
    } else {
      setAddNewPurchase({ ...AddPurchase, [name]: value });
    }
  };

  const handleDocumentChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setAddNewPurchase((prevPurchase) => ({
        ...prevPurchase,
        document: file,
      }));
    }
  };

  const handleAddPurchase = async (e) => {
    e.preventDefault();

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }

    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("date", AddPurchase.date);
    formData.append("account", AddPurchase.account);
    formData.append("advance", AddPurchase.advance);
    formData.append("balance", AddPurchase.balance);
    formData.append("installments", AddPurchase.installments);
    formData.append("description", AddPurchase.description);
    formData.append("amount", AddPurchase.amount);
    formData.append("remaining_amount", AddPurchase.remaining_amount);
    formData.append("moza", AddPurchase.moza);
    formData.append("acer_no", AddPurchase.acer_no);
    formData.append("khatooni_no", AddPurchase.khatooni_no);
    formData.append("khata_no", AddPurchase.khata_no);
    formData.append("total_marla", AddPurchase.total_marla);
    formData.append("promise_date", AddPurchase.promise_date);
    formData.append("remarks", AddPurchase.remarks);

    if (AddPurchase.document !== null) {
      formData.append("document", AddPurchase.document);
    }

    try {
      const response = await fetch(`${baseUrl}/purchase-management/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        setAddPurchase([...addPurchase, data]);
        setAddNewPurchase({
          date: "",
          account: "",
          advance: "",
          installments: "",
          description: "",
          amount: "",
          remaining_amount: "",
          khata_no: "",
          remarks: "",
          promise_date: "",
          document: null,
        });
        setmodal_list(false);
        setShowSuccessModal(true);
        fetchData();
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        console.error("Failed to add the Purchase:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the Purchase:", error);
    }
  };

  // fetch data to edit purchase

  const handleEditpurchase = async (purchase) => {
    try {
      const response = await fetch(
        `${baseUrl}/purchase-management/${purchase.id}`
      );
      if (response.ok) {
        const data = await response.json();

        const editedpurchaseData = { ...data };

        setEditpurchase(editedpurchaseData);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the purchase for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the purchase:", error);
    }
  };

  // fecth data to Save edit or update

  const handleSaveEdit = async () => {
    if (!editpurchase) return;

    const formData = new FormData();

    // Append non-file data
    formData.append("user", loginId);
    formData.append("login", loginId);
    formData.append("group", groupId);
    formData.append("date", editpurchase.date);
    formData.append("moza", editpurchase.moza);
    formData.append("acer_no", editpurchase.acer_no);
    formData.append("khatooni_no", editpurchase.khatooni_no);
    formData.append("khata_no", editpurchase.khata_no);
    formData.append("total_marla", editpurchase.total_marla);
    formData.append("account", editpurchase.account);
    formData.append("description", editpurchase.description);
    formData.append("amount", editpurchase.amount);
    formData.append("remaining_amount", editpurchase.remaining_amount);
    formData.append("advance", editpurchase.advance);
    formData.append("installments", editpurchase.installments);
    formData.append("promise_date", editpurchase.promise_date);
    formData.append("remarks", editpurchase.remarks);

    // Check if there's a document update
    if (editpurchase.document instanceof File) {
      formData.append("document", editpurchase.document);
    }

    try {
      const response = await fetch(
        `${baseUrl}/purchase-management/${editpurchase.id}/`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedPurchaseData = await response.json();
        console.log("Purchase updated:", updatedPurchaseData);

        setpurchase((prevPurchase) =>
          prevPurchase.map((purchase) =>
            purchase.id === updatedPurchaseData.id
              ? updatedPurchaseData
              : purchase
          )
        );

        setmodal_edit(false);
        setEditpurchase(null);
        fetchData();
      } else {
        console.error("Failed to edit the purchase:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the purchase:", error);
    }
  };

  // Delete purchase

  const handleDeletepurchase = async (purchase) => {
    if (purchase) {
      try {
        const response = await fetch(`/purchase-management/${purchase.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("purchase deleted:", purchase.id);

          // Remove the deleted purchase from the purchase state
          setpurchase((prevpurchase) =>
            prevpurchase.filter((c) => c.id !== purchase.id)
          );

          setmodal_delete(false);
          setShowAlertDelete(true);
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
          fetchData();
        } else {
          console.error("Failed to delete the purchase:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the purchase:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Purchase" breadcrumbItem="Purchase Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">purchase</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="purchaseList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {hasPermission("add_purchase") && (
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => setmodal_list(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Purchase
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ dimension: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="name">
                              Date
                            </th>
                            <th className="sort" data-sort="size">
                              Account
                            </th>
                            <th className="sort" data-sort="business_type">
                              Description
                            </th>
                            <th className="sort" data-sort="title">
                              Amount
                            </th>
                            <th className="sort" data-sort="title">
                              Remaining Amount
                            </th>
                            <th className="sort" data-sort="title">
                              Promise Date
                            </th>
                            <th className="sort" data-sort="title">
                              Attachement
                            </th>
                            <th className="sort" data-sort="title">
                              Remarks
                            </th>
                            <th
                              className="sort"
                              data-sort="action"
                              style={{ dimension: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {SupplierAccount.map((purchase, index) => (
                            <tr key={purchase.id}>
                              <th scope="row"></th>
                              <td className="sr">{index + 1}</td>
                              <td className="date">{purchase.date}</td>
                              <td className="account">{purchase.account}</td>

                              <td className="description">
                                {purchase.description}
                              </td>
                              <td className="amount">{purchase.amount}</td>
                              <td className="promise_date">
                                {purchase.promise_date}
                              </td>
                              <td className="promise_date">
                                {purchase.remaining_amount}
                              </td>
                              <td className="document">
                                <img
                                  src={purchase.document}
                                  alt="document"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    marginTop: "20px",
                                  }}
                                />
                              </td>
                              <td className="promise_date">
                                {purchase.remarks}
                              </td>

                              <td>
                                <div className="d-flex gap-2">
                                  {hasPermission("change_purchase") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => {
                                          handleEditpurchase(purchase); // Open the delete modal
                                        }}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {hasPermission("delete_purchase") && (
                                    <Button
                                      className="btn btn-sm btn-danger edit-item-btn"
                                      onClick={() => {
                                        setpurchaseToDelete(purchase); // Set the purchase to be deleted
                                        setmodal_delete(true); // Open the delete modal
                                      }}
                                      color="soft-danger"
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Add Modal */}

      <Modal
        isOpen={modal_list}
        toggle={tog_list}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          Add Purchase
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddPurchase}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  className="form-control"
                  placeholder="Date"
                  required
                  onChange={handleInputChange}
                />
              </div>

              <div className="col-sm-4">
                <label htmlFor="account" className="form-label">
                  Seller Account
                </label>
                <select
                  className="form-control"
                  name="account"
                  id="account"
                  onChange={handleInputChange}
                >
                  <option value="">Choose Account</option>
                  {AccountName.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-5">
                <label htmlFor="name-field" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  id="description"
                  name="description"
                  className="form-control"
                  placeholder="Description"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row  mb-4">
              <div className="col-sm-2">
                <label htmlFor="size-field" className="form-label">
                  Total Amount
                </label>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  className="form-control"
                  placeholder="Total Amount"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="size-field" className="form-label">
                  Advance
                </label>
                <input
                  type="text"
                  id="advance"
                  name="advance"
                  className="form-control"
                  placeholder="Advance"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="size-field" className="form-label">
                  Remaining
                </label>
                <input
                  type="text"
                  id="remaining_amount"
                  name="remaining_amount"
                  className="form-control"
                  placeholder="Remaining Amount"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="size-field" className="form-label">
                  No.of.Installment
                </label>
                <input
                  type="text"
                  id="installments"
                  name="installments"
                  className="form-control"
                  placeholder="Total Installments"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="promise_date" className="form-label">
                  Promise Date
                </label>
                <input
                  type="date"
                  id="promise_date"
                  name="promise_date"
                  className="form-control"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row  mb-4">
              <div className="col-md-3">
                <label htmlFor="moza" className="form-label">
                  Moza
                </label>
                <input
                  type="text"
                  id="moza"
                  name="moza"
                  className="form-control"
                  placeholder="Enter the Moza"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="khatooni_no" className="form-label">
                  Khatooni#
                </label>
                <input
                  type="text"
                  id="khatooni_no"
                  name="khatooni_no"
                  placeholder="Enter the Khatooni #"
                  className="form-control"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="acer_no" className="form-label">
                  Khata#
                </label>
                <input
                  type="text"
                  id="khata_no"
                  name="khata_no"
                  className="form-control"
                  placeholder="Enter the Khata#"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="acer_no" className="form-label">
                  Acer#
                </label>
                <input
                  type="text"
                  id="acer_no"
                  name="acer_no"
                  className="form-control"
                  placeholder="Enter the Acer #"
                  required
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row  mb-4">
              <div className="col-md-3">
                <label htmlFor="total_marla" className="form-label">
                  Total Marlas
                </label>
                <input
                  type="text"
                  id="total_marla"
                  name="total_marla"
                  className="form-control"
                  placeholder="Enter the total Marlas"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="p_img">Attachement</label>
                <input
                  type="file"
                  id="document"
                  name="document"
                  className="form-control"
                  placeholder="Choose Document"
                  onChange={handleDocumentChange}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="promise_date" className="form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  id="remarks"
                  name="remarks"
                  className="form-control"
                  placeholder="Enter Remarks"
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="row  mb-3"></div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button type="submit" className="btn btn-success" id="add-btn">
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Add modal end  */}

      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit purchase
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editpurchase && (
              <>
                <div className="row mb-2">
                  <div className="col-sm-3">
                    <label htmlFor="name-field" className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      id="entry_date"
                      name="entry_date"
                      className="form-control"
                      required
                      value={editpurchase.date}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="account" className="form-label">
                      Accounts
                    </label>
                    <select
                      className="form-control"
                      name="account"
                      id="account"
                      value={editpurchase.account}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          account: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Account</option>
                      {AccountName.map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-5">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <input
                      type="text"
                      id="description"
                      name="description"
                      className="form-control"
                      placeholder="description"
                      required
                      value={editpurchase.description}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          description: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-2">
                  <div className="col-sm-4">
                    <label htmlFor="moza" className="form-label">
                      Moza
                    </label>
                    <input
                      type="text"
                      id="moza"
                      name="moza"
                      className="form-control"
                      placeholder="Amount"
                      required
                      value={editpurchase.moza}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          moza: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="khatooni_no" className="form-label">
                      Khatooni#
                    </label>
                    <input
                      type="text"
                      id="khatooni_no"
                      name="khatooni_no"
                      className="form-control"
                      placeholder="Khatooni#"
                      required
                      value={editpurchase.khatooni_no}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          khatooni_no: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="khata_no" className="form-label">
                      Khata#
                    </label>
                    <input
                      type="text"
                      id="khata_no"
                      name="khata_no"
                      className="form-control"
                      required
                      value={editpurchase.khata_no}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          khata_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="acer_no" className="form-label">
                      Acer#
                    </label>
                    <input
                      type="text"
                      id="acer_no"
                      name="acer_no"
                      className="form-control"
                      placeholder="Acer#"
                      required
                      value={editpurchase.acer_no}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          acer_no: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="total_marla" className="form-label">
                      Total Marlas
                    </label>
                    <input
                      type="number"
                      id="total_marla"
                      name="total_marla"
                      className="form-control"
                      placeholder="Total Marlas"
                      required
                      value={editpurchase.total_marla}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          total_marla: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="total_marla" className="form-label">
                      Installments
                    </label>
                    <input
                      type="text"
                      id="installments"
                      name="installments"
                      className="form-control"
                      placeholder="Total Installments"
                      required
                      value={editpurchase.installments}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          installments: e.target.value,
                        })
                      }
                    />
                  </div>
                  </div>

<div className="row mb-2">
                  <div className="col-sm-4">
                    <label htmlFor="total_marla" className="form-label">
                      Advance
                    </label>
                    <input
                      type="text"
                      id="advance"
                      name="advance"
                      className="form-control"
                      placeholder="Advance"
                      required
                      value={editpurchase.advance}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          advance: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="promise_date" className="form-label">
                      Promise Date
                    </label>
                    <input
                      type="date"
                      id="promise_date"
                      name="promise_date"
                      className="form-control"
                      required
                      value={editpurchase.promise_date}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          promise_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="document-field" className="form-label">
                      Edit Image Document
                    </label>
                    <input
                      type="file"
                      id="document-field"
                      className="form-control"
                      placeholder="Enter Address"
                      required
                      //   onChange={handleImageChange}
                    />
                    {editpurchase.document && (
                      <img
                        src={
                          editpurchase.document instanceof File
                            ? URL.createObjectURL(editpurchase.document)
                            : editpurchase.document
                        }
                        alt="Document Preview"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                          marginTop: "20px",
                        }}
                      />
                    )}
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="promise_date" className="form-label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      required
                      value={editpurchase.remarks}
                      onChange={(e) =>
                        setEditpurchase({
                          ...editpurchase,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditpurchase(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ dimension: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (purchaseToDelete) {
                  handleDeletepurchase(purchaseToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Purchase Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Purchase;
