import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";

import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const LatestTransation = () => {
  const [showReceipt, setShowReceipt] = useState([]);

  const fetchReceipts = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/receipt/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setShowReceipt(filteredData);
      } else {
        console.error("Error fetching Data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Data:", error);
    }
  };

  useEffect(() => {
    fetchReceipts();
  }, []);
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Latest Transaction</h4>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style={{ width: "50px" }}>
                        <div className="form-check">
                          <label
                            className="form-check-label"
                            htmlFor="customCheckall"
                          ></label>
                        </div>
                      </th>
                      <th className="sort" data-sort="srNumber">
                        Sr#
                      </th>

                      <th className="sort" data-sort="date">
                        Date
                      </th>
                      <th className="sort" data-sort="customer">
                        Customer
                      </th>
                      <th className="sort" data-sort="plot">
                        Plot#
                      </th>

                      <th className="sort" data-sort="amount">
                        Amount
                      </th>
                      <th className="sort" data-sort="refrence_no">
                        Refrence#
                      </th>
                      <th className="sort" data-sort="remarks">
                        Remarks
                      </th>
                    </tr>
                  </thead>
                  <tbody className="list form-check-all" id="myTable">
                    {showReceipt.map((receipt, index) => (
                      <tr key={receipt.id}>
                        <th scope="row"></th>
                        <td className="srNumber">{index + 1}</td>
                        <td className="entry_date">{receipt.entry_date}</td>
                        <td className="block">{receipt.customer}</td>
                        <td className="plot">{receipt.plot}</td>
                        <td className="amount">{receipt.amount}</td>
                        <td className="ref_no">{receipt.ref_no}</td>
                        <td className="remarks">{receipt.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LatestTransation;
