import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";


import { env } from "../../config";
const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;
const fetchDataWithFilter = async (url, setData, groupId) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok for ${url}`);
    }
    const data = await response.json();
    // Filter data based on the groupId
    const filteredData = data.filter((item) => item.groupId === groupId);
    setData(filteredData);
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
  }
};

const ShowBooking = () => {
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [bookings, setbookings] = useState([]);
  const [Packages, setPackages] = useState([]);
  const [bookingToDelete, setbookingToDelete] = useState(null);
  const [Customer, setCustomer] = useState([]);
  const [Block, setBlock] = useState([]);
  const [Plot, setPlot] = useState([]);
  const [Agent, setAgent] = useState([]);
  const [EditBooking, setEditBooking] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  const navigate = useNavigate()

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };



  const getCustomerName = (customerID) => {

    const customer = Customer.find((customer) => customer.id === customerID);
    return customer ? customer.name : "N/A";
  };

  const getBlockName = (blockID) => {
    const block = Block.find((block) => block.id === blockID);
    return block ? block.name : "N/A";
  };

  const getPlotName = (plotID) => {
    const plot = Plot.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };
  const getAgentName = (agentID) => {
    const agent = Agent.find((agent) => agent.id === agentID);
    return agent ? agent.name : "N/A";
  };

  // Fetch Categorys data when the component mounts
  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/booking/`);
      if (response.ok) {
        const data = await response.json();
        console.log(data)
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setbookings(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }

      const account = await fetch(`${baseUrl}/accounts/`);
      if (account.ok) {
        const data = await account.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomer(filteredData);
      } else {
        console.error("Error fetching data:", account.statusText);
      }

      const agentdata = await fetch(`${baseUrl}/agent/`);
      if (agentdata.ok) {
        const data = await agentdata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAgent(filteredData);
      } else {
        console.error("Error fetching data:", agentdata.statusText);
      }

      const packagedata = await fetch(`${baseUrl}/packages/`);
      if (packagedata.ok) {
        const data = await packagedata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPackages(filteredData);
      } else {
        console.error("Error fetching data:", packagedata.statusText);
      }

      const plotdata = await fetch(`${baseUrl}/plot/`);
      if (plotdata.ok) {
        const data = await plotdata.json();

        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlot(filteredData);
      } else {
        console.error("Error fetching data:", plotdata.statusText);
      }

      const blockdata = await fetch(`${baseUrl}/block/`);
      if (blockdata.ok) {
        const data = await blockdata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setBlock(filteredData);
      } else {
        console.error("Error fetching data:", blockdata.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  // For image
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage instanceof File) {
      console.log("Selected Image:", selectedImage);
      setEditBooking((prevEditBooking) => ({
        ...prevEditBooking,
        document: selectedImage,
        image_url: URL.createObjectURL(selectedImage),
      }));
    } else {
      console.error(
        "Invalid image selected. Please choose a valid image file."
      );
    }
  };

  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditBooking((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const size = parseFloat(name === "size" ? value : EditBooking.size) || 0;
    const price = parseFloat(name === "price" ? value : EditBooking.price) || 0;
    const advance =
      parseFloat(name === "advance" ? value : EditBooking.advance) || 0;
    const commissionPercent =
      parseFloat(
        name === "commission_percent" ? value : EditBooking.commission_percent
      ) || 0;

    const total = size * price;
    const balance = total - advance;
    const commissionAmount = (balance * commissionPercent) / 100;

    setEditBooking((prevFormData) => ({
      ...prevFormData,
      total: total.toFixed(2),
      balance: balance.toFixed(2),
      commission_amount: commissionAmount.toFixed(2),
    }));
  };

  const handleInputChangeInstallment = (e) => {
    const { name, value } = e.target;

    setEditBooking((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const balanceInstallment = parseFloat(EditBooking.balance) || 0;
    const no_0f_installment = parseFloat(value) || 0;

    const InstallmentAmount =
      no_0f_installment !== 0 ? balanceInstallment / no_0f_installment : 0;

    setEditBooking((prevFormData) => ({
      ...prevFormData,
      amount_package: InstallmentAmount.toFixed(2),
      no_0f_installment: value, // Update to the input value
    }));
  };

  const handleInputChangeInstallmentAmount = (e) => {
    const { name, value } = e.target;

    setEditBooking((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const balanceInstallment = parseFloat(EditBooking.balance) || 0;
    const amount_package = parseFloat(value) || 0;

    const InstallmentAmount =
      amount_package !== 0 ? balanceInstallment / amount_package : 0;

    setEditBooking((prevFormData) => ({
      ...prevFormData,
      no_0f_installment: InstallmentAmount.toFixed(2),
      amount_package: value, // Update to the input value
    }));
  };

  // fetch data to edit booking

  const handleEditBooking = async (booking) => {
    try {
      const response = await fetch(`${baseUrl}/booking/${booking.id}`);
      if (response.ok) {
        const data = await response.json();

        // Set previous image and document URLs

        // Update the EditBooking state
        setEditBooking(data);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the booking for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the booking:", error);
    }
  };

  // fecth data to Save edit or update

  const handleSaveEdit = async () => {
    if (!EditBooking) return;
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!groupId) {
      console.error("Group ID not found in the session.");
      return;
    }
    const formData = new FormData();
    // Include login_id in the form data
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("booking_no", EditBooking.booking_no);
    formData.append("booking_date", EditBooking.booking_date);
    formData.append("customer", EditBooking.customer);
    formData.append("phone", EditBooking.phone);
    formData.append("cnic", EditBooking.cnic);
    formData.append("city", EditBooking.city);
    formData.append("address", EditBooking.address);
    formData.append("block", EditBooking.block);
    formData.append("plot", EditBooking.plot);
    formData.append("size", EditBooking.size);
    formData.append("size_unit", EditBooking.size_unit);
    formData.append("dimension", EditBooking.dimension);
    formData.append("no_0f_installment", EditBooking.no_0f_installment);
    formData.append("price", EditBooking.price);
    formData.append("total", EditBooking.total);
    formData.append("advance", EditBooking.advance);
    formData.append("balance", EditBooking.balance);
    formData.append("package_choice", EditBooking.package_choice);
    formData.append("amount_package", EditBooking.amount_package);
    formData.append("agent", EditBooking.agent);
    formData.append("promise_date", EditBooking.promise_date);
    formData.append("commission_percent", EditBooking.commission_percent);
    formData.append("commission_percent", EditBooking.commission_percent);
    formData.append("commission_amount", EditBooking.commission_amount);
    formData.append("remarks", EditBooking.remarks);
    // formData.append("document", EditBooking.document);

    // Check if a new image is selected before appending to FormData
    if (EditBooking.document && EditBooking.document instanceof File) {
      formData.append("document", EditBooking.document);
    } else if (EditBooking.image_url) {
      // If image_url is present, assume it's a URL and append it
      formData.append("image_url", EditBooking.image_url);
    }
    try {
      const response = await fetch(`${baseUrl}/booking/${EditBooking.id}/`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        const updatedbookingData = await response.json();
        console.log("booking updated:", updatedbookingData);

        setbookings((prevbookings) =>
          prevbookings.map((booking) =>
            booking.id === updatedbookingData.id ? updatedbookingData : booking
          )
        );

        setmodal_edit(false);
        setEditBooking(null);
      } else {
        console.error("Failed to edit the booking:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the booking:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // Delete booking

  const handleDeletebooking = async (booking) => {
    if (booking) {
      try {
        const response = await fetch(`${baseUrl}/booking/${booking.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("booking Deleted:", booking.id);

          // Remove the deleted booking from the bookings state
          setbookings((prevbooking) =>
            prevbooking.filter((c) => c.id !== booking.id)
          );

          setmodal_delete(false); // Close the delete modal
        } else {
          console.error("Failed to delete the booking:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the booking:", error);
      }
    }
  };

  //   // Print Function
  const handlePrint = (bookingNumber) => {
    // Find the selected booking based on the booking number
    const selectedBooking = bookings.find((booking) => booking.booking_no === bookingNumber);
    console.log("Data of Selected Booking",selectedBooking)
    navigate('/booking/show-booking-print/', { state: { data: selectedBooking } });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Booking" breadcrumbItem="Booking List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">booking</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="bookingList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="primary"
                            className="add-btn "
                            onClick={handlePrint}
                          >
                            <span className="mdi mdi-printer"> Print</span>
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col"></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="code">
                              Booking #
                            </th>
                            <th className="sort" data-sort="name">
                              Date
                            </th>
                            <th className="sort" data-sort="credi_limit">
                              Customer
                            </th>
                            <th className="sort" data-sort="credi_limit">
                              Phone
                            </th>

                            <th className="sort" data-sort="bill_limit">
                              Block
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Plot
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Size
                            </th>
                            <th className="sort" data-sort="bill_limit">
                              Size unit
                            </th>

                            <th className="sort" data-sort="bill_limit">
                              Promise Date
                            </th>

                            <th className="sort" data-sort="bill_limit">
                              Agent
                            </th>

                            <th className="sort" data-sort="bill_limit">
                              Images
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ no_0f_installment: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {bookings.map((booking, index) => (
                            <tr key={booking.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="booking_no">
                                {booking.booking_no}
                              </td>
                              <td className="f_name">{booking.booking_date}</td>
                              <td className="customer_id">
                                {getCustomerName(booking.customer)}
                              </td>
                              <td className="name">{booking.phone}</td>
                              <td className="customer_id">
                                {getBlockName(booking.block)}
                              </td>
                              <td className="customer_id">
                                {getPlotName(booking.plot)}
                              </td>
                              <td className="name">{booking.size}</td>
                              <td className="name">{booking.size_unit}</td>

                              <td className="name">{booking.promise_date}</td>
                              <td className="customer_id">
                                {getAgentName(booking.agent)}
                              </td>

                              <td className="img">
                                <img
                                  src={booking.document}
                                  alt="booking"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    marginTop: "20px",
                                  }}
                                />
                              </td>

                              <td>
                                <div className="d-flex gap-2">

                                  <div className="print">
                                    <button
                                      className="btn btn-sm btn-primary print-item-btn"
                                      onClick={() => {
                                        handlePrint(booking.booking_no);
                                      }}
                                      color="soft-primary"
                                    >
                                      <i className="ri-printer-line"></i>
                                    </button>
                                  </div>
                          {hasPermission("change_booking") && (

                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() => {
                                        handleEditBooking(booking); // Open the delete modal
                                      }}
                                      color="soft-success"
                                    >
                                      <i className="ri-edit-2-line"></i>
                                    </button>
                                  </div>
                          )}
                          {hasPermission("delete_booking") && (

                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn"
                                    onClick={() => {
                                      setbookingToDelete(booking); // Set the booking to be deleted
                                      setmodal_delete(true); // Open the delete modal
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                          )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ dimension: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Booking
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {EditBooking && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="name-field" className="form-label">
                      Booking #
                    </label>
                    <input
                      type="text"
                      id="booking_no"
                      name="booking_no"
                      className="form-control"
                      placeholder="Enter booking"
                      required
                      value={EditBooking.booking_no}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          booking_no: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="f_name-field" className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      id="booking_date"
                      name="booking_date"
                      className="form-control"
                      required
                      value={EditBooking.booking_date}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          booking_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Customer
                    </label>
                    <select
                      className="form-control"
                      name="customer"
                      id="customer"
                      value={EditBooking.customer}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          customer: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Customer</option>
                      {Customer.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="cnic-field" className="form-label">
                      Phone
                    </label>
                    <input
                      type="text"
                      id="cnic-field"
                      className="form-control"
                      placeholder="Enter booking"
                      required
                      value={EditBooking.phone}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          phone: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="cnic-field" className="form-label">
                      CNIC
                    </label>
                    <input
                      type="text"
                      id="cnic"
                      name="cnic"
                      className="form-control"
                      placeholder="Enter booking"
                      required
                      value={EditBooking.cnic}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          cnic: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      id="whatsapp-field"
                      className="form-control"
                      placeholder="Enter booking"
                      required
                      value={EditBooking.city}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          city: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Block
                    </label>
                    <select
                      className="form-control"
                      name="block"
                      id="block"
                      value={EditBooking.block} // Use the region ID from editblock
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          block: e.target.value, // Update region ID
                        })
                      }
                    >
                      <option value="">Choose Block</option>
                      {Block.map((block) => (
                        <option key={block.id} value={block.id}>
                          {block.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="gender-field" className="form-label">
                      Plot
                    </label>
                    <select
                      className="form-control"
                      name="plot"
                      id="plot"
                      value={EditBooking.plot} // Use the region ID from editblock
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          plot: e.target.value, // Update region ID
                        })
                      }
                    >
                      <option value="">Choose Plot</option>
                      {Plot.map((plot) => (
                        <option key={plot.id} value={plot.id}>
                          {plot.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Size
                    </label>
                    <input
                      type="number"
                      id="size"
                      name="size"
                      className="form-control"
                      placeholder="Enter Size"
                      required
                      value={EditBooking.size}
                      onChange={handleInputChange} // Attach handleInputChange here
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Size Unit
                    </label>
                    <input
                      type="text"
                      id="size_unit"
                      name="size_unit"
                      className="form-control"
                      placeholder="Enter Size Unit"
                      required
                      value={EditBooking.size_unit}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          size_unit: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Dimension
                    </label>
                    <input
                      type="text"
                      id="dimension"
                      name="dimension"
                      className="form-control"
                      placeholder="Enter dimension"
                      required
                      value={EditBooking.dimension}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          dimension: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      id="price"
                      name="price"
                      className="form-control"
                      placeholder="Enter price"
                      required
                      value={EditBooking.price}
                      onChange={handleInputChange} // Attach handleInputChange here
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="total" className="form-label">
                      Total
                    </label>
                    <input
                      type="number"
                      id="total"
                      name="total"
                      className="form-control"
                      placeholder="0.00"
                      required
                      value={EditBooking.total}
                      onChange={handleInputChange} // Attach handleInputChange here
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="advance" className="form-label">
                      Advance
                    </label>
                    <input
                      type="number"
                      id="advance"
                      name="advance"
                      className="form-control"
                      placeholder="Enter no_0f_installment "
                      required
                      value={EditBooking.advance}
                      onChange={handleInputChange} // Attach handleInputChange here
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="balance" className="form-label">
                      Balance
                    </label>
                    <input
                      type="number"
                      id="balance"
                      name="balance"
                      className="form-control"
                      placeholder="0.00"
                      required
                      value={EditBooking.balance}
                      onChange={handleInputChangeInstallment}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Packages
                    </label>
                    <select

                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          customer: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Customer</option>
                      {Customer.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                    <select
                      className="form-control"
                      name="package_choice"
                      id="package_choice"
                      value={EditBooking.package_choice} // Use the region ID from editblock
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          package_choice: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Package</option>
                      {Packages.map((packages) => (
                        <option
                          key={packages.id}
                          value={packages.id}
                        >
                          {packages.package_choice}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      No Of Installments
                    </label>
                    <input
                      type="text"
                      id="no_0f_installment"
                      name="no_0f_installment"
                      className="form-control"
                      placeholder="Enter no_0f_installment "
                      required
                      value={EditBooking.no_0f_installment}
                      onChange={handleInputChangeInstallment}
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Amount Package
                    </label>
                    <input
                      type="number"
                      id="amount_package"
                      name="amount_package"
                      className="form-control"
                      placeholder="0.00"
                      required
                      value={EditBooking.amount_package}
                      onChange={handleInputChangeInstallmentAmount} // Attach handleInputChange here
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="agent-field" className="form-label">
                      Agent
                    </label>
                    <select
                      className="form-control"
                      name="agent"
                      id="agent-field"
                      value={EditBooking.agent} // Use the region ID from editblock
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          agent: e.target.value, // Update region ID
                        })
                      }
                    >
                      <option value="">Choose Agent</option>
                      {Agent.map((agent) => (
                        <option key={agent.id} value={agent.id}>
                          {agent.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="size" className="form-label">
                      Promise Date
                    </label>
                    <input
                      type="date"
                      id="promise_date"
                      name="promise_date"
                      className="form-control"
                      required
                      value={EditBooking.promise_date}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          promise_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="commission_percent" className="form-label">
                      Commission %
                    </label>
                    <input
                      type="number"
                      id="commission_percent"
                      name="commission_percent"
                      className="form-control"
                      required
                      value={EditBooking.commission_percent}
                      onChange={handleInputChange} // Attach handleInputChange here
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="commission_amount" className="form-label">
                      Commission Amount
                    </label>
                    <input
                      type="number"
                      id="commission_amount"
                      name="commission_amount"
                      className="form-control"
                      required
                      value={EditBooking.commission_amount}
                      onChange={handleInputChange} // Attach handleInputChange here
                    />
                  </div>
                  <div className="col-sm-8">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      id="address-field"
                      className="form-control"
                      placeholder="Enter Address"
                      required
                      value={EditBooking.address}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="whatsapp-field" className="form-label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      placeholder="Enter Remarks"
                      required
                      value={EditBooking.remarks}
                      onChange={(e) =>
                        setEditBooking({
                          ...EditBooking,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="document-field" className="form-label">
                      Edit Image Document
                    </label>
                    <input
                      type="file"
                      id="document"
                      name="document"
                      className="form-control"
                      onChange={handleImageChange}
                    />
                    {EditBooking.document && (
                      <div>
                        {EditBooking.document instanceof File ? (
                          <img
                            src={URL.createObjectURL(EditBooking.document)}
                            alt="Product Image"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          <img
                            src={EditBooking.document}
                            alt="Product Image"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditBooking(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                disabled={isAddButtonDisabled}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ no_0f_installment: "100px", dimension: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (bookingToDelete) {
                  handleDeletebooking(bookingToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Custom Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default ShowBooking;
