import React, { useEffect } from "react";
import logolight from "../../assets/images/saharalogo.png";
import logodark from "../../assets/images/saharalogo.png";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;



const Login = (props) => {
  document.title = "Login | E Socity";
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    username: Yup.string().required("Please Enter Your username"),
    password: Yup.string().required("Please Enter Your Password"),
  });

  const initialValues = {
    username: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(`${baseUrl}/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        // Store username, token, and login_id in sessionStorage
        sessionStorage.setItem('username', values.username);
        sessionStorage.setItem('login_id', data.login_id); // Update key if needed
        sessionStorage.setItem('token', data.access);
  
        // Handle successful login, e.g., store the token in your application
        console.log('Successfully logged in. Token:', data.access);
  
        // Redirect to the dashboard after successful login
        navigate('/dashboard');
      } else {
        const errorData = await response.json();
        console.error('Login failed:', errorData);
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };
  
  
  

// Function to check if the user is already logged in
const isLoggedIn = () => {
  const token = sessionStorage.getItem('token');
  return !!token; // Returns true if the token is present
};


  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    document.body.className = "bg-pattern";
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="24"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">
                      Welcome Back!
                    </h4>
                    <p className="mb-5 text-center">
                      Sign in Allied (ERP).
                    </p>
                    <Form
                      className="form-horizontal"
                      onSubmit={formik.handleSubmit}
                    >
                      {formik.status ? <Alert color="danger">{formik.status}</Alert> : null}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Username</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Enter Username"
                              type="username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.username}
                              invalid={
                                formik.touched.username && formik.errors.username
                              }
                            />
                            {formik.touched.username && formik.errors.username ? (
                              <FormFeedback type="invalid">
                                {formik.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              invalid={
                                formik.touched.password && formik.errors.password
                              }
                            />
                            {formik.touched.password && formik.errors.password ? (
                              <FormFeedback type="invalid">
                                {formik.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-label form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link to="/auth-recoverpw" className="text-muted">
                                  <i className="mdi mdi-lock"></i> Forgot your password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">
                  Don't have an account?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    Register
                  </Link>
                </p>
                <p className="text-white-50">
                  © {new Date().getFullYear()} Upzet. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesdesign
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
