import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";

import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const UserPanel = () => {

  const [totalPlots, setTotalPlots] = useState(null);
  const [soldPlots, setSoldPlots] = useState(0);
  const [UnsoldPlots, setUnSoldPlots] = useState(0);
  const [CancelPlots, setCancelPlots] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/plot/`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
  
        // Assuming the API returns an object with a 'plot_status' property as integers
        const soldPlotsCount = data.filter(plot => plot.plot_status === 1).length;
        const UnsoldPlotsCount = data.filter(plot => plot.plot_status === 2).length;
        const cancelPlotsCount = data.filter(plot => plot.plot_status === 3).length;
  
        // Set the total number of plots and the number of sold plots
        setSoldPlots(soldPlotsCount);
        setUnSoldPlots(UnsoldPlotsCount);
        setCancelPlots(cancelPlotsCount);
        setTotalPlots(data.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, []);
  

  return (
    <React.Fragment>
      <Row>
    

          <Col xl={3} sm={6}>
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    <RadialChart2
                      id="radialchart-2"
                      className="apex-charts"
                      dir="ltr"
                    />
                  </div>

                  <div className="flex-grow-1 overflow-hidden">
                    <p className="mb-1">Sold</p>
                    <h5 className="mb-3">{soldPlots}</h5>
                    <p className="text-truncate mb-0">
                      <span className="text-success me-2">
                        1.7% <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                      </span>{" "}
                      From previous
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col xl={3} sm={6}>
            <Card>
              <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    <RadialChart3
                      id="radialchart-3"
                      className="apex-charts"
                      dir="ltr"
                    />
                  </div>

                  <div className="flex-grow-1 overflow-hidden">
                    <p className="mb-1">UnSold</p>
                    <h5 className="mb-3">{UnsoldPlots}</h5>
                    <p className="text-truncate mb-0">
                      <span className="text-success me-2">
                        1.7% <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                      </span>{" "}
                      From previous
                    </p>
                  </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-group-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Cancel</p>
                  <h5 className="mb-3">{CancelPlots}</h5>
                  <p className="text-truncate mb-0">
                    <span className="text-success me-2">
                      1.7% <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                    </span>{" "}
                    From previous
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
                <div className="d-flex text-muted">
                  <div className="flex-shrink-0 me-3 align-self-center">
                    <div id="radialchart-1" className="apex-charts" dir="ltr">
                      <RadialChart1 totalPlots={totalPlots} />
                    </div>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="mb-1">Total Plots</p>
                    <h5 className="mb-3">{totalPlots}</h5>
                    <p className="text-truncate mb-0">
                      <span className="text-success me-2">
                        0.02% <i className="ri-arrow-right-up-line align-bottom ms-1"></i>
                      </span>{" "}
                      From previous
                    </p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          
      </Row>
    </React.Fragment>
  );
};

export default UserPanel;
