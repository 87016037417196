import React, { useState, useEffect } from "react";
import RadialChart from "./RadialChart";
import { Card, CardBody, Col, Row } from "reactstrap";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const SocialSource = () => {
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(0); // Default to no block selected
  const [totalPlots, setTotalPlots] = useState(0);
  const [soldPlots, setSoldPlots] = useState(0);
  const [unsoldPlots, setUnsoldPlots] = useState(0);
  const [cancelPlots, setCancelPlots] = useState(0);

  // Fetch block data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/block/`);
        if (response.ok) {
          const data = await response.json();
          setBlocks(data);
        } else {
          console.error("Error fetching blocks:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching blocks:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch plot data when the selected block changes
  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        if (selectedBlock !== 0) {
          const response = await fetch(
            `${baseUrl}/addplot/?block=${selectedBlock}`
          );
          if (response.ok) {
            const data = await response.json();
            // Calculate plot counts based on data
            const soldPlotsCount = data.filter(
              (plot) => plot.status === 1
            ).length;
            const unsoldPlotsCount = data.filter(
              (plot) => plot.status === 2
            ).length;
            const cancelPlotsCount = data.filter(
              (plot) => plot.status === 3
            ).length;

            setSoldPlots(soldPlotsCount);
            setUnsoldPlots(unsoldPlotsCount);
            setCancelPlots(cancelPlotsCount);
            setTotalPlots(data.length);
          } else {
            console.error("Error fetching plots:", response.statusText);
          }
        }
      } catch (error) {
        console.error("Error fetching plots:", error);
      }
    };

    fetchPlotData();
  }, [selectedBlock]);

  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Block Summary</h5>
              </div>
              <div className="flex-shrink-0">
                <select
                  className="form-select form-select-sm mb-0 my-n1"
                  name="block"
                  id="block-field"
                  value={selectedBlock}
                  onChange={(e) => setSelectedBlock(e.target.value)}
                >
                  <option value="0">Choose Block</option>
                  {blocks.map((block) => (
                    <option key={block.id} value={block.id}>
                      {block.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* RadialChart */}
            <RadialChart />
            <Row>
              {/* RadialChart for Total Plots */}
              {/* Display Total, Sold, and Unsold Plots */}
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <p>Sold Plots</p>
                  <strong style={{ color: "#099680" }}>{soldPlots}</strong>
                </div>
                <div className="flex-shrink-0 me-3 align-self-center">
                  <p>Unsold Plots</p>
                  <strong style={{ color: "#4aa3ff" }}>{unsoldPlots}</strong>
                </div>
                <div className="flex-shrink-0 me-3 align-self-center">
                  <p>Cancel Plot</p>
                  <strong style={{ color: "#FF0000" }}>{cancelPlots}</strong>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default SocialSource;
