import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Expense = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [expense, setexpense] = useState([]);
  const [AccountManagement, setAccountManagement] = useState([]);
  const [AccountName, setAccountName] = useState([]);
  const [AccountCashName, setAccountCashName] = useState([]);
  const [expenseToDelete, setexpenseToDelete] = useState(null);
  const [editexpense, setEditexpense] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  function tog_list() {
    setmodal_list(!modal_list);
  }

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    setCurrentDate(getCurrentDate());
  }, []);



    // Fetch accounts data when the component mounts
    const fetchData = async () => {
      try {
        const groupId = sessionStorage.getItem("groupId");

        if (!groupId) {
          console.error("Group ID not found in the session.");
          return;
        }

        const expense = await fetch(`${baseUrl}/expense-management/`);
        if (expense.ok) {
          const data = await expense.json();
          // Filter data based on the current groupId
          const filteredData = data.filter(
            (item) => item.group.toString() === groupId
          );
          setexpense(filteredData);
        } else {
          console.error("Error fetching data:", expense.statusText);
        }

        const response = await fetch(`${baseUrl}/accounts-management/`);
        if (response.ok) {
          const data = await response.json();
          // Filter data based on the current groupId
          const filteredData = data.filter(
            (item) => item.group.toString() === groupId
          );
          setAccountManagement(filteredData);
        } else {
          console.error("Error fetching data:", response.statusText);
        }

        const expneses = await fetch(`${baseUrl}/account-expnese-name/`);
        if (expneses.ok) {
          const data = await expneses.json();
          // Filter data based on the current groupId
          const filteredData = data.filter(
            (item) => item.group.toString() === groupId
          );
          setAccountName(filteredData);
        } else {
          console.error("Error fetching data:", expneses.statusText);
        }

        const accountname = await fetch(`${baseUrl}/account-baks&cash-name/`);
        if (accountname.ok) {
          const data = await accountname.json();
          // Filter data based on the current groupId
          const filteredData = data.filter(
            (item) => item.group.toString() === groupId
          );
          setAccountCashName(filteredData);
        } else {
          console.error("Error fetching data:", accountname.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    useEffect(() => {
    fetchData();
  }, []); 

  // Function to get the Account Name
  function getAccountName(AccountManagementID) {
    const acc_name = AccountManagement.find(
      (AccountManagement) => AccountManagement.id === AccountManagementID
    );
    return acc_name ? acc_name.name : "N/A";
  }

  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Function to fetch expense data after adding an expense
  const updateExpenseData = async () => {
    try {
      const response = await fetch(`${baseUrl}/expense-management/`);
      if (response.ok) {
        const data = await response.json();
        setexpense(data);
      } else {
        console.error("Error fetching expense data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching expense data:", error);
    }
  };

  // Save data in database
  const handleAddExpense = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);
    const formData = new FormData(e.target);

    const token = sessionStorage.getItem(`token`);
    const groupId = sessionStorage.getItem(`groupId`);
    const loginId = sessionStorage.getItem(`login`);

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }
    const formDataJSON = {
      user: loginId,
      group: groupId,
      // accounts: parseInt(formData.get('accounts')), // Convert to integer
      // paid_from: parseInt(formData.get('paid_from')), // Convert to integer
    };
    formData.forEach((value, key) => {
      formDataJSON[key] = value;
    });

    try {
      const response = await fetch(`${baseUrl}/expense-management/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formDataJSON),
      });

      if (response.status === 201) {
        updateExpenseData();

        setmodal_list(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // fetch data to edit expense

  const handleEditexpense = async (expense) => {
    try {
      const response = await fetch(
        `${baseUrl}/expense-management/${expense.id}`
      );
      if (response.ok) {
        const data = await response.json();

        // Map the fetched expense_status value to match form options
        const editedexpenseData = { ...data };

        setEditexpense(editedexpenseData);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the expense for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the expense:", error);
    }
  };

  // fecth data to Save edit or update

  const handleSaveEdit = async () => {
    if (!editexpense) return;

    // Map the expense_status value to match Django choices
    const updatedexpenseData = { ...editexpense };

    try {
      const response = await fetch(
        `${baseUrl}/expense-management/${editexpense.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedexpenseData),
        }
      );

      if (response.status === 200) {
        const UpdatedexpenseData = await response.json();
        console.log("expense updated:", UpdatedexpenseData);

        setexpense((prevexpense) =>
          prevexpense.map((expense) =>
            expense.id === UpdatedexpenseData.id ? UpdatedexpenseData : expense
          )
        );

        setmodal_edit(false);
        setEditexpense(null);
      } else {
        console.error("Failed to edit the expense:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the expense:", error);
    }
  };

  // Delete expense

  const handleDeleteexpense = async (expense) => {
    if (expense) {
      try {
        const response = await fetch(
          `${baseUrl}/expense-management/${expense.id}/`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 204) {
          console.log("expense deleted:", expense.id);

          // Remove the deleted expense from the expense state
          setexpense((prevexpense) =>
            prevexpense.filter((c) => c.id !== expense.id)
          );

          setmodal_delete(false); // Close the delete modal
        } else {
          console.error("Failed to delete the expense:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the expense:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Expense" breadcrumbItem="Expense Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">expense</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="expenseList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => setmodal_list(true)}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Expense
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ dimension: "50px" }}></th>
                            <th className="sort" data-sort="srNumber">
                              Sr #
                            </th>
                            <th className="sort" data-sort="name">
                              Date
                            </th>
                            <th className="sort" data-sort="size">
                              Account
                            </th>
                            <th className="sort" data-sort="business_type">
                              Remarks
                            </th>
                            <th className="sort" data-sort="title">
                              Amount
                            </th>
                            <th className="sort" data-sort="title">
                              Paid From
                            </th>
                            <th
                              className="sort"
                              data-sort="action"
                              style={{ dimension: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {expense.map((expense, index) => (
                            <tr key={expense.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>
                              <td className="name">{expense.date}</td>
                              <td className="block">
                                {getAccountName(expense.accounts)}
                              </td>
                              <td className="size">{expense.remarks}</td>
                              <td className="size">{expense.amount}</td>
                              <td className="paid_from">
                                {getAccountName(expense.paid_from)}
                              </td>
                              <td>
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() => {
                                        handleEditexpense(expense); // Open the delete modal
                                      }}
                                      color="soft-success"
                                    >
                                      <i className="ri-edit-2-line"></i>
                                    </button>
                                  </div>
                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn"
                                    onClick={() => {
                                      setexpenseToDelete(expense); // Set the expense to be deleted
                                      setmodal_delete(true); // Open the delete modal
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}

      <Modal
        isOpen={modal_list}
        toggle={tog_list}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          Add Expense
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddExpense}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="p_type" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  className="form-control"
                  placeholder="Date"
                  required
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                />
              </div>

              <div className="col-sm-4">
                <label htmlFor="account" className="form-label">
                  Accounts
                </label>
                <select
                  className="form-control"
                  name="accounts"
                  id="accounts"
                  required
                >
                  <option value="">Choose Account</option>
                  {AccountName.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-5">
                <label htmlFor="name-field" className="form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  id="remarks"
                  name="remarks"
                  className="form-control"
                  placeholder="Remarks"
                  required
                />
              </div>
              <div className="row  mt-2">
                <div className="col-sm-3">
                  <label htmlFor="size-field" className="form-label">
                    Amount
                  </label>
                  <input
                    type="text"
                    id="amount"
                    name="amount"
                    className="form-control"
                    placeholder="Amount"
                    required
                  />
                </div>
                <div className="col-sm-4">
                  <label htmlFor="paid_from" className="form-label">
                    Paid From
                  </label>
                  <select
                    className="form-control"
                    name="paid_from"
                    id="paid_from"
                    required
                  >
                    <option value="">Choose Account</option>
                    {AccountCashName.map((account) => (
                      <option key={account.id} value={account.id}>
                        {account.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Add modal end  */}

      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Expense
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editexpense && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="name-field" className="form-label">
                      Date
                    </label>
                    <input
                      type="date"
                      id="entry_date"
                      name="entry_date"
                      className="form-control"
                      required
                      value={editexpense.date}
                      onChange={(e) =>
                        setEditexpense({
                          ...editexpense,
                          date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="account" className="form-label">
                      Accounts
                    </label>
                    <select
                      className="form-control"
                      name="accounts"
                      id="accounts"
                      value={editexpense.accounts} // Use the region ID from editblock
                      onChange={(e) =>
                        setEditexpense({
                          ...editexpense,
                          accounts: e.target.value, // Update region ID
                        })
                      }
                    >
                      <option value="">Choose Account</option>
                      {AccountManagement.map((account) => (
                        <option key={account.id} value={account.id}>
                          {account.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-5">
                    <label htmlFor="remarks" className="form-label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      placeholder="Remarks"
                      required
                      value={editexpense.remarks}
                      onChange={(e) =>
                        setEditexpense({
                          ...editexpense,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="amount" className="form-label">
                      Amount
                    </label>
                    <input
                      type="number"
                      id="amount"
                      name="amount"
                      className="form-control"
                      placeholder="Amount"
                      required
                      value={editexpense.amount}
                      onChange={(e) =>
                        setEditexpense({
                          ...editexpense,
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="paid_from" className="form-label">
                      Paid From
                    </label>
                    <input
                      type="text"
                      id="paid_from"
                      name="paid_from"
                      className="form-control"
                      placeholder="Enter expense size"
                      required
                      value={editexpense.paid_from}
                      onChange={(e) =>
                        setEditexpense({
                          ...editexpense,
                          paid_from: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditexpense(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ dimension: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (expenseToDelete) {
                  handleDeleteexpense(expenseToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">expense Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Expense;
