module.exports = {
  env: {
    NEXT_PUBLIC_API_BASE_URL: "https://erp.adroitsoft.co/api", //,"http://127.0.0.1:8000/api"
  },
 
  google: {
    API_KEY: "",
    CLIENT_ID: "23144678283-oek7ncjmmrgkgmi2i56sc411gp71a8sp.apps.googleusercontent.com",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}
