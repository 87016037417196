import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {
  document.title = "Profile | Allied Tajar(ERP)";

  const dispatch = useDispatch();

  const [idx, setidx] = useState(1);
  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    username: "",
    email: "",
    phone: "",
  });

  const { error, success } = useSelector((state) => ({
    error: state.profile.error,
    success: state.profile.success,
  }));

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const userId = sessionStorage.getItem("login");

        if (!userId) {
          console.error("User Id not found in the session.");
          return;
        }

        const response = await fetch(
          `http://127.0.0.1:8000/api/user-profile/${userId}`
        );

        if (response.ok) {
          const data = await response.json();
          setProfileData(data);
        } else {
          console.error("Error fetching user data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: profileData.first_name || "",
      last_name: profileData.last_name || "",
      username: profileData.username || "",
      email: profileData.email || "",
      phone: profileData.phone || "",
      password: "", // Add password field if you want to allow changing the password
    },
    validationSchema: Yup.object({
      // Add validation schema as needed
    }),
    onSubmit: (values) => {
      // Dispatch the action to update the user profile
      dispatch(editProfile(values));
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Allied Tajar" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              {error && error ? (
                <Alert color="danger">
                  <div>{error}</div>
                </Alert>
              ) : null}
              {success ? (
                <Alert color="success">
                  <div>{success}</div>
                </Alert>
              ) : null}

              <Card>
                <CardBody>
                  <div className="flex-grow-1 align-self-center">
                    <div className="text-muted">
                      <div className="row">
                        <div className="col-md-6">
                          <p className="mb-1">
                            First Name: {profileData.first_name}
                          </p>
                          <p className="mb-1">
                            Last Name: {profileData.last_name}
                          </p>
                          <p className="mb-1">User Name: {profileData.username}</p>
                        </div>
                        <div className="col-md-6">
                          <p className="mb-1">Email: {profileData.email}</p>
                          <p className="mb-1">Phone: {profileData.phone}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Edit Profile</h4>

          <Card>
            <CardBody>
              <Form
                className="form-horizontal"
                onSubmit={validation.handleSubmit}
              >
                <div className="form-group">
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Label className="form-label">First Name</Label>
                      <Input
                        name="first_name"
                        className="form-control"
                        placeholder="Enter First Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ""}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label">Last Name</Label>
                      <Input
                        name="last_name"
                        className="form-control"
                        placeholder="Enter Last Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name || ""}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Label className="form-label">User Name</Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder="Enter User Name"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.username || ""}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label">Email </Label>
                      <Input
                        name="email"
                        className="form-control"
                        placeholder="Enter User Email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <Label className="form-label">Password</Label>
                      <Input
                        name="password"
                        className="form-control"
                        placeholder="Edit Password"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ""}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label className="form-label">Phone </Label>
                      <Input
                        name="phone"
                        className="form-control"
                        placeholder="Edit Phone"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.phone || ""}
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update Profile
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
