import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";
// import { useNavigate } from "react-router-dom";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Block = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [blockToDelete, SetBlockToDelete] = useState(null);
  const [editBlock, seteditBlock] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control success alert display
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [blocks, SetBlock] = useState([]);
  const [purchase, setPurchase] = useState([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [addNewBlock, setAddnewBlock] = useState();
  const [AddBlock, SetAddBlock] = useState({
    moza: "",
    khatooni_no: "",
    description: "",
    acer_no: "",
    total_marla: "",
    name: "",
    remarks: "",
    map_attachement: null,
  });

  // Function to toggle the 'modal_list' state
  function tog_list() {
    setmodal_list(!modal_list);
  }

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };
  const token = sessionStorage.getItem("token");
  const groupId = sessionStorage.getItem("groupId");
  const loginId = sessionStorage.getItem("login");

  const searchfunc = () => {
    const filter = document.getElementById("myInput").value.toUpperCase();
    const myTable = document.getElementById("myTable");
    const tr = myTable.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
      let showRow = true;

      if (filter !== "") {
        let found = false;
        const tds = tr[i].getElementsByTagName("td");
        for (let j = 0; j < tds.length; j++) {
          const td = tds[j];
          if (td) {
            const textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true;
              break;
            }
          }
        }

        if (!found) {
          showRow = false;
        }
      }

      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    SetAddBlock((prevAddBlock) => ({
      ...prevAddBlock,
      [name]: value,
    }));
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      SetAddBlock((prevBlock) => ({
        ...prevBlock,
        map_attachement: file,
      }));
    }
  };
  const handleImageChangeEdit = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage instanceof File) {
      console.log("Selected Image:", selectedImage);

      // Update the newImage property in the editBlock
      seteditBlock((prevEditBlock) => ({
        ...prevEditBlock,
        newImage: selectedImage,
      }));
    }
  };

  const handleMozachange = async (e) => {
    const { name, value } = e.target;

    const fetchMozaDetails = async (mozaId) => {
      try {
        const response = await fetch(
          `${baseUrl}/purchase-management/${mozaId}`
        );
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching description details:", error);
      }
    };

    switch (name) {
      case "description":
        const mozaDetails = await fetchMozaDetails(value);

        if (mozaDetails) {
          SetAddBlock((prevFormData) => ({
            ...prevFormData,
            description: value,
            moza: mozaDetails.moza,
            acer_no: mozaDetails.acer_no,
            khatooni_no: mozaDetails.khatooni_no,
            khata_no: mozaDetails.khata_no,
            total_marla: mozaDetails.total_marla,
          }));
        }
        break;
      default:
        break;
    }
  };

  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/block/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        SetBlock(filteredData);
      } else {
        console.error("Error fetching Blocks:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Blocks:", error);
    }
  };
  const fetchPurchaseData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/purchase-management/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPurchase(filteredData);
      } else {
        console.error("Error fetching Blocks:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Blocks:", error);
    }
  };

  const getDescription = (descriptionID) => {
    const desc = purchase.find((desc) => desc.id === descriptionID);
    return desc ? desc.description : "N/A";
  };

  const fetchUpdatedBlockData = async () => {
    try {
      await fetchData();
    } catch (error) {
      console.error("Error fetching updated blocks:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPurchaseData();
  }, []);

  const handleAddblock = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);

    if (!loginId) {
      console.error("User not logged in.");
      // Handle the case where the user is not logged in
      return;
    }

    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("description", AddBlock.description);
    formData.append("moza", AddBlock.moza);
    formData.append("acer_no", AddBlock.acer_no);
    formData.append("khatooni_no", AddBlock.khatooni_no);
    formData.append("total_marla", AddBlock.total_marla);
    formData.append("khata_no", AddBlock.khata_no);
    formData.append("name", AddBlock.name);
    formData.append("remarks", AddBlock.remarks);
    if (AddBlock.map_attachement !== null) {
      formData.append("map_attachement", AddBlock.map_attachement);
    }

    try {
      const response = await fetch(`${baseUrl}/block/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        SetBlock([...blocks, data]);
        SetAddBlock({
          name: "",
          remarks: "",
          moza: "",
          description: "",
          acer_no: "",
          khatooni_no: "",
          khata_no: "",
          total_marla: "",
          remarks: "",
          map_attachement: null,
        });
        fetchUpdatedBlockData();
        setmodal_list(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
        setmodal_list(false);
      } else {
        console.error("Failed to add the block:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the block:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // Function to handle editing a block
  const HandleEditBlock = async (block) => {
    try {
      const response = await fetch(`${baseUrl}/block/${block.id}`);

      if (response.ok) {
        const data = await response.json();
        seteditBlock(data); // Update the state with fetched data
        setmodal_edit(true); // Open the modal for editing
      } else {
        console.error(
          "Failed to fetch the block for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the block:", error);
    }
  };

  // Function to save edited block
  const handleSaveEdit = async () => {
    if (!editBlock || !hasPermission("change_block")) return;
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!groupId) {
      console.error("Group ID not found in the session.");
      return;
    }
    const formData = new FormData();
    // Include login_id in the form data
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("name", editBlock.name);
    formData.append("description", editBlock.description);
    formData.append("moza", editBlock.moza);
    formData.append("khatooni_no", editBlock.khatooni_no);
    formData.append("khata_no", editBlock.khata_no);
    formData.append("acer_no", editBlock.acer_no);
    formData.append("total_marla", editBlock.total_marla);
    formData.append("remarks", editBlock.remarks);
    if (editBlock.map_attachement instanceof File) {
      formData.append("map_attachement", editBlock.map_attachement);
    }

    try {
      const response = await fetch(`${baseUrl}/block/${editBlock.id}/`, {
        method: "PUT",
        body: formData,
      });

      if (response.ok) {
        const updatedBlock = await response.json();
        console.log("Block updated:", updatedBlock);

        SetBlock((prevCustomers) =>
          prevCustomers.map((block) =>
            block.id === updatedBlock.id ? updatedBlock : block
          )
        );

        setmodal_edit(false);
        seteditBlock(null);
      } else {
        console.error("Failed to edit the Customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the Customer:", error);
    }
  };

  // Function to handle deleting a block
  const handleDeleteblock = async (block) => {
    if (block) {
      try {
        const response = await fetch(`${baseUrl}/block/${block.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("block deleted:", block.id);

          SetBlock((prevblocks) => prevblocks.filter((r) => r.id !== block.id));
          setmodal_delete(false);
          setShowAlertDelete(true);

          // Show the alert after successful deletion
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000); // Hide the alert after 5 seconds (adjust the duration as needed)
        } else {
          console.error("Failed to delete the block:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the block:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs  breadcrumbItem="Block " />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {hasPermission("add_block") && (
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => setmodal_list(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Block
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="name">
                              Description
                            </th>
                            <th className="sort" data-sort="name">
                              Moza
                            </th>
                            <th className="sort" data-sort="name">
                              Acer#
                            </th>
                            <th className="sort" data-sort="name">
                              Total Marlas
                            </th>
                            <th className="sort" data-sort="name">
                              Block Name
                            </th>
                            <th className="sort" data-sort="remarks">
                              Remarks
                            </th>
                            <th className="sort" data-sort="remarks">
                              Attachement
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {blocks.map((block, index) => (
                            <tr key={block.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="name">
                                {getDescription(block.description)}
                              </td>
                              {/* Display status name */}
                              <td className="remarks">{block.moza}</td>
                              <td className="remarks">{block.acer_no}</td>
                              <td className="remarks">{block.total_marla}</td>
                              <td className="remarks">{block.name}</td>
                              <td className="remarks">{block.remarks}</td>
                              <td className="map_attachment">
                                {block.map_attachement ? (
                                  <img
                                    src={block.map_attachement}
                                    alt="map_attachement"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50%",
                                      marginTop: "20px",
                                    }}
                                  />
                                ) : (
                                  <span>No Image Available</span>
                                )}
                              </td>

                              <td>
                                <div className="d-flex gap-2">
                                  {hasPermission("change_block") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => HandleEditBlock(block)}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {hasPermission("delete_block") && (
                                    <Button
                                      className="btn btn-sm btn-danger edit-item-btn"
                                      onClick={() => {
                                        SetBlockToDelete(block);
                                        setmodal_delete(true);
                                      }}
                                      color="soft-danger"
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          Add Block
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddblock}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="plot-field" className="form-label">
                  Desc
                </label>
                <select
                  className="form-control"
                  name="description"
                  id="description"
                  value={AddBlock.description}
                  required
                  onChange={handleMozachange}
                >
                  <option value="">Choose Description</option>
                  {purchase.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.description}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor="khatooni_no" className="form-label">
                  Moza
                </label>
                <input
                  type="text"
                  id="moza"
                  name="moza"
                  placeholder="Enter the Moza"
                  className="form-control"
                  value={AddBlock.moza}
                  required
                  readOnly
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="khatooni_no" className="form-label">
                  Khatooni#
                </label>
                <input
                  type="text"
                  id="khatooni_no"
                  name="khatooni_no"
                  placeholder="Enter the Khatooni #"
                  className="form-control"
                  value={AddBlock.khatooni_no}
                  required
                  readOnly
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="khata_no" className="form-label">
                  Khata#
                </label>
                <input
                  type="text"
                  id="khata_no"
                  name="khata_no"
                  placeholder="Enter the Khata#"
                  className="form-control"
                  value={AddBlock.khata_no}
                  required
                  readOnly
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3">
                <label htmlFor="acer_no" className="form-label">
                  Acer#
                </label>
                <input
                  type="text"
                  id="acer_no"
                  name="acer_no"
                  className="form-control"
                  placeholder="Enter the Acer #"
                  required
                  readOnly
                  value={AddBlock.acer_no}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="total_marla" className="form-label">
                  Total Marlas
                </label>
                <input
                  type="text"
                  id="total_marla"
                  name="total_marla"
                  className="form-control"
                  placeholder="Enter the total Marlas"
                  required
                  readOnly
                  value={AddBlock.total_marla}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="name-field" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter Name"
                  value={blocks.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="remarks-field" className="form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  name="remarks"
                  className="form-control"
                  placeholder="Enter remarks"
                  value={blocks.remarks}
                  onChange={handleInputChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-4">
                <label htmlFor="map_attachement-field" className="form-label">
                  Attachement
                </label>
                <input
                  type="file"
                  id="map_attachement"
                  name="map_attachement"
                  className="form-control"
                  value={blocks.map_attachement}
                  onChange={handleImageChange}
                />

                {AddBlock.imagePreview && (
                  <img
                    src={AddBlock.imagePreview}
                    alt="Map Preview"
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      marginTop: "20px",
                    }}
                  />
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={!hasPermission("add_block") || isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit block
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editBlock && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="name-field" className="form-label">
                      Description
                    </label>
                    <select
                      className="form-control"
                      name="account"
                      id="account"
                      value={editBlock.description}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          description: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Description</option>
                      {purchase.map((purchase) => (
                        <option key={purchase.id} value={purchase.id}>
                          {purchase.description}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="name-field" className="form-label">
                      Moza
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Moza"
                      required
                      value={editBlock.moza}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          moza: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="name-field" className="form-label">
                      Acer#
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Acer#"
                      required
                      value={editBlock.acer_no}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          acer_no: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="remarks-field" className="form-label">
                      Khatooni#
                    </label>
                    <input
                      type="text"
                      id="customername-field"
                      className="form-control"
                      placeholder="Enter Khatooni#"
                      required
                      value={editBlock.khatooni_no}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          khatooni_no: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="remarks-field" className="form-label">
                      Khata#
                    </label>
                    <input
                      type="text"
                      id="customername-field"
                      className="form-control"
                      placeholder="Enter Khata#"
                      required
                      value={editBlock.khata_no}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          khata_no: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="remarks-field" className="form-label">
                      Acer#
                    </label>
                    <input
                      type="text"
                      id="customername-field"
                      className="form-control"
                      placeholder="Enter Marlas"
                      required
                      value={editBlock.acer_no}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          acer_no: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="remarks-field" className="form-label">
                      Total Marlas
                    </label>
                    <input
                      type="text"
                      id="customername-field"
                      className="form-control"
                      placeholder="Enter Marlas"
                      required
                      value={editBlock.total_marla}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          total_marla: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="remarks-field" className="form-label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="customername-field"
                      className="form-control"
                      placeholder="Enter remarks"
                      required
                      value={editBlock.remarks}
                      onChange={(e) =>
                        seteditBlock({
                          ...editBlock,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="image" className="form-label">
                      Attachement
                    </label>
                    <input
                      type="file"
                      id="map_attachement"
                      className="form-control"
                      onChange={handleImageChangeEdit}
                    />
                    {editBlock.map_attachement && (
                      <div>
                        {editBlock.map_attachement instanceof File ? (
                          <img
                            src={URL.createObjectURL(editBlock.map_attachement)}
                            alt=" Attachement"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        ) : (
                          <img
                            src={editBlock.map_attachement}
                            alt=" Attachement"
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              marginTop: "20px",
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  seteditBlock(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (blockToDelete) {
                  handleDeleteblock(blockToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Modal  */}

      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="map_attachement"
            >
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">block Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div
              className="modal-dialog modal-dialog-centered"
              role="map_attachement"
            >
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Block;
