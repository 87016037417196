import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import QRCode from "react-qr-code";
import { useLocation } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const ShowBookingPrint = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [installments, setInstallments] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [block, setBlock] = useState([]);
  const [plot, setPlot] = useState([]);

  const location = useLocation();
  const selectedBooking = location.state ? location.state.data : null;

  useEffect(() => {
    updateDateTime();
    fetchAllData();
    if (selectedBooking) {
      console.log("selectedbooking", selectedBooking);
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/installment-data/`);
          if (response.ok) {
            const data = await response.json();
            const filteredData = data.filter(
              (installment) =>
                String(installment.booking).trim() ===
                selectedBooking.booking_no.trim()
            );
            setInstallments(filteredData);
          } else {
            console.error(
              "Error fetching installment data:",
              response.statusText
            );
          }
        } catch (error) {
          console.error("Error fetching installment data:", error);
        }
      };

      fetchData();
    }
  }, [selectedBooking]);

  useEffect(() => {
    if (selectedBooking && installments.length > 0) {
      // Print the window when both booking and installment data are available
      window.print();
    }
  }, [selectedBooking, installments]);

  const fetchAllData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const account = await fetch(`${baseUrl}/accounts/`);
      if (account.ok) {
        const data = await account.json();
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomer(filteredData);
      } else {
        console.error("Error fetching data:", account.statusText);
      }

      const plotData = await fetch(`${baseUrl}/plot/`);
      if (plotData.ok) {
        const data = await plotData.json();
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlot(filteredData);
      } else {
        console.error("Error fetching data:", plotData.statusText);
      }

      const blockData = await fetch(`${baseUrl}/block/`);
      if (blockData.ok) {
        const data = await blockData.json();
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setBlock(filteredData);
      } else {
        console.error("Error fetching data:", blockData.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categories:", error);
    }
  };

  useEffect(() => {
    updateDateTime();
    fetchAllData();
    // fetch(selectedBooking?.id);
  }, [selectedBooking]);

  const updateDateTime = () => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString();
    const formattedTime = now.toLocaleTimeString();
    setCurrentDate(formattedDate);
    setCurrentTime(formattedTime);
  };

  const getCustomerName = (customerID) => {
    const customerData = customer.find(
      (customer) => customer.id === customerID
    );
    return customerData ? customerData.name : "N/A";
  };

  const getBlockName = (blockID) => {
    const blockData = block.find((block) => block.id === blockID);
    return blockData ? blockData.name : "N/A";
  };

  const getPlotName = (plotID) => {
    const plotData = plot.find((plot) => plot.id === plotID);
    return plotData ? plotData.name : "N/A";
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="border" />
              <CardBody className="print-visible">
                <div id="customerList">
                  <div className="row print-visible">
                    <div className="col-md-6">
                      <div>
                        <strong>
                          <h3>Installment List</h3>
                        </strong>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <QRCode
                        value={` ${selectedBooking?.id}`}
                        size={64}
                        style={{ marginLeft: "15em" }}
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    {selectedBooking && (
                      <>
                        <div className="col-md-4">
                          <p>
                            <strong>Booking #:</strong> {selectedBooking.id}
                          </p>
                          <p>
                            <strong>Plot:</strong>{" "}
                            {getPlotName(selectedBooking.plot)}
                          </p>
                          <p>
                            <strong>Block:</strong>{" "}
                            {getBlockName(selectedBooking.block)}
                          </p>
                        </div>

                        <div className="col-md-4">
                          <p>
                            <strong>Customer:</strong>{" "}
                            {getCustomerName(selectedBooking.customer)}
                          </p>
                          <p>
                            <strong>Size:</strong> {selectedBooking.size}{" "}
                            {selectedBooking.size_unit}
                          </p>
                          <p>
                            <strong>Phone:</strong> {selectedBooking.phone}
                          </p>
                        </div>

                        <div className="col-md-4">
                          <p>
                            <strong>Date:</strong>{" "}
                            {selectedBooking.booking_date}
                          </p>
                          <p>
                            <strong>Price:</strong> {selectedBooking.price}
                          </p>

                          <p>
                            <strong>Advance:</strong> {selectedBooking.advance}
                          </p>
                        </div>
                      </>
                    )}
                  </div>

                  <div className="table-responsive">
                    <table className="table table-bordered table-striped">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">Sr</th>
                          <th scope="col">Month</th>
                          <th scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {installments.map((installment, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{installment.promise_date}</td>
                            <td>{installment.amount}</td>
                          </tr>
                        ))}
                        <tr>
                          <td></td>
                          <td>
                            <strong>Total:</strong>
                          </td>
                          <td>
                            <strong>
                              {installments.reduce(
                                (total, installment) =>
                                  total + parseFloat(installment.amount),
                                0
                              )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Internal CSS for print styles */}
      <style>
        {`
          @media print {
            body {
              font-family: Arial, sans-serif;
              font-size: 14px;
              color: #000;
            }

            #customerList {
              margin: 20px;
            }

            h3 {
              text-align: center;
              color: #000;
            }

            .row.print-visible {
              display: flex;
              justify-content: space-between;
            }

            .col-md-6 {
              width: 50%;
            }

            .col-md-4 {
              width: 48%;
            }

            .text-center {
              text-align: center;
            }

            .table {
              width: 100%;
              margin-top: 20px;
              border-collapse: collapse;
            }

            .table th,
            .table td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center; /* Align both header and body text to the center */
            }

            .table th:nth-child(3),
            .table td:nth-child(3) {
              text-align: right; /* Align the "Amount" header and column to the right */
            }

            .thead-light th {
              background-color: #f2f2f2;
            }

            .table tbody tr:nth-child(even) {
              background-color: #f9f9f9;
            }

            .table tbody tr:hover {
              background-color: #e0e0e0;
            }

            .table tbody td {
              padding: 10px;
            }

            .table tbody td:first-child {
              text-align: center;
            }

            .table tbody td:last-child {
              text-align: right;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ShowBookingPrint;
