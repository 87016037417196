import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

// ... (previous code remains unchanged)

const PaymentVoucher = () => {
  const [AccountData, setAccountData] = useState([]);
  const [MaxTransaction, setMaxTransaction] = useState([]);
  const [BankAccount, setBankAccount] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [modal_list, setmodal_list] = useState(false);
  const [modal_confirm, setModalConfirm] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [isAddButtonDisable, setIsAddButtonDisable] = useState(false);
  const [currentDate, setCurrentDate] = useState("");

  const [AddTransaction, setAddNewTransaction] = useState({
    voucher_no: "",
    account: "",
    entry_date: "",
    remarks: "",
    amount: "",
    b_account: "",
  });
  const token = sessionStorage.getItem("token");
  const groupId = sessionStorage.getItem(`groupId`);
  const loginId = sessionStorage.getItem(`login`);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddNewTransaction((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const fetchMaxTrasactionId = async () => {
    try {

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(
        `${baseUrl}/transaction/max_transaction_id/`
      );
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        const maxId = data.max_id !== undefined ? parseInt(data.max_id) + 1 : 1;
        // console.log("Max Id", MaxTransaction);

        setMaxTransaction(maxId);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData = async () => {
    try {

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchAccounts = await fetch(`${baseUrl}/accounts/`);
      if (fetchAccounts.ok) {
        const data = await fetchAccounts.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAccountData(filteredData);
      } else {
        console.error("Error fetching data:", fetchAccounts.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetcBankAccountData = async () => {
    try {
      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchBankAccount = await fetch(
        `${baseUrl}/account-baks&cash-name/`
      );
      if (fetchBankAccount.ok) {
        const data = await fetchBankAccount.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setBankAccount(filteredData);
      } else {
        console.error("Error fetching data:", fetchBankAccount.statusText);
      }

      const fetchtempData = await fetch(`${baseUrl}/temp-receipt-voucher/`);
      if (fetchtempData.ok) {
        const data = await fetchtempData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) =>
            item.group.toString() === groupId &&
            loginId &&
            item.type === "Payment Voucher"
        );

        setTempData(filteredData);
      } else {
        console.error("Error fetching data:", fetchtempData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchMaxTrasactionId();
    fetcBankAccountData();
    fetchData();
    setCurrentDate(getCurrentDate());
  }, []);

  const AddPaymentVoucher = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);



    if (!loginId) {
      console.error("User not logged in.");
      return;
    }

    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("voucher_no", MaxTransaction);
    formData.append("account", AddTransaction.account);
    formData.append("type", "Payment Voucher");
    formData.append("entry_date", currentDate);
    formData.append("remarks", AddTransaction.remarks);
    formData.append("amount", AddTransaction.amount);
    formData.append("b_account", AddTransaction.b_account);
    console.log("Form data", formData);

    try {
      const response = await fetch(`${baseUrl}/temp-receipt-voucher/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        setIsAddButtonDisabled(false);
        fetcBankAccountData();
        console.log("Data added:", data);
        setAddNewTransaction({
          voucher_no: "",
          account: "",
          entry_date: "",
          remarks: "",
          amount: "",
          b_account: "",
        });
      } else {
        console.error("Failed to add the Booking:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the Booking:", error);
    }
  };

  const handleAddTransaction = async (e) => {
    e.preventDefault();
    setIsAddButtonDisable(true);

    try {
      const totalAmount = tempData.reduce(
        (total, data) => total + parseFloat(data.amount || 0),
        0
      );

      const tempTransactionData = tempData.map((tempItem) => ({
        group: tempItem.group,
        user: tempItem.user,
        voucher_no: tempItem.voucher_no,
        account: tempItem.account,
        type: "Payment Voucher",
        b_account: tempItem.b_account,
        remarks: tempItem.remarks,
        amount: tempItem.amount,
        entry_date: tempItem.entry_date,
      }));

      const response = await fetch(`${baseUrl}/add-receipt-voucher/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tempTransactionData, totalAmount }),
      });

      if (response.ok) {
        setIsAddButtonDisable(false);

        const data = await response.json();
        setTempData([]);
        setModalConfirm(false);

        // Display success message
        setSuccessMessage("Transaction Added", data);
        setTimeout(() => {
          setSuccessMessage(null);
        }, 1000);
      } else {
        console.error("Failed to add the transaction:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the transaction:", error);
    }
  };

  const handleConfirm = () => {
    setModalConfirm(true);
  };

  // Function to handle deleting a TempQuotation
  const handleDelete = async (id) => {
    // alert(id)
    try {
      const response = await fetch(
        `${baseUrl}/temp-transaction/delete/${id}/`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        console.log("Data deleted successfully");
        // Handle any additional logic after successful deletion

        setTempData((prevTempQuotation) =>
          prevTempQuotation.filter((item) => item.id !== id)
        );
      } else {
        console.error("Error deleting Data:", response.statusText);
        // Handle error cases
      }
    } catch (error) {
      console.error("Error:", error.message);
      // Handle other types of errors
    }
  };

  // Function to get the Product Name
  function getAccountName(AccountID) {
    const accountName = AccountData.find((account) => account.id === AccountID);
    return accountName ? accountName.name : "N/A";
  }

  // Function to get the Product Name
  function getBankAccount(BankAccountID) {
    const BankAccountName = BankAccount.find(
      (bankAccount) => bankAccount.id === BankAccountID
    );
    return BankAccountName ? BankAccountName.name : "N/A";
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Transaction" breadcrumbItem=" Payment Voucher" />
          <row>
            <Card>
              <CardBody>
                <div id="business_profileList">
                  <Row className="g-4 mb-3" style={{ padding: "20px" }}>
                    <Col className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <form
                          className="tablelist-form"
                          onSubmit={AddPaymentVoucher}
                        >
                          <div id="business_profileList">
                            <Row className="g-4 mb-3">
                              <div className="row mb-3">
                                <div className="col-md-2">
                                  <label
                                    htmlFor="voucher_no"
                                    className="form-label"
                                  >
                                    Voucher#
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="voucher_no"
                                    name="voucher_no"
                                    value={MaxTransaction}
                                    readOnly
                                  />
                                </div>

                                <div className="col-md-5">
                                  <label
                                    htmlFor="product"
                                    className="form-label"
                                  >
                                    To Account
                                  </label>
                                  <select
                                    className="form-control"
                                    name="account"
                                    id="account"
                                    value={AddTransaction.account}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Choose Account</option>
                                    {AccountData.map((account) => (
                                      <option
                                        key={account.id}
                                        value={account.id}
                                      >
                                        {account.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="col-md-5">
                                  <label htmlFor="unit" className="form-label">
                                    Payment Date
                                  </label>
                                  <input
                                    type="date"
                                    id="entry_date"
                                    name="entry_date"
                                    className="form-control"
                                    value={currentDate}
                                    onChange={(e) =>
                                      setCurrentDate(e.target.value)
                                    }
                                    // onChange={handleInputChange}
                                  />
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-5">
                                  <label
                                    htmlFor="remarks"
                                    className="form-label"
                                  >
                                    Narration/Remarks{" "}
                                  </label>
                                  <input
                                    type="text"
                                    id="remarks"
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"
                                    value={AddTransaction.remarks}
                                    onChange={handleInputChange}
                                  />
                                </div>

                                <div className="col-md-2">
                                  <label htmlFor="qty" className="form-label">
                                    Amount (PKR)
                                  </label>
                                  <input
                                    type="number"
                                    id="amount"
                                    name="amount"
                                    className="form-control"
                                    placeholder="0.00"
                                    onChange={handleInputChange}
                                    value={AddTransaction.amount}
                                  />
                                </div>
                                <div className="col-md-4">
                                  <label
                                    htmlFor="product"
                                    className="form-label"
                                  >
                                    Issue From
                                  </label>
                                  <select
                                    className="form-control"
                                    name="b_account"
                                    id="b_account"
                                    value={AddTransaction.b_account}
                                    onChange={handleInputChange}
                                  >
                                    <option value="">Issue From</option>
                                    {BankAccount.map((bankaccount) => (
                                      <option
                                        key={bankaccount.id}
                                        value={bankaccount.id}
                                      >
                                        {bankaccount.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>

                                <div className="col-md-1">
                                  <label>&nbsp;&nbsp;</label>
                                  <label>&nbsp;&nbsp;</label>
                                  <Button
                                    color="success"
                                    className="add-btn"
                                    id="create-btn"
                                    disabled={isAddButtonDisabled}
                                    type="submit"
                                  >
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </Row>
                          </div>
                        </form>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </row>

          <div className="table-responsive table-card mt-3 mb-1">
            <table className="table align-middle table-nowrap" id="table">
              <thead className="table-light">
                <tr>
                  <th>Sr #</th>
                  <th>Date</th>
                  <th>Account Title</th>
                  <th>Narration</th>
                  <th>Amount</th>
                  <th>Issue From</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tempData.map((data, index) => (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data.entry_date}</td>
                    <td className="id">{getAccountName(data.account)}</td>
                    <td>{data.remarks}</td>
                    <td>{data.amount}</td>
                    <td className="id">{getBankAccount(data.b_account)}</td>

                    <td>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(data.id)}
                      >
                        Remove
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {tempData.length > 0 && (
            <div className="row">
              <hr />
              <div className="col-md-5"></div>
              <div className="col-md-2 mb-3">
                <Button
                  color="success"
                  className="add-btn"
                  onClick={handleConfirm}
                  disabled={isAddButtonDisable}
                  id="confirm-btn"
                >
                  Confirm
                </Button>
              </div>
              <div className="col-md-5"></div>
            </div>
          )}
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_confirm}
        toggle={() => setModalConfirm(!modal_confirm)}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => setModalConfirm(!modal_confirm)}
        >
          Confirm Transaction
        </ModalHeader>
        <ModalBody>
          <p>Are you sure you want to confirm the transaction?</p>
        </ModalBody>
        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setModalConfirm(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleAddTransaction}
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default PaymentVoucher;
