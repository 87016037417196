import React, { useState, useEffect } from "react";
// import "./singlebooking.css";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import QRCode from "react-qr-code";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const SingleBookingPrint = () => {
    const [lastBooking, setLastBooking] = useState(null);
    const [currentDate, setCurrentDate] = useState("");
    const [currentTime, setCurrentTime] = useState("");
    const [bookingNumber, setBookingNumber] = useState(0);
    const [installments, setInstallments] = useState([]);
    const [filteredInstallments, setFilteredInstallments] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [block, setBlock] = useState([]);
    const [plot, setPlot] = useState([]);

    const fetchAllData = async () => {
        try {
            const groupId = sessionStorage.getItem("groupId");

            if (!groupId) {
                console.error("Group ID not found in the session.");
                return;
            }

            const account = await fetch(`${baseUrl}/accounts/`);
            if (account.ok) {
                const data = await account.json();
                const filteredData = data.filter(
                    (item) => item.group.toString() === groupId
                );
                setCustomer(filteredData);
            } else {
                console.error("Error fetching data:", account.statusText);
            }

            const plotData = await fetch(`${baseUrl}/plot/`);
            if (plotData.ok) {
                const data = await plotData.json();
                const filteredData = data.filter(
                    (item) => item.group.toString() === groupId
                );
                setPlot(filteredData);
            } else {
                console.error("Error fetching data:", plotData.statusText);
            }

            const blockData = await fetch(`${baseUrl}/block/`);
            if (blockData.ok) {
                const data = await blockData.json();
                const filteredData = data.filter(
                    (item) => item.group.toString() === groupId
                );
                setBlock(filteredData);
            } else {
                console.error("Error fetching data:", blockData.statusText);
            }
        } catch (error) {
            console.error("Error fetching Categories:", error);
        }
    };

    const fetchbookingData = async () => {
        try {
            const groupId = sessionStorage.getItem('groupId');
            console.log("group id:", groupId);
    
            // Fetch the max booking ID for the specific group ID
            const maxBookingResponse = await fetch(
                `${baseUrl}/booking/max_booking_id/?group=${groupId}`
            );
    
            if (maxBookingResponse.ok) {
                const maxBookingData = await maxBookingResponse.json();
                const maxId = maxBookingData.max_id !== undefined ? parseInt(maxBookingData.max_id) : 0;
    
                // Fetch the booking data for the specific booking number (maxId)
                const bookingResponse = await fetch(`${baseUrl}/booking/?booking_no=${maxId}`);
    
                if (bookingResponse.ok) {
                    const lastBookingData = await bookingResponse.json();
    
                    // Filter the booking data based on booking_no and groupId
                    const filteredBookingData = lastBookingData.filter(booking => booking.booking_no == maxId && booking.group == groupId);
    
                    // Check if filtered data is empty or null
                    if (filteredBookingData && filteredBookingData.length > 0) {
                        setLastBooking(filteredBookingData);
    
                        // Extract bookingId from the first booking record
                        const bookingId = filteredBookingData[0].id;
    
                        // Fetch installment data based on bookingId
                        const installmentResponse = await fetch(`${baseUrl}/installment-data/?booking=${bookingId}`);
                        if (installmentResponse.ok) {
                            const installmentData = await installmentResponse.json();
                            // Filter installment data based on bookingId
                            const filteredInstallmentData = installmentData.filter(installment => installment.booking == bookingId);
                            setFilteredInstallments(filteredInstallmentData);
                        } else {
                            throw new Error("Failed to fetch installment data: " + installmentResponse.statusText);
                        }
    
                        return [filteredBookingData];
                    } else {
                        throw new Error("No booking data found for the specified criteria.");
                    }
                } else {
                    throw new Error("Failed to fetch last booking data: " + bookingResponse.statusText);
                }
            } else {
                throw new Error("Failed to fetch max booking ID: " + maxBookingResponse.statusText);
            }
        } catch (error) {
            console.error("Error fetching booking data:", error);
            return [];
        }
    };
    
    
    


    useEffect(() => {
        fetchAllData();
        fetchData();
    }, []);


    const fetchData = async () => {
        try {
            const updatedbookings = await fetchbookingData();
            setBookingNumber(
                updatedbookings.length > 0 ? updatedbookings[0].id : 0
            );
            if (updatedbookings.length > 0) {
                // await fetchInstallmentsData(updatedbookings[0].id);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    useEffect(() => {
        const fetchBookingAndPrint = async () => {
            await fetchData();
            // window.print();
        };

        fetchBookingAndPrint();
    }, [bookingNumber]);

    useEffect(() => {
        updateDateTime();
    }, [lastBooking]);

    const updateDateTime = () => {
        const now = new Date();
        const formattedDate = now.toLocaleDateString();
        const formattedTime = now.toLocaleTimeString();
        setCurrentDate(formattedDate);
        setCurrentTime(formattedTime);
    };

    const getCustomerName = (customerID) => {
        const customerData = customer.find((customer) => customer.id === customerID);
        return customerData ? customerData.name : "N/A";
    };

    const getBlockName = (blockID) => {
        const blockData = block.find((block) => block.id === blockID);
        return blockData ? blockData.name : "N/A";
    };

    const getPlotName = (plotID) => {
        const plotData = plot.find((plot) => plot.id === plotID);
        return plotData ? plotData.name : "N/A";
    };

    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="border" />
                            <CardBody className="print-visible">
                                <div id="customerList">
                                    <div className="row print-visible">
                                        <div className="col-md-6">
                                            <div>
                                                <strong>
                                                    <h3>Installment List</h3>
                                                </strong>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <QRCode
                                                value={` ${bookingNumber}`}
                                                size={64}
                                                style={{ marginLeft: "15em" }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-3">
                                        {lastBooking && lastBooking.map((booking, index) => (
                                            <div key={index} className="row mb-4">
                                                <div className="col-md-4">
                                                    <p>
                                                        <strong>Booking #:</strong> {booking.booking_no}
                                                    </p>
                                                    <p>
                                                        <strong>Plot:</strong>{" "}
                                                        {getPlotName(booking.plot)}
                                                    </p>
                                                    <p>
                                                        <strong>Block:</strong>{" "}
                                                        {getBlockName(booking.block)}
                                                    </p>
                                                </div>

                                                <div className="col-md-4">
                                                    <p>
                                                        <strong>Customer:</strong>{" "}
                                                        {getCustomerName(booking.customer)}
                                                    </p>
                                                    <p>
                                                        <strong>Size:</strong>{" "}
                                                        {booking.size} {booking.size_unit}
                                                    </p>
                                                    <p>
                                                        <strong>Phone:</strong> {booking.phone}
                                                    </p>
                                                </div>

                                                <div className="col-md-4">
                                                    <p>
                                                        <strong>Date:</strong> {booking.booking_date}
                                                    </p>
                                                    <p>
                                                        <strong>Price:</strong> {booking.price}
                                                    </p>

                                                    <p>
                                                        <strong>Advance:</strong> {booking.advance}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}



                                    </div>

                                    <div className="table-responsive">
                                        <table className="table table-bordered table-striped">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Sr</th>
                                                    <th scope="col">Month</th>
                                                    <th scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredInstallments.map((installment, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{installment.promise_date}</td>
                                                        <td>{installment.amount}</td>
                                                    </tr>
                                                ))}
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <strong>Total:</strong>
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            {filteredInstallments.reduce(
                                                                (total, installment) =>
                                                                    total + parseFloat(installment.amount),
                                                                0
                                                            )}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* Internal CSS for print styles */}
            <style>
                {`
          @media print {
            body {
              font-family: Arial, sans-serif;
              font-size: 14px;
              color: #000;
            }

            #customerList {
              margin: 20px;
            }

            h3 {
              text-align: center;
              color: #000;
            }

            .row.print-visible {
              display: flex;
              justify-content: space-between;
            }

            .col-md-6 {
              width: 50%;
            }

            .col-md-4 {
              width: 48%;
            }

            .text-center {
              text-align: center;
            }

            .table {
              width: 100%;
              margin-top: 20px;
              border-collapse: collapse;
            }

            .table th,
            .table td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: center; /* Align both header and body text to the center */
            }

            .table th:nth-child(3),
            .table td:nth-child(3) {
              text-align: right; /* Align the "Amount" header and column to the right */
            }

            .thead-light th {
              background-color: #f2f2f2;
            }

            .table tbody tr:nth-child(even) {
              background-color: #f9f9f9;
            }

            .table tbody tr:hover {
              background-color: #e0e0e0;
            }

            .table tbody td {
              padding: 10px;
            }

            .table tbody td:first-child {
              text-align: center;
            }

            .table tbody td:last-child {
              text-align: right;
            }
          }
        `}
            </style>
        </div>
    );
};

export default SingleBookingPrint;