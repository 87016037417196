import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import './PrintPlotReport.css'
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

export default function PrintPlotReport() {
  const [addPlotData, setPlotData] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const location = useLocation();
  const [username, setUsername] = useState("");
  const groupId = sessionStorage.getItem("groupId");
  const userApiKey = `${baseUrl}/users`;
  const {
    BookingData,
    TransactionsData,
    ShowData,
    TotalBalance,
    InstallmentData,
    SelectedCustomer,
    SelectedPlot,
  } = location.state || {};
  const [installmentData, setInstallmentData] = useState([]);
  console.log("SelectedCustomer:", SelectedCustomer);
  console.log("SelectedPlot:", SelectedPlot);
  console.log("InstallmentData:", InstallmentData);
  const filteredInstallmentData = InstallmentData.filter(
    (data) =>
      data.customer.toString() === SelectedCustomer &&
      data.plot.toString() === SelectedPlot
  );
  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetch(`${baseUrl}/plot/`);
        const data = await response.json();
        setPlotData(data);
      } catch (error) {
        console.error("Error fetching plot data:", error);
      }
    };

    fetchPlotData();
  }, []);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await fetch(`${baseUrl}/accounts/`);
        const data = await response.json();
        setCustomer(data);

        const customerId = data[0]?.id;
        const plotId = BookingData[0]?.plot; // Assuming plot ID is available in BookingData
        fetchInstallmentData(customerId, plotId);
      } catch (error) {
        console.error("Error fetching Customer:", error);
      }
    };

    fetchCustomerData();

    
    // Fetch data of Installments based on customer_id and plot_id
    const fetchInstallmentData = async (customerId, plotId) => {
      try {
        const response = await fetch(
          `${baseUrl}/installment-data/?customer=${customerId}&plot=${plotId}`
        );
        const data = await response.json();
        setInstallmentData(data);
      } catch (error) {
        console.error("Error fetching installment data:", error);
      }
    };
  }, [BookingData]);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await fetch(`${baseUrl}/users/${groupId}/`, {
          method: "GET",
          headers: {
            Authorization: `Api-Key ${userApiKey}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUsername(userData.username);
        } else {
          console.error("Failed to fetch username:", response.status);
          // Handle error when fetching username
        }
      } catch (error) {
        console.error("Fetch username error:", error);
        // Handle fetch username error
      }
    };

    if (groupId) {
      fetchUsername();
    }
  }, [groupId, userApiKey]);

  
  useEffect(() => {
    // Check if plot and customer data is available before setting ready to print
    if (addPlotData.length > 0 && Customer.length > 0) {
      window.print();
    }
  }, [addPlotData, Customer]);

  // get Plot Name
  const getPlotName = (plotID) => {
    const plot = addPlotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = Customer.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };

  return (
    <div className="card page-content">
      <div className=" print-visible">
        <div style={{ textAlign: "center" }}>
        <h3>
                          <strong> {username && <p>{username}</p>}</strong>
                        </h3>
        </div>
        <p
          style={{ textAlign: "center", marginTop: "none" }}
          className="print-visible"
        >
          <strong></strong>
        </p>
      </div>
      {/* Customer Information Table */}
      <h5 className="p-2 mt-4" style={{ color: "black", width: "30rem" }}>
        Customer Information
      </h5>
      <table border="1">
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Phone</th>
            <th>CNIC</th>
            <th>City</th>
          </tr>
        </thead>
        <tbody>
          {BookingData.map((data) => (
            <tr key={data.id}>
              <td className="customer_id">{getCustomerName(data.customer)}</td>
              <td>{data.phone}</td>
              <td>{data.cnic}</td>
              <td>{data.city}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Show Print Table  */}

      <h5 className="p-2 mt-4" style={{ color: "black", width: "30rem" }}>
        Plot Data
      </h5>
      {ShowData && ( // Only render the table if ShowData is true
        <table border="1">
          <thead>
            <tr>
              <th>Plot</th>
              <th>Dimension</th>
              <th>Size</th>
              <th>Size Unit</th>
              <th>Packages</th>
              <th>Price</th>
              <th>Total</th>
              <th>Advance</th>
              <th>Balance</th>
              <th>Promise Date</th>
              <th>Commission Amount</th>
            </tr>
          </thead>
          <tbody>
            {/* Render BookingData */}
            {BookingData.map((data) => (
              <tr key={data.id}>
                <td className="plot_id">{getPlotName(data.plot)}</td>
                <td>{data.dimension}</td>
                <td>{data.size}</td>
                <td>{data.size_unit}</td>
                <td>{data.package_choice}</td>
                <td>{data.price}</td>
                <td>{data.total}</td>
                <td>{data.advance}</td>
                <td>{data.balance}</td>
                <td>{data.promise_date}</td>
                <td>{data.commission_amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

   
      {/* Package Plan Table */}
      <h5 className="p-2 mt-4" style={{ color: "black", width: "30rem" }}>
        Installment Plan
      </h5>
      <table border="1">
        <thead>
          <tr>
            <th>Total </th>
            <th>Advance</th>
            <th>Balacnce</th>
            <th>Package</th>
            <th>Installments</th>
            <th>Installment/Amount</th>
          </tr>
        </thead>
        <tbody>
          {BookingData.map((data) => (
            <tr key={data.id}>
              <td>{data.total}</td>
              <td>{data.advance}</td>
              <td>{data.balance}</td>
              <td>{data.package_choice}</td>
              <td>{data.no_Of_installment}</td>
              <td>{data.amount_package}</td>
            </tr>
          ))}
        </tbody>
      </table>

       
        <table border="1">
          <thead>
          <tr>
                <th>Sr#</th>
                <th>Months</th>
                <th>Total</th>
                <th>Received</th>
                <th>Balance</th>
              </tr>
          </thead>
          <tbody>
          {ShowData &&
          filteredInstallmentData.map((data, index) => (
            <tr
              key={data.id}
              style={{
                color: data.paid === "0.00" ? "red" : "inherit",
              }}
            >
                 <td>{index + 1}</td>
                      <td>{data.promise_date}</td>
                      <td>{data.amount}</td>
                      <td>{data.paid ? data.paid : "0.00"}</td>
                      <td>
                        {data.paid && data.paid !== "0"
                          ? parseFloat(data.amount) - parseFloat(data.paid)
                          : data.amount}
                      </td>
              </tr>
            ))}
          </tbody>
        </table>
   

      {/* Transaction Print  Table  */}
      <h5 className="p-2 mt-4" style={{ color: "black", width: "30rem" }}>
        Transaction History
      </h5>
      <table border="1">
        <thead>
          <tr>
            <th>Receipt NO</th>
            <th>Amount</th>
            <th>Remarks</th>
          </tr>
        </thead>
        <tbody>
          {TransactionsData.map((transaction) => (
            <tr key={transaction.id}>
              <td>{transaction.receipt}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.remarks}</td>
            </tr>
          ))}
          <tr>
            <td>
              <strong>Closing Balance:</strong>
            </td>
            <td colSpan="3">{TotalBalance}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
