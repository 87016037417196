import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../../config";
import { Navigate } from "react-router-dom";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const ReceiptVoucherList = () => {
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [bookings, setTransactions] = useState([]);
  const [TransactionToDelete, setTransactiontoDelete] = useState(null);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [EditTransaction, setEditTransaction] = useState(null);
  const [AccountData, setAccountData] = useState([]);
  const [TransactionData, setTransactionsData] = useState([]);
  const [transactionData, setTransactionData] = useState([]);
  const [modal_list, setmodal_list] = useState(false);
  const [BankAccount, setBankAccount] = useState([]);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [ViewTransaction, setTransaction] = useState(null);
  const [VoucherId, setvoucherId] = useState(null);
  const [account, setAccount] = useState(null);
  const [b_account, setBaccount] = useState(null);
  const [Remarks, setRemarks] = useState(null);
  const [amount, setAmount] = useState(null);
  const [entry_date, setEntrydate] = useState(null);
  const [transactionId, setTransactionId] = useState("");
  const [voucherToDelete, setVoucherToDelete] = useState(null);
  const groupId = sessionStorage.getItem("groupId");
  const loginId = sessionStorage.getItem("login");
  const navigate = useNavigate();
  // Function to get the Product Name
  function getAccountName(AccountID) {
    const accountName = AccountData.find((account) => account.id === AccountID);
    return accountName ? accountName.name : "N/A";
  }

  // Function to get the Product Name
  function getBankAccount(BankAccountID) {
    const BankAccountName = AccountData.find(
      (bankAccount) => bankAccount.id === BankAccountID
    );
    return BankAccountName ? BankAccountName.name : "N/A";
  }

  const fetcBankAccountData = async () => {
    try {
      const fetchBankAccount = await fetch(
        `${baseUrl}/account-baks&cash-name/`
      );
      if (fetchBankAccount.ok) {
        const data = await fetchBankAccount.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setBankAccount(filteredData);
      } else {
        console.error("Error fetching data:", fetchBankAccount.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchTransactionData = async () => {
    try {
      const fetchAccounts = await fetch(`${baseUrl}/receipt-voucher-list/`);
      if (fetchAccounts.ok) {
        const data = await fetchAccounts.json();
        console.log("Filtered Account Data", data);
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) =>
            item.group.toString() === groupId && item.type === "Payment Voucher"
        );
        setTransactionData(filteredData);
      } else {
        console.error("Error fetching data:", fetchAccounts.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch Categorys data when the component mounts
  const fetchData = async () => {
    try {
      const fetchAccounts = await fetch(`${baseUrl}/accounts/`);
      if (fetchAccounts.ok) {
        const data = await fetchAccounts.json();
        console.log("Filtered Account Data", data);
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAccountData(filteredData);
      } else {
        console.error("Error fetching data:", fetchAccounts.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // Fetch  data when the component mounts
  const fetchTransaction = async (voucherId) => {
    try {
      const fetchTransaction = await fetch(
        `${baseUrl}/transaction-items/${voucherId}/`
      );
      if (fetchTransaction.ok) {
        const data = await fetchTransaction.json();
        console.log("Filtered Transaction Data", data);
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setTransactionsData(filteredData);
      } else {
        console.error("Error fetching data:", fetchTransaction.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetcBankAccountData();
    fetchTransactionData();
    fetchTransaction();
  }, []);

  // search Functionality
  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break;
            }
          }
        }

        if (!found) {
          showRow = false;
        }
      }

      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const toggleViewModal = () => {
    setmodal_list(!modal_list);
  };

  
  const handleViewVoucher = async (transactionData) => {
    if (!transactionData || !transactionData.voucher_no) {
      console.error("Invalid transaction data:", transactionData);
      return;
    }

    try {
      const response = await fetch(
        `${baseUrl}/transaction-items/${transactionData.voucher_no}/`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Data::", data);
      // Update state with fetched QuotationItem details
      setTransaction(data);

      // Update selectedQuotation state with the clicked quotation
      setSelectedVoucher(transactionData);

      // Open the View modal
      toggleViewModal();
    } catch (error) {
      console.error("Error fetching Transactions details:", error);
      // Handle error as needed
    }
  };

  // In the handleEditQuotation function
  const handleEditTransaction = async (data) => {
    console.log("Data for editing transaction:", data);
    setvoucherId(data.VoucherId);
    setTransactionId(data.id);
    setAmount(data.amount);
    setAccount(data.account);
    setRemarks(data.remarks);
    setBaccount(data.b_account);
    setEntrydate(data.entry_date);

    // Fetch the updated transactions for the corresponding voucher
    try {
      const response = await fetch(
        `${baseUrl}/transaction-items/${selectedVoucher.voucher_no}/`
      );

      if (response.ok) {
        const updatedData = await response.json();
        setTransaction(updatedData);

        // Close the edit modal
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch updated transactions:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching updated transactions:", error);
    }
  };

  const handleSaveEdit = async () => {
    const updatedTransaction = {
      group: groupId,
      user: loginId,
      entry_date: entry_date,
      account: account,
      remarks: Remarks,
      amount: amount,
      b_account: b_account,
    };
  
    try {
      const response = await fetch(
        `${baseUrl}/transaction/edit/${transactionId}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedTransaction),
        }
      );
  
      if (response.ok) {
        // Close the edit modal
        setmodal_edit(false);
  
        // Fetch the updated transactions for the corresponding voucher
        const updatedResponse = await fetch(
          `${baseUrl}/transaction-items/${selectedVoucher.voucher_no}/`
        );
  
        if (updatedResponse.ok) {
          const updatedData = await updatedResponse.json();
  
          // Update state with fetched updated transactions
          setTransaction(updatedData);
  
          // Fetch and update the total amount in the corresponding voucher
          const voucherResponse = await fetch(
            `${baseUrl}/transaction-items/${selectedVoucher.voucher_no}/`
          );
  
          if (voucherResponse.ok) {
            const voucherData = await voucherResponse.json();
  
            // Update the Voucher amount with the sum of positive transactions
            const totalPositiveAmount = updatedData
              .filter((transaction) => transaction.amount > 0)
              .reduce(
                (total, transaction) =>
                  total + parseFloat(transaction.amount),
                0
              );
  
            // Update the voucherData.amount directly
            voucherData.amount = totalPositiveAmount;
  
            // Update the state with the modified voucher data
            setSelectedVoucher({
              ...selectedVoucher,
              amount: totalPositiveAmount,
            });
  
            // Save the updated Voucher data
            await fetch(
              `${baseUrl}/transaction-items/${selectedVoucher.voucher_no}/`,
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(voucherData),
              }
            );
          } else {
            console.error(
              "Failed to fetch updated Voucher:",
              voucherResponse.statusText
            );
          }
        } else {
          console.error(
            "Failed to fetch updated transactions:",
            updatedResponse.statusText
          );
        }
      } else {
        console.error("Failed to edit the transaction:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the transaction:", error);
    }
  };
  

  // Delete
  const handleDeleteTransaction = async (data) => {
    if (data) {
      try {
        const response = await fetch(
          `${baseUrl}/receipt-voucher-list/${data.id}/`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 204) {
          console.log("booking Deleted:", data.id);

          // Remove the deleted booking from the bookings state
          setTransactions((prevtransaction) =>
            prevtransaction.filter((c) => c.id !== data.id)
          );
          fetchTransactionData();
          setmodal_delete(false);
          setShowAlertDelete(true);

          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the packages:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the booking:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Voucher List"
            breadcrumbItem="Payment Voucher List"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">booking</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="bookingList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th></th>
                            <th>Sr#</th>
                            <th>Voucher#</th>
                            <th>Date</th>

                            <th>Amount</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {transactionData.map((data, index) => (
                            <tr key={data.id}>
                              <td></td>
                              <td>{index + 1}</td>
                              <td>{data.voucher_no}</td>
                              <td>{data.entry_date}</td>
                              {/* <td className="id">
                                {getAccountName(data.account)}
                              </td> */}
                              <td>{data.amount}</td>
                              {/* <td className="id">
                                {getBankAccount(data.b_account)}
                              </td> */}

                              <td>
                                <div className="d-flex gap-2">
                                  <button
                                    className="btn btn-sm btn-info view-item-btn"
                                    onClick={() => handleViewVoucher(data)}
                                  >
                                    <i className="ri-eye-line"></i> View
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ dimension: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* view data  */}
      <Modal
        isOpen={modal_list}
        toggle={toggleViewModal}
        centered
        className="modal-lg"
      >
        <ModalHeader className="bg-light p-3" toggle={toggleViewModal}>
          View Transaction
        </ModalHeader>

        {/* Quotation item Input  */}
        <div className="container">
          <div className="row mb-2">
            <div className="col-md-4">
              {selectedVoucher && (
                <React.Fragment>
                  <label
                    htmlFor={`quotation_id_${selectedVoucher.id}`}
                    className="form-label"
                  >
                    Voucher#
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id={`voucher_no${selectedVoucher.voucher_no}`}
                    name={`voucher_no${selectedVoucher.voucher_no}`}
                    placeholder="0.00"
                    value={selectedVoucher.voucher_no}
                    readOnly
                  />
                </React.Fragment>
              )}
            </div>

            <div className="col-md-4">
              {selectedVoucher && (
                <React.Fragment>
                  <label
                    htmlFor={`quotation_id_${selectedVoucher.id}`}
                    className="form-label"
                  >
                    Date
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id={`entry_date${selectedVoucher?.entry_date || ""}`}
                    name={`entry_date${selectedVoucher?.entry_date || ""}`}
                    required
                    value={selectedVoucher.entry_date}
                    readOnly
                  />
                </React.Fragment>
              )}
            </div>

            <div className="col-md-4">
              {selectedVoucher && (
                <React.Fragment>
                  <label
                    htmlFor={`total_amount${selectedVoucher.amount}`}
                    className="form-label"
                  >
                    Amount
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id={`total_amount${selectedVoucher.amount}`}
                    name={`total_amount${selectedVoucher.amount}`}
                    placeholder="0.00"
                    value={selectedVoucher.amount}
                    readOnly
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <hr />

        <ModalBody>
          {ViewTransaction && (
            <table className="table">
              <thead>
                <tr>
                  <th>Sr#</th>
                  <th>T ID</th>
                  <th>Date</th>
                  <th>Received From</th>
                  <th>Narration</th>
                  <th>Amount</th>
                  <th>Deposit To</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="list form-check-all" id="myTable">
                {ViewTransaction.map((data, index) => (
                  <tr key={data.id}>
                    <td>{index + 1}</td>
                    <td>{data.id}</td>
                    <td>{data.entry_date}</td>
                    <td className="id">{getAccountName(data.account)}</td>
                    <td>{data.remarks}</td>
                    <td>{data.amount}</td>
                    <td className="id">{getBankAccount(data.b_account)}</td>
                    <td>
                      <div className="d-flex gap-2">
                        <div className="edit">
                          <button
                            className="btn btn-sm btn-success edit-item-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#showModal"
                            onClick={() => {
                              handleEditTransaction(data);
                            }}
                            color="soft-success"
                          >
                            <i className="ri-edit-2-line"></i>
                          </button>
                        </div>
                        <Button
                          className="btn btn-sm btn-danger edit-item-btn"
                          onClick={() => {
                            setVoucherToDelete(transactionData);
                            setmodal_delete(true);
                          }}
                          color="soft-danger"
                        >
                          <i className="ri-delete-bin-2-line"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </ModalBody>

        <ModalFooter>
          <div className="hstack gap-2 justify-content-end">
            <div className="d-flex gap-1"></div>
            <button
              type="button"
              className="btn btn-light"
              onClick={toggleViewModal}
            >
              Close
            </button>
          </div>
        </ModalFooter>
      </Modal>

      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Transaction
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label htmlFor="f_name-field" className="form-label">
                  ID
                </label>

                <input
                  type="text"
                  id="transactionId"
                  name="transactionId"
                  className="form-control"
                  required
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="f_name-field" className="form-label">
                  Date
                </label>

                <input
                  type="date"
                  id="entry_date"
                  name="entry_date"
                  className="form-control"
                  required
                  value={entry_date}
                  onChange={(e) => setEntrydate(e.target.value)}
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="whatsapp-field" className="form-label">
                  Received From
                </label>
                <select
                  className="form-control"
                  name="account"
                  id="account"
                  value={account}
                  onChange={(e) => setAccount(e.target.value)}
                >
                  <option value="">Choose Account</option>
                  {AccountData.map((account) => (
                    <option key={account.id} value={account.id}>
                      {account.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="whatsapp-field" className="form-label">
                  Amount
                </label>
                <input
                  type="text"
                  id="address-field"
                  className="form-control"
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>

              <div className="col-sm-3">
                <label htmlFor="whatsapp-field" className="form-label">
                  Deposit To
                </label>
                <select
                  className="form-control"
                  name="b_account"
                  id="b_account"
                  value={b_account}
                  onChange={(e) => setBaccount(e.target.value)}
                >
                  <option value="">Choose Account</option>
                  {BankAccount.map((b_account) => (
                    <option key={b_account.id} value={b_account.id}>
                      {b_account.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                <label htmlFor="whatsapp-field" className="form-label">
                  Narration
                </label>
                <input
                  type="text"
                  id="address-field"
                  className="form-control"
                  required
                  value={Remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-3"></div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditTransaction(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ no_0f_installment: "100px", dimension: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (TransactionToDelete) {
                  handleDeleteTransaction(TransactionToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default ReceiptVoucherList;
