import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";
// import { useNavigate } from "react-router-dom";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Agent = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [Agent, setAgent] = useState([]);
  const [blockToDelete, setAgentkToDelete] = useState(null);
  const [editAgent, setEditblock] = useState(null);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  // Function to toggle the 'modal_list' state
  function tog_list() {
    setmodal_list(!modal_list);
  }

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };

  // Function to fetch status data
  const fetchAgentData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/agent/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAgent(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };
  useEffect(() => {
    fetchAgentData();
  }, []);
  // Function for search functionality
  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false;
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true;

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Function to handle adding a new agent
  const handleAddAgent = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);
    const formData = new FormData(e.target);
    const token = sessionStorage.getItem("token");
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!loginId) {
      console.error("User not logged in.");
      // Handle the case where the user is not logged in
      return;
    }

    const formDataJSON = {
      user: loginId,
      group: groupId,
    }; // Initialize with user and group

    formData.forEach((value, key) => {
      formDataJSON[key] = value;
    });

    try {
      const response = await fetch(`${baseUrl}/agent/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formDataJSON),
      });

      if (response.status === 201) {
        setmodal_list(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
        fetchAgentData();
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // Function to handle editing a agent
  const handleEditAgent = async (agent) => {
    try {
      const response = await fetch(`${baseUrl}/agent/${agent.id}`);
      if (response.ok) {
        const data = await response.json();
        setEditblock(data);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the agent for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the agent:", error);
    }
  };

  // Function to save edited agent
  const handleSaveEdit = async () => {
    if (!editAgent) return;

    try {
      const response = await fetch(`${baseUrl}/agent/${editAgent.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editAgent),
      });

      if (response.status === 200) {
        const updatedblock = await response.json();
        console.log("agent updated:", updatedblock);

        setAgent((prevagents) =>
          prevagents.map((agent) =>
            agent.id === updatedblock.id ? updatedblock : agent
          )
        );

        setmodal_edit(false);
        setEditblock(null);
      } else {
        console.error("Failed to edit the agent:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the agent:", error);
    }
  };

  // Function to handle deleting a agent
  const handleDeleteAgent = async (agent) => {
    if (agent) {
      try {
        const response = await fetch(`${baseUrl}/agent/${agent.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("agent deleted:", agent.id);

          setAgent((prevagents) => prevagents.filter((r) => r.id !== agent.id));
          setmodal_delete(false);
          setShowAlertDelete(true);
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the agent:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the agent:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Agent" breadcrumbItem="Agent Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">agent</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {hasPermission("add_agent") && (
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => setmodal_list(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Agent
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="status">
                              Name
                            </th>
                            <th className="sort" data-sort="detail">
                              Mobile
                            </th>
                            <th className="sort" data-sort="name">
                              City
                            </th>

                            <th className="sort" data-sort="name">
                              commision
                            </th>
                            <th className="sort" data-sort="name">
                              Amount
                            </th>
                            <th
                              className="sort"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {Agent.map((agent, index) => (
                            <tr key={agent.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              {/* Display status name */}
                              <td className="name">{agent.name}</td>
                              <td className="mobile">{agent.mobile}</td>
                              <td className="city">{agent.city}</td>
                              <td className="commision">{agent.commision}</td>
                              <td className="amount">{agent.amount}</td>
                              <td>
                                <div className="d-flex gap-2">
                                  {hasPermission("change_agent") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => handleEditAgent(agent)}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {hasPermission("delete_agent") && (
                                    <Button
                                      className="btn btn-sm btn-danger edit-item-btn"
                                      onClick={() => {
                                        setAgentkToDelete(agent); // Set the agent to be deleted
                                        setmodal_delete(true); // Open the delete modal
                                      }}
                                      color="soft-danger"
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          tog_list();
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          Add Agent
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddAgent}>
          <ModalBody>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="name-field" className="form-label">
                  Agent Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Enter Name"
                  required
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="mobile-field" className="form-label">
                  Mobile #
                </label>
                <input
                  type="text"
                  name="mobile"
                  className="form-control"
                  placeholder="Enter Phone Number"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="city-field" className="form-label">
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  placeholder="Enter City"
                  required
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-4">
                <label htmlFor="address-field" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  placeholder="Enter Address"
                  required
                />
              </div>

              <div className="col-md-4">
                <label htmlFor="commision-field" className="form-label">
                  Commision
                </label>
                <input
                  type="text"
                  name="commision"
                  className="form-control"
                  placeholder="Enter Commission"
                  required
                />
              </div>
              <div className="col-md-4">
                <label htmlFor="amount-field" className="form-label">
                  Amount
                </label>
                <input
                  type="text"
                  name="amount"
                  className="form-control"
                  placeholder="Enter Amount"
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit Modal */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Agent
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editAgent && (
              <>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="name-field" className="form-label">
                      Agent Name
                    </label>
                    <input
                      type="text"
                      id="name-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editAgent.name} // Populate with editAgent data
                      onChange={(e) =>
                        setEditblock({
                          ...editAgent,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="mobile-field" className="form-label">
                      Mobile
                    </label>
                    <input
                      type="text"
                      id="customername-field"
                      className="form-control"
                      placeholder="Enter Mobile"
                      required
                      value={editAgent.mobile} // Populate with editAgent data
                      onChange={(e) =>
                        setEditblock({
                          ...editAgent,
                          mobile: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="city-field" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      id="city-field"
                      className="form-control"
                      placeholder="Enter City"
                      required
                      value={editAgent.city} // Populate with editAgent data
                      onChange={(e) =>
                        setEditblock({
                          ...editAgent,
                          city: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-4">
                    <label htmlFor="address-field" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      id="address-field"
                      className="form-control"
                      placeholder="Enter Address"
                      required
                      value={editAgent.address} // Populate with editAgent data
                      onChange={(e) =>
                        setEditblock({
                          ...editAgent,
                          address: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="commision-field" className="form-label">
                      Commission
                    </label>
                    <input
                      type="text"
                      id="commision-field"
                      className="form-control"
                      placeholder="Enter Mobile"
                      required
                      value={editAgent.commision} // Populate with editAgent data
                      onChange={(e) =>
                        setEditblock({
                          ...editAgent,
                          commision: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="city-field" className="form-label">
                      Amount
                    </label>
                    <input
                      type="text"
                      id="amount-field"
                      className="form-control"
                      placeholder="Enter City"
                      required
                      value={editAgent.amount} // Populate with editAgent data
                      onChange={(e) =>
                        setEditblock({
                          ...editAgent,
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditblock(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (blockToDelete) {
                  handleDeleteAgent(blockToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Modal  */}

      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Agent Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Agent Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Agent;
