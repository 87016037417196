import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import './PrintPlotReport.css'
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

export default function AccountReportPrint() {
  const [addPlotData, setPlotData] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [AccountData, setAccountData] = useState([]);
  const groupId = sessionStorage.getItem("groupId");
  const [installmentData, setInstallmentData] = useState([]);
  const userApiKey = `${baseUrl}/users`;
  const { filteredData } =
    location.state || {};


    const fetchData = async () => {
      try {
        const fetchAccounts = await fetch(`${baseUrl}/accounts/`);
        if (fetchAccounts.ok) {
          const data = await fetchAccounts.json();
          setAccountData(data);
        } else {
          console.error("Error fetching account data:", fetchAccounts.statusText);
        }
      } catch (error) {
        console.error("Error fetching account data:", error);
      }
    };

    useEffect(() => {
      fetchData();
    }, []);
    
  useEffect(() => {
    if (filteredData.length > 0) {
      window.print();
    }
  }, [filteredData]);

  function getAccountName(accountId) {
    const Account = AccountData.find((account) => account.id === accountId);
    return Account ? Account.name : "N/A";
  }

  return (
    <div className="card page-content">
      <div className=" print-visible">
        <div style={{ textAlign: "center" }}>
          <h3>
            <strong> {username && <p>{username}</p>}</strong>
          </h3>
        </div>
        <p
          style={{ textAlign: "center", marginTop: "none" }}
          className="print-visible"
        >
          <strong></strong>
        </p>
      </div>

      <div className="table-responsive table-card mt-3">
        <h5
          className="p-2 text-center mt-4"
          style={{ color: "white", background: "black" }}
        >
          Account Report
        </h5>

        <table className="table align-middle table-nowrap" id="table">
          <thead className="table-light">
            <tr>
              <th>Sr #</th>
              <th>Account</th>
              <th>Plot</th>
              <th>Receipt</th>
              <th>Installment</th>
              <th>Voucher#</th>
              <th>B_Account</th>
              <th>Type</th>
              <th>Ref# </th>
              <th>Remarks</th>
              <th>Amount</th>
              <th>Entry Date</th>
            </tr>
          </thead>
          <tbody className="list form-check-all" id="myTable">
            {filteredData.length > 0 ? (
              filteredData.map((transaction, index) => (
                <tr key={transaction.id}>
                  <td>{index + 1}</td>
                  <td className="id">{getAccountName(transaction.account)}</td>
                  <td>{transaction.plot}</td>
                  <td>{transaction.receipt}</td>
                  <td>{transaction.installment}</td>
                  <td>{transaction.voucher_no}</td>
                  <td>{getAccountName(transaction.b_account)}</td>
                  <td>{transaction.type}</td>
                  <td>{transaction.ref_no}</td>
                  <td>{transaction.remarks}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.entry_date}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="12" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
