import React from "react";
import { Routes, Route } from "react-router-dom";

// redux
import { useSelector } from "react-redux";

//constants
import { layoutTypes } from "../constants/layout";

// layouts
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
// import { AuthProtected } from "./AuthProtected";
import ProtectedRoute from "./AuthProtected";

import { authProtectedRoutes, publicRoutes } from "./routes";

const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const Index = () => {

  const { layoutType } = useSelector((state) => ({
    layoutType: state.Layout.layoutType,
  }));

  const Layout = getLayout(layoutType);

  return (
    <Routes>
      <Route>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                  {route.component}
              </NonAuthLayout>
          }
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      <Route>
      {authProtectedRoutes.map((route, idx) => (
  <Route
    path={route.path}
    element={
      <ProtectedRoute element={<Layout>{route.component}</Layout>} />
    }
    key={idx}
    exact={true}
  />
))}

      </Route>
        {/* 404 Not Found Route */}
        <Route path="*" element={<NotFoundPage />} />

    </Routes>
  );
};


const NotFoundPage = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h1 className="display-1">404 - Not Found</h1>
        <p className="lead">The page you're looking for does not exist.</p>
        {/* Add more Bootstrap classes for additional styling */}
      </div>
    </div>
  );
};

export default Index;