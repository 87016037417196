import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// import './PrintPlotReport.css'
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

export default function RecoveryPrint() {
  const [addPlotData, setPlotData] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [showData, setShowData] = useState(false);
  const [Booking, setBooking] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedPlot, setSelectedPlot] = useState("");
  const groupId = sessionStorage.getItem("groupId");
  const [installmentData, setInstallmentData] = useState([]);
  const userApiKey = `${baseUrl}/users`;
  const { BookingData, InstallmentData, SelectedCustomer, SelectedPlot } =
    location.state || {};

  useEffect(() => {
    const fetchPlotData = async () => {
      try {
        const response = await fetch(`${baseUrl}/plot/`);
        const data = await response.json();
        setPlotData(data);
      } catch (error) {
        console.error("Error fetching plot data:", error);
      }
    };

    fetchPlotData();
  }, []);

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const response = await fetch(`${baseUrl}/accounts/`);
        const data = await response.json();
        setCustomer(data);

        const customerId = data[0]?.id;
        const plotId = BookingData[0]?.plot; // Assuming plot ID is available in BookingData
        fetchInstallmentData(customerId, plotId);
      } catch (error) {
        console.error("Error fetching Customer:", error);
      }
    };

    fetchCustomerData();

    // Fetch data of Installments based on customer_id and plot_id
    const fetchInstallmentData = async (customerId, plotId) => {
      try {
        const response = await fetch(
          `${baseUrl}/installment-data/?customer=${customerId}&plot=${plotId}`
        );
        const data = await response.json();
        setInstallmentData(data);
      } catch (error) {
        console.error("Error fetching installment data:", error);
      }
    };
  }, [BookingData]);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await fetch(`${baseUrl}/users/${groupId}/`, {
          method: "GET",
          headers: {
            Authorization: `Api-Key ${userApiKey}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUsername(userData.username);
        } else {
          console.error("Failed to fetch username:", response.status);
          // Handle error when fetching username
        }
      } catch (error) {
        console.error("Fetch username error:", error);
        // Handle fetch username error
      }
    };

    if (groupId) {
      fetchUsername();
    }
  }, [groupId, userApiKey]);

  useEffect(() => {
    // Check if plot and customer data is available before setting ready to print
    if (addPlotData.length > 0 && Customer.length > 0) {
      window.print();
    }
  }, [addPlotData, Customer]);

  // get Plot Name
  const getPlotName = (plotID) => {
    const plot = addPlotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = Customer.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };

  const filteredData = installmentData.filter(
    (data) =>
      data.customer.toString() === selectedCustomer &&
      data.plot.toString() === selectedPlot
  );
  const totalClosingBalance = filteredData.reduce((total, item) => {
    const remainingBalance =
      parseFloat(item.amount) - parseFloat(item.paid || 0);
    return total + remainingBalance;
  }, 0);

  const calculateTotalReceivedBalance = () => {
    let total = 0;
    let received = 0;

    installmentData
      .filter(
        (data) =>
          data.customer.toString() === selectedCustomer &&
          data.plot.toString() === selectedPlot
      )
      .forEach((data) => {
        total += parseFloat(data.amount);
        received += parseFloat(data.paid ? data.paid : 0);
      });

    const balance = total - received;

    return { total, received, balance };
  };

  const { total, received, balance } = calculateTotalReceivedBalance();

  const closingBalance = totalClosingBalance;

  return (
    <div className="card page-content">
      <div className=" print-visible">
        <div style={{ textAlign: "center" }}>
          <h3>
            <strong> {username && <p>{username}</p>}</strong>
          </h3>
        </div>
        <p
          style={{ textAlign: "center", marginTop: "none" }}
          className="print-visible"
        >
          <strong></strong>
        </p>
      </div>

      <div className="table-responsive table-card mt-3">
        <h5
          className="p-2 text-center mt-4"
          style={{ color: "white", background: "black" }}
        >
          Recovery Report
        </h5>

        <table className="table align-middle table-nowrap" id="customer-table">
          <thead className="table-light">
            <tr></tr>
          </thead>
          <tbody>
            {BookingData.map((data) => (
              <tr key={data.id}></tr>
            ))}
          </tbody>
          <thead className="table-light">
            <tr>
              <th>Sr#</th>
              <th>Code</th>
              <th>Name</th>
              <th>Address</th>
              <th>Phone#</th>
              <th>Plot#</th>
              <th>SizeValue</th>
              <th>Months</th>
              <th>Total</th>
              <th>Received</th>
              <th>Balance</th>
            </tr>
          </thead>

          <tbody>
            {BookingData.map((booking, index) => {
              const correspondingInstallment = installmentData.find(
                (installment) =>
                  installment.customer.toString() === selectedCustomer &&
                  installment.plot.toString() === selectedPlot &&
                  installment.id === booking.id
              );

              return (
                <tr
                  key={index + 1}
                  style={{
                    color:
                      correspondingInstallment?.paid === "0.00"
                        ? "red"
                        : "inherit",
                    backgroundColor:
                      correspondingInstallment?.paid === "0.00"
                        ? "#ffe5e5"
                        : "inherit",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{booking.customer}</td>
                  <td className="customer_id">
                    {getCustomerName(booking.customer)}
                  </td>
                  <td>{booking.address}</td>
                  <td>{booking.phone}</td>
                  <td>{getPlotName(booking.plot)}</td>
                  <td>{booking.size}</td>
                  <td>{booking.promise_date}</td>
                  {/* <td>{booking.amount}</td> */}
                  <td>{correspondingInstallment?.paid || "0.00"}</td>
                  <td>{correspondingInstallment?.paid && correspondingInstallment?.paid !== "0"
                      ? parseFloat(booking.amount) -
                        parseFloat(correspondingInstallment?.paid)
                      : booking.amount}
                  </td>
                </tr>
              );
            })}

            {InstallmentData.filter(
              (data) =>
                data.customer.toString() === SelectedCustomer &&
                data.plot.toString() === SelectedPlot &&
                !BookingData.some((booking) => booking.id === data.id)
            ).map((data, index) => (
              <tr
                key={index + 1}
                style={{
                  color: data.paid === "0.00" ? "red" : "inherit",
                  backgroundColor: data.paid === "0.00" ? "#ffe5e5" : "inherit",
                }}
              >
                <td>{index + 2}</td>
                <td>{data.customer}</td>
                <td
                  className="customer_id"
                  style={{
                    fontWeight: 400,
                    color:
                      data.balance === null
                        ? "red"
                        : data.balance == 0
                        ? "green"
                        : data.balance > 0
                        ? "orange"
                        : data.amount < data.balance
                        ? "grey"
                        : "inherit",
                  }}
                >
                  {getCustomerName(data.customer)}
                </td>
                <td>{data.address}</td>
                <td>{data.phone}</td>
                <td
                  style={{
                    fontWeight: 400,
                    color:
                      data.balance === null
                        ? "red"
                        : data.balance == 0
                        ? "green"
                        : data.balance > 0
                        ? "orange"
                        : data.amount < data.balance
                        ? "grey"
                        : "inherit",
                  }}
                >
                  {getPlotName(data.plot)}
                </td>
                <td>{data.plot.size}</td>
                <td
                  style={{
                    fontWeight: 400,
                    color:
                      data.balance === null
                        ? "red"
                        : data.balance == 0
                        ? "green"
                        : data.balance > 0
                        ? "orange"
                        : data.amount < data.balance
                        ? "grey"
                        : "inherit",
                  }}
                >
                  {data.promise_date}
                </td>
                <td
                  style={{
                    fontWeight: 400,
                    color:
                      data.balance === null
                        ? "red"
                        : data.balance == 0
                        ? "green"
                        : data.balance > 0
                        ? "orange"
                        : data.amount < data.balance
                        ? "grey"
                        : "inherit",
                  }}
                >
                  {data.amount}
                </td>
                <td
                  style={{
                    fontWeight: 400,
                    color:
                      data.balance === null
                        ? "red"
                        : data.balance == 0
                        ? "green"
                        : data.balance > 0
                        ? "orange"
                        : data.amount < data.balance
                        ? "grey"
                        : "inherit",
                  }}
                >
                  {data.paid || "0.00"}
                </td>
                <td
                  style={{
                    fontWeight: 400,
                    color:
                      data.balance === null
                        ? "red"
                        : data.balance == 0
                        ? "green"
                        : data.balance > 0
                        ? "orange"
                        : data.amount < data.balance
                        ? "grey"
                        : "inherit",
                  }}
                >
                  {data.paid && data.paid !== "0"
                    ? parseFloat(data.amount) - parseFloat(data.paid)
                    : data.amount}
                </td>
              </tr>
            ))}

            <br />
            <br />
            <tr style={{ background: "black", color: "white" }}>
              <td colSpan={8} style={{ textAlign: "right", color: "red" }}>
                <strong>Closing Balance:</strong>
              </td>
              <td colSpan="1">{total.toFixed(2)}</td>
              <td colSpan="1">{received.toFixed(2)}</td>
              <td colSpan="2">{balance.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
