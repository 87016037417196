import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Form,
  Alert,
} from "reactstrap";
import { env } from "../../config";
import withRouter from "../../components/Common/withRouter";
import Breadcrumb from "../../components/Common/Breadcrumb";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
const ChangePassword = () => {
  document.title = "Profile | Allied Tajar(ERP)";
  const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

  const [updateData, setUpdateData] = useState({
    old_password: "",
    password: "",
    password2: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  
  useEffect(() => {
    const clearMessages = () => {
      setSuccessMessage(null);
      setErrorMessage(null);
    };

    const successTimeout = setTimeout(clearMessages, 3000);

    return () => clearTimeout(successTimeout);
  }, [successMessage, errorMessage]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdateData({ ...updateData, [name]: value });
  };

  const handleToggleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    const userId = sessionStorage.getItem("login");

    try {
      const response = await fetch(`${baseUrl}/change-password/${userId}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updateData),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Password update response:', responseData);
        setSuccessMessage("Password updated successfully");
        setErrorMessage(null);
      } else {
        const errorData = await response.json();
        console.error('Error updating password:', errorData);
        setErrorMessage(errorData.error || "Failed to update password");
        setSuccessMessage(null);
      }
    } catch (error) {
      console.error('Error updating user password:', error);
      setErrorMessage("An error occurred while updating password");
      setSuccessMessage(null);
    }
  };

  
  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Allied Tajar" breadcrumbItem="ChangePassword" />

          <Card>
            <CardBody>
              <Form className="form-horizontal" onSubmit={handleUpdatePassword}>
              {successMessage && <Alert color="success">{successMessage}</Alert>}
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <div className="form-group">
                <div className="row mb-3">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <Label className="form-label">Last Password</Label>
                    <div className="input-group">
                      <Input
                        name="old_password"
                        value={updateData.old_password}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter Last Password"
                        type={showOldPassword ? 'text' : 'password'}
                      />
                      <span className="input-group-text" onClick={handleToggleOldPassword}>
                        <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>

                {/* New Password */}
                <div className="row mb-3">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <Label className="form-label">New Password</Label>
                    <div className="input-group">
                      <Input
                        name="password"
                        value={updateData.password}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Enter New Password"
                        type={showNewPassword ? 'text' : 'password'}
                      />
                      <span className="input-group-text" onClick={handleToggleNewPassword}>
                        <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>

                {/* Confirm Password */}
                <div className="row mb-3">
                  <div className="col-md-3"></div>
                  <div className="col-md-6">
                    <Label className="form-label">Confirm Password</Label>
                    <div className="input-group">
                      <Input
                        name="password2"
                        value={updateData.password2}
                        onChange={handleInputChange}
                        className="form-control"
                        placeholder="Confirm Password"
                        type={showConfirmPassword ? 'text' : 'password'}
                      />
                      <span className="input-group-text" onClick={handleToggleConfirmPassword}>
                        <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>

                {/* Submit Button */}
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Update
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ChangePassword);
