import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Button, Row } from "reactstrap";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

// ... (previous code remains unchanged)

const PlotReport = () => {
  const [addPlotData, setPlotData] = useState([]);
  const [Booking, setBooking] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [showData, setShowData] = useState(false);
  const [endDate, setEndDate] = useState("");
  const [Customer, setCustomer] = useState([]);
  const [installmentData, setInstallmentData] = useState([]);
  const [printDisabled, setPrintDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedPlot, setSelectedPlot] = useState("");
  const [getplots, setPlots] = useState([]);
  const [installmentBalance, setInstallmentBalance] = useState({});

  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(`${baseUrl}/plot/`);
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlotData(filteredData);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch data of Installments based on customer_id
  const fetchInstallmentData = async (customerId, plotId) => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }
      const response = await fetch(
        `${baseUrl}/installment-data/?accounts=${customerId}&plot=${plotId}`
      );
      const data = await response.json();
      const filteredData = data.filter(
        (item) => item.group.toString() === groupId
      );
      setInstallmentData(filteredData);
    } catch (error) {
      console.error("Error fetching installment data:", error);
    }
  };

  useEffect(() => {
    fetch(`${baseUrl}/accounts/`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setCustomer(data);

        const customerId = data[0]?.id;
        fetchInstallmentData(customerId);
      })
      .catch((error) => {
        console.error("Error fetching Customer:", error);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  // get Plot Name
  const getPlotName = (plotID) => {
    const plot = addPlotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = Customer.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCustomerSelect = async (custId) => {
    setSelectedCustomer(custId);
    setPlots([]);
    setInstallmentData([]);
    if (custId) {
      try {
        const response = await fetch(
          `${baseUrl}/receipt-booking/?customer_id=${custId}`
        );
        const data = await response.json();

        if (data.message) {
          console.warn(data.message);
          setPlots([]);
        } else {
          setPlots(data);
        }
      } catch (error) {
        console.error("Error fetching plots:", error);
        setPlots([]);
      }
    } else {
      setPlots([]);
    }
  };
  const handlePlotSelect = (plotId) => {
    setSelectedPlot(plotId);
    fetchInstallmentData(selectedCustomer, plotId);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    try {
      const plotId = e.target.elements.plot.value;
      const cId = e.target.elements.customer.value;

      // Format the start and end dates for the API call
      const formattedStartDate =
        startDate && new Date(startDate).toISOString().split("T")[0];
      const formattedEndDate =
        endDate && new Date(endDate).toISOString().split("T")[0];

      // Fetch booking data
      const bookingResponse = await fetch(
        `${baseUrl}/booking/?plot=${plotId}&customer=${cId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
      );
      const bookingData = await bookingResponse.json();

      // Fetch installment data
      const installmentResponse = await fetch(
        `${baseUrl}/installment-data/?accounts=${cId}&plot=${plotId}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`
      );
      const installmentData = await installmentResponse.json();

      let filteredBooking = bookingData;
      let filteredInstallments = installmentData;

      // Apply additional filters if customer and plot are selected
      if (cId !== "" && plotId !== "") {
        filteredBooking = filteredBooking.filter(
          (item) =>
            item.customer.toString() === cId && item.plot.toString() === plotId
        );

        filteredInstallments = filteredInstallments.filter(
          (item) =>
            item.customer.toString() === cId &&
            item.plot.toString() === plotId &&
            (!formattedStartDate ||
              new Date(item.promise_date) >= new Date(formattedStartDate)) &&
            (!formattedEndDate ||
              new Date(item.promise_date) <= new Date(formattedEndDate))
        );
      }

      if (filteredBooking.length === 0 || filteredInstallments.length === 0) {
        setError(true);
        setBooking([]);
        setInstallmentData([]);
        setShowTable(false);
        setShowData(false);
        setPrintDisabled(false);
      } else {
        setBooking(filteredBooking);
        setInstallmentData(filteredInstallments);
        setShowTable(true);
        setError(false);
        setShowData(true);
        setPrintDisabled(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    }
  };

  const filteredData = installmentData.filter(
    (data) =>
      data.customer.toString() === selectedCustomer &&
      data.plot.toString() === selectedPlot
  );

  const totalClosingBalance = filteredData.reduce((total, item) => {
    const remainingBalance =
      parseFloat(item.amount) - parseFloat(item.paid || 0);
    return total + remainingBalance;
  }, 0);

  const calculateTotalReceivedBalance = () => {
    let total = 0;
    let received = 0;

    installmentData
      .filter(
        (data) =>
          data.customer.toString() === selectedCustomer &&
          data.plot.toString() === selectedPlot
      )
      .forEach((data) => {
        total += parseFloat(data.amount);
        received += parseFloat(data.paid ? data.paid : 0);
      });

    const balance = total - received;

    return { total, received, balance };
  };

  const { total, received, balance } = calculateTotalReceivedBalance();

  const closingBalance = totalClosingBalance;

  //   // Print Function
  const navigate = useNavigate();
  const handlePrint = () => {
    if (selectedCustomer && selectedPlot) {
      navigate("/recovery-report/recovery-report-print/", {
        state: {
          BookingData: Booking,
          ShowData: showData,
          TotalBalance: closingBalance,
          InstallmentData: installmentData,
          SelectedCustomer: selectedCustomer,
          SelectedPlot: selectedPlot,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <form
          className="tablelist-form"
          encType="multipart/form-data"
          onSubmit={handleSearch}
        >
          <div id="AddBookingList">
            <Row className="g-4">
              <div className="col-sm-3">
                <label htmlFor="customer" className="form-label">
                  Customer
                </label>
                <select
                  className="form-control"
                  name="customer"
                  id="customer-field"
                  value={selectedCustomer}
                  onChange={(e) => handleCustomerSelect(e.target.value)}
                >
                  <option value="">Choose Customer</option>
                  {Customer.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="plot-field" className="form-label">
                  Plot
                </label>
                <select
                  className="form-control"
                  name="plot"
                  id="plot"
                  onChange={(e) => handlePlotSelect(e.target.value)}
                >
                  <option value="">Choose Plot</option>
                  {getplots && getplots.length > 0 ? (
                    getplots.map((booking) => (
                      <option key={booking.plot.id} value={booking.plot.id}>
                        {booking.plot.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No plots available
                    </option>
                  )}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="start_date">Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="start_date"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="end_date">End Date</label>
                <input
                  type="date"
                  className="form-control"
                  name="end_date"
                  value={endDate}
                  onChange={handleEndDateChange}
                />
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <div className="col-md-1">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <Button
                  color="success"
                  className="add-btn"
                  id="create-btn"
                  type="submit"
                >
                  Search
                </Button>
              </div>
              <div className="col-md-1">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                <Button
                  color="primary"
                  className="add-btn"
                  onClick={handlePrint}
                  disabled={printDisabled}
                >
                  <span>Print</span>
                </Button>
              </div>
            </Row>
          </div>
        </form>

        <div className="table-responsive table-card mt-3">
          <h5
            className="p-2 text-center mt-4"
            style={{ color: "white", background: "black" }}
          >
            Recovery Report
          </h5>

          <table
            className="table align-middle table-nowrap"
            id="customer-table"
          >
            <thead className="table-light">
              <tr></tr>
            </thead>
            <tbody>
              {showData && Booking.map((data) => <tr key={data.id}></tr>)}
            </tbody>
            <thead className="table-light">
              <tr>
                <th>Sr#</th>
                <th>Code</th>
                <th>Name</th>
                <th>Address</th>
                <th>Phone#</th>
                <th>Plot#</th>
                <th>SizeValue</th>
                <th>Months</th>
                <th>Total</th>
                <th>Received</th>
                <th>Balance</th>
              </tr>
            </thead>

            <tbody>
              {showData &&
                Booking.map((booking, index) => {
                  const correspondingInstallment = installmentData.find(
                    (installment) =>
                      installment.customer.toString() === selectedCustomer &&
                      installment.plot.toString() === selectedPlot &&
                      installment.id === booking.id
                  );

                  return (
                    <tr
                      key={index + 1}
                      style={{
                        color:
                          correspondingInstallment?.paid === "0.00"
                            ? "red"
                            : "inherit",
                        backgroundColor:
                          correspondingInstallment?.paid === "0.00"
                            ? "#ffe5e5"
                            : "inherit",
                      }}
                    >
                      <td>{index + 1}</td>
                      <td>{booking.customer}</td>
                      <td className="customer_id">
                        {getCustomerName(booking.customer)}
                      </td>
                      <td>{booking.address}</td>
                      <td>{booking.phone}</td>
                      <td>{getPlotName(booking.plot)}</td>
                      <td>{booking.size}</td>
                      <td>{booking.promise_date}</td>
                      {/* <td>{booking.amount}</td> */}
                      <td>{correspondingInstallment?.paid || "0.00"}</td>
                      <td>
                        {correspondingInstallment?.paid &&
                        correspondingInstallment?.paid !== "0"
                          ? parseFloat(booking.amount) -
                            parseFloat(correspondingInstallment?.paid)
                          : booking.amount}
                      </td>
                    </tr>
                  );
                })}

              {showData &&
                installmentData
                  .filter(
                    (data) =>
                      data.customer.toString() === selectedCustomer &&
                      data.plot.toString() === selectedPlot &&
                      !Booking.some((booking) => booking.id === data.id)
                  )
                  .map((data, index) => (
                    <tr
                      key={index + 1}
                      style={{
                        color: data.paid === "0.00" ? "red" : "inherit",
                        backgroundColor:
                          data.paid === "0.00" ? "#ffe5e5" : "inherit",
                      }}
                    >
                      <td>{index + 2}</td>
                      <td>{data.customer}</td>
                      <td
                        className="customer_id"
                        style={{
                          fontWeight: 400,
                          color:
                            data.balance === null
                              ? "red"
                              : data.balance == 0
                              ? "green"
                              : data.balance > 0
                              ? "orange"
                              : data.amount < data.balance
                              ? "grey"
                              : "inherit",
                        }}
                      >
                        {getCustomerName(data.customer)}
                      </td>
                      <td>{data.address}</td>
                      <td>{data.phone}</td>
                      <td
                        style={{
                          fontWeight: 400,
                          color:
                            data.balance === null
                              ? "red"
                              : data.balance == 0
                              ? "green"
                              : data.balance > 0
                              ? "orange"
                              : data.amount < data.balance
                              ? "grey"
                              : "inherit",
                        }}
                      >
                        {getPlotName(data.plot)}
                      </td>
                      <td>{data.plot.size}</td>
                      <td
                        style={{
                          fontWeight: 400,
                          color:
                            data.balance === null
                              ? "red"
                              : data.balance == 0
                              ? "green"
                              : data.balance > 0
                              ? "orange"
                              : data.amount < data.balance
                              ? "grey"
                              : "inherit",
                        }}
                      >
                        {data.promise_date}
                      </td>
                      <td
                        style={{
                          fontWeight: 400,
                          color:
                            data.balance === null
                              ? "red"
                              : data.balance == 0
                              ? "green"
                              : data.balance > 0
                              ? "orange"
                              : data.amount < data.balance
                              ? "grey"
                              : "inherit",
                        }}
                      >
                        {data.amount}
                      </td>
                      <td
                        style={{
                          fontWeight: 400,
                          color:
                            data.balance === null
                              ? "red"
                              : data.balance == 0
                              ? "green"
                              : data.balance > 0
                              ? "orange"
                              : data.amount < data.balance
                              ? "grey"
                              : "inherit",
                        }}
                      >
                        {data.paid || "0.00"}
                      </td>
                      <td
                        style={{
                          fontWeight: 400,
                          color:
                            data.balance === null
                              ? "red"
                              : data.balance == 0
                              ? "green"
                              : data.balance > 0
                              ? "orange"
                              : data.amount < data.balance
                              ? "grey"
                              : "inherit",
                        }}
                      >
                        {data.paid && data.paid !== "0"
                          ? parseFloat(data.amount) - parseFloat(data.paid)
                          : data.amount}
                      </td>
                    </tr>
                  ))}

              <br />
              <br />
              <tr style={{ background: "black", color: "white" }}>
                <td colSpan={8} style={{ textAlign: "right", color: "red" }}>
                  <strong>Closing Balance:</strong>
                </td>
                <td colSpan="1">{total.toFixed(2)}</td>
                <td colSpan="1">{received.toFixed(2)}</td>
                <td colSpan="2">{balance.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="page-content">
          {error && (
            <div className="alert alert-danger" role="alert">
              No data available for the selected criteria.
            </div>
          )}

          {/* Rest of your code */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PlotReport;
