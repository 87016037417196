import React, { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./print.css";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;
const BookingPrint = () => {
  const [bookings, setbookings] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [Block, setBlock] = useState([]);
  const [Agent, setAgent] = useState([]);
  const [Plot, setPlot] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    fetchPlotData();
    fetchBlockData();
    fetchData();
    updateDateTime();
    fetchAgentData();
    fetchCustomertData();
  }, []);

  const getCustomerName = (customerID) => {
    const customer = Customer.find((customer) => customer.id === customerID);
    return customer ? customer.name : "N/A";
  };
  const getBlockName = (blockID) => {
    const block = Block.find((block) => block.id === blockID);
    return block ? block.name : "N/A";
  };

  const getPlotName = (plotID) => {
    const plot = Plot.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };
  const getAgentName = (agentID) => {
    const agent = Agent.find((agent) => agent.id === agentID);
    return agent ? agent.name : "N/A";
  };
  // Function to fetch updated booking data
  const fetchbookingData = async () => {
    try {
      const response = await fetch(`${baseUrl}/booking/`);
      if (response.ok) {
        const data = await response.json();
        return data;
      } else {
        throw new Error("Failed to fetch booking data");
      }
    } catch (error) {
      console.error("Error fetching booking data:", error);
      return [];
    }
  };
  //   Fetch Custoomer Data
  const fetchCustomertData = async () => {
    try {
      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        setCustomer(data);
        console.log("Fetched data:", data);
      } else {
        console.error("Error fetching customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching customer:", error);
    }
  };

  //   Fetch Agent Data
  const fetchAgentData = async () => {
    try {
      const response = await fetch(`${baseUrl}/agent/`);
      if (response.ok) {
        const data = await response.json();
        setAgent(data);
        console.log("Fetched data:", data);
      } else {
        console.error("Error fetching agent:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching agent:", error);
    }
  };

  //   Fetch PACKAGE   Data
  const fetchPlotData = async () => {
    try {
      const response = await fetch(`${baseUrl}/plot/`);
      if (response.ok) {
        const data = await response.json();
        setPlot(data);
        console.log("Fetched data:", data);
      } else {
        console.error("Error fetching plot:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching plot:", error);
    }
  };

  //   Fetch Agent Data
  const fetchBlockData = async () => {
    try {
      const response = await fetch(`${baseUrl}/block/`);
      if (response.ok) {
        const data = await response.json();
        setBlock(data);
        console.log("Fetched data:", data);
      } else {
        console.error("Error fetching block:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching block:", error);
    }
  };

  const updateDateTime = () => {
    const now = new Date();
    const formattedDate = now.toLocaleDateString();
    const formattedTime = now.toLocaleTimeString();
    setCurrentDate(formattedDate);
    setCurrentTime(formattedTime);
  };
  // // Inside your useEffect that fetches initial data

  const fetchData = async () => {
    try {
      const updatedbookings = await fetchbookingData();
      setbookings(updatedbookings); 
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  useEffect(() => {
    if (dataFetched) {
      window.print();
    }
  }, [dataFetched]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="">
                <div className="row print-visible">
                  <div className="col-md-4">
                    <div className="d-flex">
                      <ReactHTMLTableToExcel
                        id="table-to-excel"
                        table="table"
                        className="btn btn-primary d-print-none"
                        filename="Business Profile Report"
                        sheet="sheet1"
                        buttonText="Export to Excel"
                      />
                    </div>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </CardHeader>
              <CardBody className="print-visible">
                <div id="customerList">
                  <div className="border p-3">
                    <div className="row print-visible">
                    <div className="col-md-5 print-visible">
                      <div style={{ textAlign: "center" }}>
                         <h3 className="text-center text-white bg-black p-2 mb-0">
                            Booking List
                          </h3>
                      </div>
                    </div>
                      <div className="col-md-12 d-flex justify-content-end">
                        <div>
                          <p>
                            <strong>Date:</strong> {currentDate}{" "}
                            <strong>Time:</strong> {currentTime}
                          </p>
                       
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="table-responsive table-card mt-3 mb-1 print-visible">
                    <table
                      className="table align-middle table-nowrap"
                      id="table"
                    >
                      <thead className="table-light">
                        <tr>
                          <th scope="col"></th>
                          <th className="sort" data-sort="sr">
                            Sr#
                          </th>
                          <th className="sort" data-sort="booking_no">
                            Booking#
                          </th>
                          <th className="sort" data-sort="date">
                            Date
                          </th>
                          <th className="sort" data-sort="customer">
                            Customer
                          </th>
                          <th className="sort" data-sort="phone">
                            Phone#
                          </th>
                          <th className="sort" data-sort="block">
                            Block
                          </th>
                          <th className="sort" data-sort="plot">
                            Plot
                          </th>
                          <th className="sort" data-sort="size">
                            Size
                          </th>
                          <th className="sort" data-sort="size_unit">
                            Unit
                          </th>
                          <th className="sort" data-sort="promise_date">
                            Promise Date
                          </th>
                          <th className="sort" data-sort="agent">
                            Agent
                          </th>
                          <th className="sort" data-sort="Document">
                            Document
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all" id="myTable">
                        {bookings.map((booking, index) => (
                          <tr key={booking.id}>
                            <th scope="row"></th>
                            <td className="srNumber">{index + 1}</td>
                            <td className="booking_no">{booking.booking_no}</td>
                            <td className="f_name">{booking.booking_date}</td>
                            <td className="customer_id">
                              {getCustomerName(booking.customer)}
                            </td>
                            <td className="name">{booking.phone}</td>
                            <td className="customer_id">
                              {getBlockName(booking.block)}
                            </td>
                            <td className="customer_id">
                              {getPlotName(booking.plot)}
                            </td>
                            <td className="size">{booking.size}</td>
                            <td className="size_unit">{booking.size_unit}</td>
                            <td className="promise_date">{booking.promise_date}</td>
                            <td className="agent">
                              {getAgentName(booking.agent)}
                            </td>
                            <td className="document">
                              <img
                                src={booking.document}
                                alt="booking"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  marginTop: "20px",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BookingPrint;
