import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;
const Receipt = () => {
  const [receiptNumber, setReceiptNumber] = useState("");
  const [modalList, setModalList] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [plotToDelete, setPlotToDelete] = useState(null);
  const [Customer, setCustomer] = useState([]);
  const [installmentData, setInstallmentData] = useState([]);
  const [editReceipt, setEditReceipt] = useState(null);
  const [currentDate, setCurrentDate] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [showReceipt, setShowReceipt] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [selectedPlot, setSelectedPlot] = useState("");
  const [installmentName, setInstallmentName] = useState("");
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [getplots, setPlots] = useState([]);
  const [addMonth, setAddMonth] = useState({
    amount: 0,
    paid_amount: 0,
  });

  const [plotDetails, setPlotDetails] = useState({
    package_choice: "",
    no_Of_installment: "",
    amount_package: "",
  });

  const navigate = useNavigate();

  const toggleList = () => {
    setModalList(!modalList);
  };
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };

  const fetchReceipts = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/receipt/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setShowReceipt(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };

  const fetchInstallments = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/installment-data/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setInstallmentName(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };

  const fetchMaxReceiptId = async () => {
    try {
      const response = await fetch(`${baseUrl}/receipt/max_receipt_id/`);
      const data = await response.json();
      const maxId = data.max_id !== undefined ? parseInt(data.max_id, 10) : 0;
      setReceiptNumber(maxId + 1);
    } catch (error) {
      console.error("Error fetching Last Quotation ID:", error);
    }
  };

  useEffect(() => {
    setCurrentDate(getCurrentDate());
    fetchMaxReceiptId();
    fetchReceipts();
    fetchInstallments();
  }, []);

  const getInstallmentName = (agentID) => {
    const dateName = installmentName.find((month) => month.id === agentID);
    return dateName ? dateName.month : "N/A";
  };

  // Function to calculate and update the total
  const calculateTotal = () => {
    const total = addMonth.amount - addMonth.paid_amount;
    return total.toFixed(2);
  };

  const fetchPlotDetails = async (selectedBookingId) => {
    if (selectedBookingId) {
      try {
        // Reset plot details before making the new API call
        setPlotDetails({
          package_choice: null,
          no_Of_installment: null,
          amount_package: null,
        });

        const response = await fetch(
          `${baseUrl}/booking/?plotId=${selectedBookingId}`
        );
        const data = await response.json();

        if (data.length > 0) {
          const bookingData = data.find(
            (booking) => booking.plot == selectedBookingId
          );
          if (bookingData) {
            setPlotDetails((prevDetails) => ({
              ...prevDetails,
              package_choice: bookingData.package_choice,
              no_Of_installment: bookingData.no_Of_installment,
              amount_package: bookingData.amount_package,
            }));
          } else {
            console.log("No booking found for the selected plot");
          }
        } else {
          console.log(
            "No data or empty response received for the selected plot"
          );
        }
      } catch (error) {
        console.error("Error fetching plot details:", error);
      }
    }
  };

  useEffect(() => {
    fetchPlotDetails(selectedPlot);
  }, [selectedPlot]);

  const fetchCustomerData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomer(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };
  useEffect(() => {
    fetchCustomerData();
  }, []);

  const handleCustomerSelect = async (customerId) => {
    setSelectedCustomer(customerId);
    if (customerId) {
      try {
        const response = await fetch(
          `${baseUrl}/receipt-booking/?customer_id=${customerId}`
        );
        const data = await response.json();

        if (data.message) {
          console.warn(data.message);
          setPlots([]);
          setInstallmentData([]);
        } else {
          setPlots(data);
          setInstallmentData([]);

          if (data.length > 0) {
            const plotId = data[0].id;
            fetchPlotMonths(plotId);
          }
        }
      } catch (error) {
        console.error("Error fetching plots:", error);
        setPlots([]);
        setInstallmentData([]);
      }
    } else {
      setPlots([]);
      setInstallmentData([]);
    }
  };

  const fetchPlotMonths = async (plotId) => {
    try {
      const response = await fetch(
        `${baseUrl}/installment-data/?plot=${plotId}`
      );
      const data = await response.json();

      if (data.message) {
        console.warn(data.message);
        setInstallmentData([]);
      } else {
        // Filter the data to only include the selected plot
        const selectedPlotData = data.filter((item) => item.plot == plotId);
        setInstallmentData(selectedPlotData);
      }
    } catch (error) {
      console.error("Error fetching installment data:", error);
      setInstallmentData([]);
    }
  };

  const searchFunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false;
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true;

      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true;
              break;
            }
          }
        }

        if (!found) {
          showRow = false;
        }
      }

      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const InstallmentChangeData = async (e) => {
    const { name, value } = e.target;

    const fetchMonthData = async (month) => {
      try {
        const response = await fetch(`${baseUrl}/installment-data/${month}`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    switch (name) {
      case "installments":
        const monthData = await fetchMonthData(value);

        if (monthData) {
          setAddMonth((prevFormData) => ({
            ...prevFormData,
            installment: value,
            amount: monthData.amount,
            balance: monthData.balance, // Update balance here
          }));
        }
        console.log("data...", monthData);
        break;
      default:
        break;
    }
  };

  const handleAddReceipt = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);
    const formData = new FormData(e.target);
    const token = sessionStorage.getItem("token");
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }

    const formDataJSON = {
      user: loginId,
      group: groupId,
    }; // Initialize with user and group

    formData.forEach((value, key) => {
      formDataJSON[key] = value;
    });

    try {
      const response = await fetch(`${baseUrl}/add-receipt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataJSON),
      });

      console.log("Request Payload:", JSON.stringify(formDataJSON));

      if (response.status === 201) {
        fetchReceipts();
        e.target.reset();
        setAddMonth({
          amount: 0,
          paid_amount: 0,
        });
        setSelectedCustomer("");
        setSelectedPlot("");
        setPlotDetails({
          package_choice: "",
          no_Of_installment: "",
          amount_package: "",
        });

        setModalList(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  const handleEditReceipt = async (receipt) => {
    console.log(receipt);
    try {
      const response = await fetch(`${baseUrl}/add-receipt/${receipt.id}`);
      if (response.ok) {
        const data = await response.json();
        const editedReceiptData = { ...data };
        setEditReceipt(editedReceiptData);
        setModalEdit(true);
      } else {
        console.error(
          "Failed to fetch the plot for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the plot:", error);
    }
  };

  const handleSaveEdit = async () => {
    if (!editReceipt) return;

    const updatedPlotData = { ...editReceipt };
    try {
      const response = await fetch(
        `${baseUrl}/add-receipt/${editReceipt.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedPlotData),
        }
      );

      if (response.status === 200) {
        const updatedPlotData = await response.json();
        // console.log("plot updated:", updatedPlotData);
        setShowReceipt((prevPlot) =>
          prevPlot.map((plot) =>
            plot.id === updatedPlotData.id ? updatedPlotData : plot
          )
        );
        setModalEdit(false);
        setEditReceipt(null);
      } else {
        console.error("Failed to edit the plot:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the plot:", error);
    }
  };

  const handleDeletePlot = async (plot) => {
    if (plot) {
      try {
        const response = await fetch(`${baseUrl}/receipt/${plot.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("plot deleted:", plot.id);
          setShowReceipt((prevPlot) =>
            prevPlot.filter((c) => c.id !== plot.id)
          );
          setModalDelete(false);
          setShowAlertDelete(true);
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the plot:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the plot:", error);
      }
    }
  };
  // // Receipt Print

  const handlePrintReceipt = (receipt) => {
    navigate("/receipt/receipt-print/", {
      state: { receipt: receipt },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="Receipt" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader></CardHeader>
                <CardBody>
                  <div id="plotList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {hasPermission("add_receipt") && (
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => setModalList(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              Receipt
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchFunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ dimension: "50px" }}></th>
                            <th className="sort" data-sort="srNumber">
                              Sr #
                            </th>

                            <th className="sort" data-sort="date">
                              Date
                            </th>
                            <th className="sort" data-sort="customer">
                              Customer
                            </th>
                            <th className="sort" data-sort="plot">
                              Plot#
                            </th>

                            <th className="sort" data-sort="amount">
                              Amount
                            </th>

                            <th className="sort" data-sort="remarks">
                              Remarks
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ dimension: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {showReceipt.map((receipt, index) => (
                            <tr key={receipt.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>
                              <td className="entry_date">
                                {receipt.entry_date}
                              </td>
                              <td className="block">{receipt.customer}</td>
                              <td className="plot">{receipt.plot}</td>

                              <td className="paid_amount">
                                {receipt.paid_amount}
                              </td>
                              <td className="remarks">{receipt.remarks}</td>
                              <td>
                                <div className="d-flex gap-2">
                                  <Button
                                    color="primary"
                                    className="add-btn"
                                    onClick={() => handlePrintReceipt(receipt)}
                                  >
                                    <span className="mdi mdi-printer"></span>
                                  </Button>
                                  {hasPermission("change_receipt") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => {
                                          handleEditReceipt(receipt);
                                        }}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {hasPermission("delete_receipt") && (
                                    <Button
                                      className="btn btn-sm btn-danger edit-item-btn"
                                      onClick={() => {
                                        setPlotToDelete(receipt);
                                        setModalDelete(true); // Open the delete modal
                                      }}
                                      color="soft-danger"
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}

      <Modal
        isOpen={modalList}
        toggle={toggleList}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            toggleList();
          }}
        >
          Generate Receipt
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddReceipt}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-md-2">
                <label htmlFor="p_type" className="form-label">
                  Sr#
                </label>
                <input
                  type="number"
                  id="receipt_id"
                  name="receipt_id"
                  value={receiptNumber}
                  className="form-control"
                  placeholder="Receipt Number"
                  readOnly
                  required
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="p_type" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  id="entry_date"
                  name="entry_date"
                  className="form-control"
                  placeholder="Date"
                  required
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                />
              </div>

              <div className="col-sm-4">
                <label htmlFor="customer" className="form-label">
                  Customer
                </label>
                <select
                  className="form-select"
                  name="customer"
                  id="customer-field"
                  value={selectedCustomer}
                  required
                  onChange={(e) => handleCustomerSelect(e.target.value)}
                >
                  <option value="">Choose Customer</option>
                  {Customer.map((c) => (
                    <option key={c.id} value={c.id}>
                      {c.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="plot" className="form-label">
                  Plot#
                </label>
                <select
                  id="plot"
                  className="form-select"
                  name="plot"
                  onChange={(e) => {
                    setSelectedPlot(e.target.value);
                    fetchPlotDetails(e.target.value);
                    fetchPlotMonths(e.target.value);
                  }}
                  value={selectedPlot}
                >
                  <option value="">Choose Plot</option>
                  {getplots && getplots.length > 0 ? (
                    getplots.map((booking) => (
                      <option key={booking.plot.id} value={booking.plot.id}>
                        {booking.plot.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No plots available
                    </option>
                  )}
                </select>
              </div>
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Package
                </label>
                <input
                  type="text"
                  id="package_choice"
                  name="package"
                  className="form-control"
                  placeholder="Package"
                  value={plotDetails.package_choice || ""}
                  readOnly
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label htmlFor="size-field" className="form-label">
                  Installments
                </label>
                <input
                  type="text"
                  id="installment"
                  name="installment"
                  className="form-control"
                  placeholder="0"
                  value={plotDetails.no_Of_installment || ""}
                  readOnly
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="company" className="form-label">
                  Promise Date
                </label>
                <select
                  className="form-select"
                  name="installments"
                  id="installment"
                  required
                  onChange={InstallmentChangeData}
                  value={addMonth.installments}
                >
                  <option value="">Select Month</option>
                  {installmentData.map((installmentmonth) => {
                 

                    const color =
                      installmentmonth.balance === null
                        ? "red"
                        : installmentmonth.balance == 0
                        ? "green"
                        : installmentmonth.balance > 0
                        ? "pink"
                        : addMonth.amount < installmentmonth.balance
                        ? "grey"
                        : "inherit";


                    return (
                      <option
                        key={installmentmonth.id}
                        value={installmentmonth.id}
                        style={{ color }}
                      >
                        {installmentmonth.promise_date}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="col-sm-2">
                <label htmlFor="detail-field" className="form-label">
                  Total
                </label>
                <input
                  type="text"
                  id="amount"
                  name="amount"
                  className="form-control"
                  placeholder="0.00"
                  value={addMonth.amount}
                  onChange={(e) =>
                    setAddMonth({ ...addMonth, amount: e.target.value })
                  }
                />
              </div>

              <div className="col-sm-2">
                <label htmlFor="detail-field" className="form-label">
                  Paid
                </label>
                <input
                  type="text"
                  id="paid_amount"
                  name="paid_amount"
                  className="form-control"
                  placeholder="0.00"
                  value={addMonth.paid_amount}
                  onChange={(e) =>
                    setAddMonth({ ...addMonth, paid_amount: e.target.value })
                  }
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="detail-field" className="form-label">
                  Balance
                </label>
                <input
                  type="text"
                  id="balance"
                  name="balance"
                  className="form-control"
                  value={
                    addMonth.balance !== null ? addMonth.balance : "Not Paid"
                  }
                  onChange={(e) =>
                    setAddMonth({ ...addMonth, balance: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label htmlFor="detail-field" className="form-label">
                  Receipt#
                </label>
                <input
                  type="text"
                  id="receipt_no"
                  name="receipt_no"
                  className="form-control"
                />
              </div>

              <div className="col-sm-10">
                <label htmlFor="detail-field" className="form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  id="remarks"
                  name="remarks"
                  className="form-control"
                  placeholder="Enter Remarks"
                />
              </div>
              <div className="col-sm-3">
                <label htmlFor="detail-field" className="form-label"></label>
                <input
                  type="hidden"
                  id="balance"
                  name="balance"
                  className="form-control"
                  placeholder="0.00"
                  value={calculateTotal()}
                  readOnly
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={toggleList}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Add modal end  */}

      {/* Edit Modal start */}

      <Modal
        isOpen={modalEdit}
        toggle={() => {
          setModalEdit(!modalEdit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setModalEdit(!modalEdit);
          }}
        >
          Edit Receipt
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editReceipt && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="name-field" className="form-label">
                      Receipt#
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Plot size"
                      required
                      value={editReceipt.id}
                      onChange={(e) =>
                        setEditReceipt({
                          ...editReceipt,
                          id: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="customer" className="form-label">
                      Customer
                    </label>
                    <select
                      className="form-control"
                      name="customer"
                      id="customer"
                      value={editReceipt.customer}
                      onChange={(e) =>
                        setEditReceipt({
                          ...editReceipt,
                          customer: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Customer</option>
                      {Customer.map((c) => (
                        <option key={c.id} value={c.id}>
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="status-field" className="form-label">
                      Plot#
                    </label>
                    <select
                      className="form-control"
                      name="status"
                      id="status-field"
                      value={editReceipt.plot}
                      onChange={(e) =>
                        setEditReceipt({
                          ...editReceipt,
                          plot: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Plot</option>
                      {getplots.map((plot) => (
                        <option key={plot.plot_id} value={plot.plot_id}>
                          {plot.plot_id}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="credit-field" className="form-label">
                      Month
                    </label>
                    <select
                      className="form-control"
                      name="installment"
                      id="installment"
                      value={editReceipt.installment}
                      onChange={(e) =>
                        setEditReceipt({
                          ...editReceipt,
                          installment: e.target.value,
                        })
                      }
                    >
                      <option value="">Select Month</option>
                      {installmentData.map((installmentmonth) => (
                        <option
                          key={installmentmonth.id}
                          value={installmentmonth.id}
                        >
                          {installmentmonth.month}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="name-field" className="form-label">
                      Amount
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      name="amount"
                      className="form-control"
                      placeholder="Enter Amount"
                      required
                      value={editReceipt.amount}
                      onChange={(e) =>
                        setEditReceipt({
                          ...editReceipt,
                          amount: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="remarks" className="form-label">
                      Remarks
                    </label>
                    <input
                      type="text"
                      id="remarks"
                      name="remarks"
                      className="form-control"
                      placeholder="Edit Dimension"
                      required
                      value={editReceipt.remarks}
                      onChange={(e) =>
                        setEditReceipt({
                          ...editReceipt,
                          remarks: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3"></div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setModalEdit(false);
                  setEditReceipt(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modalDelete}
        toggle={() => {
          setModalDelete(!modalDelete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setModalDelete(!modalDelete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ dimension: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setModalDelete(!modalDelete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (plotToDelete) {
                  handleDeletePlot(plotToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Receipt Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Receipt;
