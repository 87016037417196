import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Plot = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [plot, setPlot] = useState([]);
  const [Status, setStatus] = useState([]);
  const [plotToDelete, setPlotToDelete] = useState(null);
  const [block, SetBlock] = useState([]);
  const [editplot, setEditplot] = useState(null);
  const [addCategory, setCategory] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);

  function tog_list() {
    setmodal_list(!modal_list);
  }


  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));
  

  
    permissionsArray.forEach((p) => {
    });
  
    return result;
  };


  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  const searchBlock = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    // Assuming the "Block" column is the third column (index 2)
    let blockColumnIndex = 1;

    for (var i = 0; i < tr.length; i++) {
      let found = false;
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true;

      let td = tds[blockColumnIndex];
      if (td) {
        let textValue = td.textContent || td.innerHTML;
        if (textValue.toUpperCase().indexOf(filter) > -1) {
          found = true;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = found ? "" : "none";
    }
  };

  //   Fetch data of Plot
  const fetchPlotData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      // Fetch the plot data and filter based on the current groupId
      const plotResponse = await fetch(`${baseUrl}/plot/`);
      if (plotResponse.ok) {
        const plotData = await plotResponse.json();
        const filteredPlotData = plotData.filter(
          (item) => item.group.toString() === groupId
        );
        setPlot(filteredPlotData);
      } else {
        console.error("Error fetching Plot:", plotResponse.statusText);
      }

      // Fetch the block data and filter based on the current groupId
      const blockResponse = await fetch(`${baseUrl}/block/`);
      if (blockResponse.ok) {
        const blockData = await blockResponse.json();
        const filteredBlockData = blockData.filter(
          (item) => item.group.toString() === groupId
        );
        SetBlock(filteredBlockData);
      } else {
        console.error("Error fetching Block:", blockResponse.statusText);
      }

      // Fetch the category data and filter based on the current groupId
      const categoryResponse = await fetch(`${baseUrl}/category/`);
      if (categoryResponse.ok) {
        const categoryData = await categoryResponse.json();
        const filteredCategoryData = categoryData.filter(
          (item) => item.group.toString() === groupId
        );
        setCategory(filteredCategoryData);
      } else {
        console.error("Error fetching Categorys:", categoryResponse.statusText);
      }

      // Fetch the status data (assuming it doesn't have a 'group' property)
      const statusResponse = await fetch(`${baseUrl}/status/`);
      if (statusResponse.ok) {
        const statusData = await statusResponse.json();
        setStatus(statusData);
      } else {
        console.error("Error fetching Status:", statusResponse.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchPlotData();
  }, []);

  // Function to get the customer name
  function getCategoryName(categoryID) {
    const category_name = addCategory.find(
      (category) => category.id === categoryID
    );
    return category_name ? category_name.name : "N/A";
  }

  // Function to get the customer name
  function getBlockName(blockID) {
    const block_name = block.find((block) => block.id === blockID);
    return block_name ? block_name.name : "N/A";
  }

  // Function to get the Status name
  function getStatusName(statusID) {
    const status_name = Status.find((status) => status.id === statusID);
    return status_name ? status_name.name : "N/A";
  }

  // Save data in database
  const handleAddPot = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);

    const formData = new FormData(e.target);
    const token = sessionStorage.getItem("token");
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }

    const formDataJSON = {
      user: loginId,
      group: groupId,
    }; // Initialize with user and group

    formData.forEach((value, key) => {
      formDataJSON[key] = value;
    });


    try {
      const response = await fetch(`${baseUrl}/addplot/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${loginId}`,
        },
        body: JSON.stringify(formDataJSON),
      });

      if (response.status === 201) {
        fetchPlotData();
        setmodal_list(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  // fetch data to edit plot

  const handleEditPlot = async (plot) => {
    try {
      const response = await fetch(`${baseUrl}/plot/${plot.id}`);
      if (response.ok) {
        const data = await response.json();

        // Map the fetched plot_status value to match form options
        const editedPlotData = { ...data };
        console.log("fetch the edit block data", editedPlotData);
        setEditplot(editedPlotData);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the plot for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the plot:", error);
    }
  };

  // fecth data to Save edit or update

  const handleSaveEdit = async () => {
    if (!editplot) return;

    // Map the plot_status value to match Django choices
    const updatedPlotData = { ...editplot };

    try {
      const response = await fetch(`${baseUrl}/addplot/${editplot.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedPlotData),
      });

      if (response.status === 200) {
        const UpdatedPlotData = await response.json();
        console.log("plot updated:", UpdatedPlotData);

        setPlot((prevplot) =>
          prevplot.map((plot) =>
            plot.id === UpdatedPlotData.id ? UpdatedPlotData : plot
          )
        );

        setmodal_edit(false);
        setEditplot(null);
      } else {
        console.error("Failed to edit the plot:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the plot:", error);
    }
  };

  // Delete plot

  const handleDeleteplot = async (plot) => {
    if (plot) {
      try {
        const response = await fetch(`${baseUrl}/plot/${plot.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("plot deleted:", plot.id);

          // Remove the deleted plot from the plot state
          setPlot((prevplot) => prevplot.filter((c) => c.id !== plot.id));

          setmodal_delete(false); // Close the delete modal
        } else {
          console.error("Failed to delete the plot:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the plot:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Plot" breadcrumbItem="Plot Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">plot</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="plotList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                        {hasPermission("add_plot") && (

                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => setmodal_list(true)}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Plot
                          </Button>
                        )}
                          <Col className="col-sm">
                            <div className="d-flex justify-content-sm-end">
                              <div className="search-box ms-2">
                                <input
                                  type="text"
                                  id="myInput"
                                  onKeyUp={searchBlock}
                                  className="form-control search"
                                  placeholder="Search Block..."
                                />
                                <i className="ri-search-line search-icon"></i>
                              </div>
                            </div>
                          </Col>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ dimension: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="name">
                              Block
                            </th>
                            <th className="sort" data-sort="size">
                              Category
                            </th>
                            <th className="sort" data-sort="business_type">
                              Plot Name
                            </th>
                            <th className="sort" data-sort="title">
                              Size
                            </th>
                            <th className="sort" data-sort="title">
                              Size Unit
                            </th>
                            <th className="sort" data-sort="title">
                              Dimension
                            </th>
                            <th className="sort" data-sort="title">
                              Detail
                            </th>
                            <th className="sort" data-sort="title">
                              Plot Status
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ dimension: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {plot.map((plot, index) => (
                            <tr key={plot.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="block">
                                {getBlockName(plot.block.id)}
                              </td>
                              <td className="category">
                                {getCategoryName(plot.category)}
                              </td>
                              <td className="name">{plot.name}</td>
                              <td className="size">{plot.size}</td>
                              <td className="size">{plot.size_unit}</td>

                              <td className="height">{plot.dimension}</td>
                              <td className="detail">{plot.detail}</td>
                              <td className="category">
                                {getStatusName(plot.plot_status)}
                              </td>

                              <td>
                                <div className="d-flex gap-2">
                        {hasPermission("change_plot") && (

                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() => {
                                        handleEditPlot(plot); // Open the delete modal
                                      }}
                                      color="soft-success"
                                    >
                                      <i className="ri-edit-2-line"></i>
                                    </button>
                                  </div>
                        )}
                        {hasPermission("delete_plot") && (

                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn"
                                    onClick={() => {
                                      setPlotToDelete(plot); // Set the plot to be deleted
                                      setmodal_delete(true); // Open the delete modal
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                        )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}

      <Modal
        isOpen={modal_list}
        toggle={tog_list}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          Add Plot
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddPot}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-3">
                <label htmlFor="block-field" className="form-label">
                  Block
                </label>
                <select className="form-control" name="block" id="block-field">
                  <option value="">Choose Block</option>
                  {block.map((block) => (
                    <option key={block.id} value={block.id}>
                      {block.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-3">
                <label htmlFor="category-field" className="form-label">
                  Category
                </label>
                <select
                  className="form-control"
                  name="category"
                  id="category-field"
                  required
                >
                  <option value="">Choose Category</option>
                  {addCategory.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Plot#
                </label>
                <input
                  type="text"
                  id="text-field"
                  name="name"
                  className="form-control"
                  placeholder="Plot#"
                  required
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="size-field" className="form-label">
                  Size Value
                </label>
                <input
                  type="text"
                  id="size"
                  name="size"
                  className="form-control"
                  placeholder="Size Value"
                  required
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="size_unit-field" className="form-label">
                  Size Unit
                </label>
                <input
                  type="text"
                  id="size_unit"
                  name="size_unit"
                  className="form-control"
                  placeholder="Size Unit"
                  required
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-sm-3">
                <label htmlFor="name-field" className="form-label">
                  Dimensions
                </label>
                <input
                  type="text"
                  id="text-field"
                  name="dimension"
                  className="form-control"
                  placeholder="Add Dimension"
                  required
                />
              </div>

              <div className="col-sm-7">
                <label htmlFor="detail-field" className="form-label">
                  Detail
                </label>
                <input
                  type="text"
                  id="detail"
                  name="detail"
                  className="form-control"
                  placeholder="Enter Detail"
                  required
                />
              </div>

              <div className="col-sm-2">
                <label htmlFor="status-field" className="form-label">
                  Status
                </label>
                <select
                  className="form-control"
                  name="plot_status"
                  id="plot-field"
                >
                  <option value="2">Choose</option>
                  {Status.map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Add modal end  */}

      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Plot
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editplot && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="block-field" className="form-label">
                      Block
                    </label>
                    <select
                      className="form-control"
                      name="block"
                      id="block"
                      value={editplot.block}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          block: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose block</option>
                      {block.map((block) => (
                        <option key={block.id} value={block.id}>
                          {block.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-4">
                    <label htmlFor="category-field" className="form-label">
                      category
                    </label>
                    <select
                      className="form-control"
                      name="category"
                      id="category-field"
                      value={editplot.category}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          category: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose category</option>
                      {addCategory.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="status-field" className="form-label">
                      Plot Status
                    </label>
                    <select
                      className="form-control"
                      name="status"
                      id="status-field"
                      value={editplot.plot_status}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          plot_status: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Status</option>
                      {Status.map((status) => (
                        <option key={status.id} value={status.id}>
                          {status.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-4">
                    <label htmlFor="credit-field" className="form-label">
                      Plot Number
                    </label>
                    <input
                      type="text"
                      id="credit-field"
                      className="form-control"
                      placeholder="Enter Plot Name"
                      required
                      value={editplot.name}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="name-field" className="form-label">
                      Plot Size
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Plot size"
                      required
                      value={editplot.size}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          size: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="size-field" className="form-label">
                      Dimensions
                    </label>
                    <input
                      type="text"
                      id="size-field"
                      className="form-control"
                      placeholder="Edit Dimension"
                      required
                      value={editplot.dimension}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          dimension: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-8">
                    <label htmlFor="phone-field" className="form-label">
                      Detail
                    </label>
                    <input
                      type="text"
                      id="phone-field"
                      className="form-control"
                      placeholder="Enter Detail"
                      required
                      value={editplot.detail}
                      onChange={(e) =>
                        setEditplot({
                          ...editplot,
                          detail: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="row mb-3"></div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditplot(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ dimension: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (plotToDelete) {
                  handleDeleteplot(plotToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Plot Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Plot;
