import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { env } from "../../config";
import saharalogo from "../../assets/images/saharalogo.png";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const TrialBalance = () => {
  const [transactions, setTransactions] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [username, setUsername] = useState("");
  const groupId = sessionStorage.getItem("groupId");
  const userApiKey = `${baseUrl}/users/`;
  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formattedDate = now.toLocaleDateString();
      const formattedTime = now.toLocaleTimeString();
      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateDateTime();

    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await fetch(`${baseUrl}/users/${groupId}/`, {
          method: "GET",
          headers: {
            Authorization: `Api-Key ${userApiKey}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUsername(userData.username);
        } else {
          console.error("Failed to fetch username:", response.status);
          // Handle error when fetching username
        }
      } catch (error) {
        console.error("Fetch username error:", error);
        // Handle fetch username error
      }
    };

    if (groupId) {
      fetchUsername();
    }
  }, [groupId, userApiKey]);

  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(`${baseUrl}/accounts/`);
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAccounts(filteredData);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchTransactionData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(`${baseUrl}/transactions/`);
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setTransactions(filteredData);
        console.log("transaction data:", filteredData);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
    fetchTransactionData();
  }, []);

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = accounts.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };

  //   // Print Function
  const navigate = useNavigate();
  const handlePrint = () => {
    // if () {
    //   navigate("/plot-report/plot-report-print", {
    //     state: {},
    //   });
    // }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className=""></CardHeader>
              <CardBody className="print-visible">
                <div id="customerList">
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div className="text-center">
                        <Link to="/">
                          <img
                            // src={saharalogo}
                            alt=""
                            height="80"
                            className="auth-logo logo-dark mx-auto"
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-5 print-visible">
                      <div style={{ textAlign: "center" }}>
                        <h3>
                          <strong>{username && <p>{username}</p>}</strong>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4 text-center print-visible">
                      <p>
                        <strong>Date:</strong> {currentDate}{" "}
                        <strong>Time:</strong> {currentTime}
                      </p>
                    </div>
                  </div>
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                    <div className="col-md-5 print-visible">
                      <p
                        style={{ textAlign: "center" }}
                        className="print-visible"
                      ></p>
                    </div>
                    <div className="col-md-1 print-visible"></div>
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                  </div>
                  <div className="table-responsive table-card mt-3">
                    <h5
                      className="p-2 text-center mt-4"
                      style={{ color: "white", background: "black" }}
                    >
                      Customer Trial Balance
                    </h5>
                  </div>
                  <table className="table align-middle table-nowrap" id="table">
                    <thead className="table-light">
                      <tr>
                        <th>Sr#</th>
                        <th>Customer Name</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((data, index) => (
                        <tr key={data.id}>
                          <td className="srNumber">{index + 1}</td>
                          <td className="customer_id">
                            {getCustomerName(data.account)}
                          </td>
                          <td>
                            {data.amount > 0
                              ? data.amount
                              : data.amount === 0
                              ? 0
                              : 0}
                          </td>
                          <td>
                            {data.amount < 0
                              ? -data.amount
                              : data.amount === 0
                              ? 0
                              : 0}
                          </td>
                          <td>
                            {data.amount === undefined || data.amount === null
                              ? 0
                              : data.amount}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colSpan={2}
                          style={{ textAlign: "right", color: "red" }}
                        >
                          <strong>Total Balance:</strong>
                        </td>
                        <td style={{ color: "red" }}>
                          {transactions
                            .filter((data) => data.amount > 0)
                            .reduce(
                              (total, data) => total + parseFloat(data.amount),
                              0
                            )
                            .toFixed(2)}
                        </td>
                        <td style={{ color: "red" }}>
                          {transactions
                            .filter((data) => data.amount < 0)
                            .reduce(
                              (total, data) => total - parseFloat(data.amount),
                              0
                            )
                            .toFixed(2)}
                        </td>
                        <td style={{ color: "red" }}>
                          {transactions
                            .reduce(
                              (total, data) => total + parseFloat(data.amount),
                              0
                            )
                            .toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrialBalance;
