import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./print.css";
import {Link, useLocation } from "react-router-dom";
import saharalogo from "../../assets/images/saharalogo.png";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const PrintReceipt = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [plots, setPlots] = useState([]);
  const [Receipt, setReceiptData] = useState([]);
  const location = useLocation();
  const [selectedPlotDetails, setSelectedPlotDetails] = useState({});
  const [username, setUsername] = useState("");
  const groupId = sessionStorage.getItem("groupId");
  const userApiKey = `${baseUrl}/users/`;
  useEffect(() => {
    const fetchPlots = async () => {
      try {
        const response = await fetch(`${baseUrl}/booking/`);
        const data = await response.json();
        console.log("API Response:", data);
        setPlots(data);
      } catch (error) {
        console.error("Error fetching plot data:", error);
      }
    };

    fetchPlots();
  }, []);

  // Function to fetch updated customer data
  const fetchCustomerData = async () => {
    try {
      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        return data; // Assuming the response contains customer data including image URLs
      } else {
        throw new Error("Failed to fetch customer data");
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
      return [];
    }
  };

  useEffect(() => {
    if (location.state && location.state.receipt) {
      setReceiptData(location.state.receipt);
      setDataFetched(true);
    } else {
      const id = location.pathname.split("/")[4];
      console.log("ID:", id);

      if (id) {
        // fetchReceiptData(id);
      } else {
        console.error("No receipt data and no ID provided.");
        setDataFetched(true);
      }
    }
  }, [location.state]);

  useEffect(() => {
    fetchCustomerData();
  }, []);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formattedDate = now.toLocaleDateString();
      const formattedTime = now.toLocaleTimeString();
      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  
  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await fetch(`${baseUrl}/users/${groupId}/`, {
          method: "GET",
          headers: {
            Authorization: `Api-Key ${userApiKey}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUsername(userData.username);
        } else {
          console.error("Failed to fetch username:", response.status);
          // Handle error when fetching username
        }
      } catch (error) {
        console.error("Fetch username error:", error);
        // Handle fetch username error
      }
    };

    if (groupId) {
      fetchUsername();
    }
  }, [groupId, userApiKey]);


  useEffect(() => {
    if (dataFetched) {
      window.print();
    }
  }, [dataFetched]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              {/* <CardHeader className="">
                <div className="d-flex">
                  <ReactHTMLTableToExcel
                    id="table-to-excel"
                    table="table"
                    className="btn btn-primary d-print-none"
                    filename="Installment Receipt"
                    sheet="sheet1"
                    buttonText="Export to Excel"
                  />
                </div>
              </CardHeader> */}
              <CardBody className="print-visible">
       
                <div id="customerList">
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                    <div className="text-center">
                      <Link to="/">
                        <img
                          // src={saharalogo}
                          alt=""
                          height="80"
                          className="auth-logo logo-dark mx-auto"
                        />
                     
                      </Link>
                    </div>
                    </div>
              
                    <div className="col-md-5 print-visible">
                    

                      <div style={{ textAlign: "center" }}>
                      <h3>
                          <strong> {username && <p>{username}</p>}</strong>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4 text-center print-visible">
                      <p>
                        <strong>Date:</strong> {currentDate}{" "}
                        <strong>Time:</strong> {currentTime}
                      </p>

                      <p>
                        {/* <strong>Contact Us:</strong> */}
                      </p>
                    </div>
                  </div>
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                    <div className="col-md-5 print-visible">
                      <div style={{ textAlign: "center" }}>
                        <h5
                          style={{
                            backgroundColor: "black",
                            padding: "5px",
                            color: "white",
                          }}
                        >
                          <strong>Installment Receipt</strong>
                        </h5>
                      </div>
                      <p
                        style={{ textAlign: "center" }}
                        className="print-visible"
                      ></p>
                    </div>
                    <div className="col-md-1 print-visible"></div>
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Receipt #:
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "30%",
                          }}
                        >
                          {Receipt.id || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Entry Date:
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "30%",
                          }}
                        >
                          {Receipt.entry_date || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {selectedPlotDetails && (
                        <div style={{ marginBottom: "10px" }}>
                          <strong style={{ display: "inline-block" }}>
                            Block :
                          </strong>
                          <p
                            style={{
                              borderBottom: "2px solid black",
                              display: "inline-block",
                              paddingLeft: "10px",
                              width: "70%",
                            }}
                          >
                            {Receipt.block}
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Promise Date:
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "63%",
                          }}
                        >
                          {Receipt.promise_date || "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Plot #:
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "70%",
                          }}
                        >
                          {Receipt.plot}
                        </p>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {selectedPlotDetails && (
                        <div style={{ marginBottom: "10px" }}>
                          <strong style={{ display: "inline-block" }}>
                            Plot Size:
                          </strong>
                          <p
                            style={{
                              borderBottom: "2px solid black",
                              display: "inline-block",
                              paddingLeft: "10px",
                              width: "70%",
                            }}
                          >
                            {Receipt.plot_size || "N/A"}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Customer:
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "66%",
                          }}
                        >
                          {Receipt.customer || ""}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Amount :
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "68%",
                          }}
                        >
                          {Receipt.amount}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div style={{ marginBottom: "10px" }}>
                        <strong style={{ display: "inline-block" }}>
                          Remarks :
                        </strong>
                        <p
                          style={{
                            borderBottom: "2px solid black",
                            display: "inline-block",
                            paddingLeft: "10px",
                            width: "70%",
                          }}
                        >
                          {Receipt.remarks}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrintReceipt;
