import React, { useState, useEffect } from "react";
import {
  // Import necessary components and styles
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";
// import { useNavigate } from "react-router-dom";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Accounts = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [selectedParent, setSelectedParent] = useState("");
  const [selectedChild, setSelectedChild] = useState("");

  const [subHeads, setSubHeads] = useState([]);
  const [selectedSubHead, setSelectedSubHead] = useState("");

  const [modal_delete, setmodal_delete] = useState(false);
  const [accounts, setaccounts] = useState([]);
  const [ChartAccount, setChartAccount] = useState([]);
  const [Account, setAccount] = useState([]);
  const [accountsToDelete, setaccountsToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [newaccounts, setNewaccounts] = useState({
    name: "",
    head: "",
    sub_head: "",
  });
  const [editaccounts, setEditaccounts] = useState(null);

  // const navigate = useNavigate();

  // useEffect(() => {
  //   const login = sessionStorage.getItem('login');

  //   if (!login) {
  //     navigate("/login");
  //   }
  // }, []);

  // Function to get the Head name
  function getHeadName(ChartAccountID) {
    const head_name = ChartAccount.find(
      (ChartAccount) => ChartAccount.id === ChartAccountID
    );
    return head_name ? head_name.name : "N/A";
  }

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const accountsResponse = await fetch(`${baseUrl}/accounts-management/`);
      if (accountsResponse.ok) {
        const accountsData = await accountsResponse.json();
        // Filter data based on the current groupId
        const filteredAccountsData = accountsData.filter(
          (item) => item.group.toString() === groupId
        );
        setAccount(filteredAccountsData);
      } else {
        console.error(
          "Error fetching accounts data:",
          accountsResponse.statusText
        );
      }

      const chartResponse = await fetch(`${baseUrl}/chart-of-accounts/`);
      if (chartResponse.ok) {
        const chartData = await chartResponse.json();
        // Filter data based on the current groupId
        const filteredChartData = chartData.filter(
          (item) => item.group.toString() === groupId
        );
        setChartAccount(chartData);
      } else {
        console.error("Error fetching chart data:", chartResponse.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Function to search the Table Data
  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }
        if (!found) {
          showRow = false;
        }
      }
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Function to handle adding a new accounts

  const handleAddaccounts = async (e) => {
    e.preventDefault();
    try {
      const token = sessionStorage.getItem("token");
      const groupId = sessionStorage.getItem("groupId");
      const loginId = sessionStorage.getItem("login");

      if (!loginId) {
        console.error("User not logged in.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts-management/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          group: groupId,
          user: loginId,
          head: selectedParent, // Use selectedParent as the head value
          sub_head: selectedChild, // Use selectedChild as the sub_head value
          name: newaccounts.name,
        }),
      });

      if (response.status === 201) {
        const data = await response.json();
        setmodal_list(false);
        fetchData();
        setNewaccounts({
          name: "",
          head: "",
          sub_head: "",
        });
      } else {
        const errorData = await response.json();
        console.error("Failed to add the account:", errorData);
      }
    } catch (error) {
      console.error("Error adding the account:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  useEffect(() => {
    let timeout;
    if (showAlert || successMessage) {
      timeout = setTimeout(() => {
        setShowAlert(false);
        setSuccessMessage(null);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert, successMessage]);

  // Function to handle editing a accounts
  const handleEditaccounts = (accounts) => {
    setEditaccounts(accounts);
    setmodal_edit(true);
  };

  // Function to save edited accounts
  const handleSaveEdit = async () => {
    if (!editaccounts) return;

    try {
      const response = await fetch(
        `${baseUrl}/accounts-management/${editaccounts.id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(editaccounts),
        }
      );

      if (response.status === 200) {
        const updatedAccount = await response.json();
        console.log("Account updated:", updatedAccount);

        const updatedAccounts = accounts.map((account) =>
          account.id === updatedAccount.id ? updatedAccount : account
        );

        setaccounts(updatedAccounts);
        setmodal_edit(false);
        setEditaccounts(null);
      } else {
        console.error("Failed to edit the account:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the account:", error);
    }
  };

  // Function to handle deleting a accounts
  const handleDeleteaccounts = async (accounts) => {
    if (accounts) {
      try {
        const response = await fetch(
          `${baseUrl}/accounts-management/${accounts.id}/`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 204) {
          console.log("accounts deleted:", accounts.id);

          // Remove the deleted accounts from the accounts state
          setaccounts((prevaccounts) =>
            prevaccounts.filter((r) => r.id !== accounts.id)
          );

          setmodal_delete(false); // Close the delete modal
          setShowAlertDelete(true);
          fetchData();

          // Show the alert after successful deletion
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the accounts:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the accounts:", error);
      }
    }
  };

  const handleParentChange = (e) => {
    const selectedParent = e.target.value;
    console.log("Selected Parent:", selectedParent);
    setSelectedParent(selectedParent);
    // Reset selected child when parent changes
    setSelectedChild("");
  };

  console.log("Chart Account:", ChartAccount);

  const handleChildChange = (e) => {
    setSelectedChild(e.target.value);
  };

  const handleaccountsChange = (e, field) => {
    const value = e.target.value;
    setNewaccounts((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Accounts" breadcrumbItem="Accounts Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">accounts</h1> */}
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4"></div>
                  </div>

                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => setmodal_list(true)}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Accounts
                          </Button>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>

                            <th className="sort" data-sort="head">
                              Head
                            </th>
                            <th className="sort" data-sort="sub_head">
                              Sub Head
                            </th>

                            <th className="sort" data-sort="name">
                              Name
                            </th>
                            <th
                              className="no-print"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {Account.map((accounts, index) => (
                            <tr key={accounts.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="head">
                                {getHeadName(accounts.head)}
                              </td>
                              <td className="head">
                                {getHeadName(accounts.sub_head)}
                              </td>
                              <td className="name">{accounts.name}</td>
                              <td className="">
                                <div className="d-flex gap-2">
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() =>
                                        handleEditaccounts(accounts)
                                      }
                                      color="soft-success"
                                    >
                                      <i className="ri-edit-2-line"></i>
                                    </button>
                                  </div>
                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn action-button"
                                    onClick={() => {
                                      setaccountsToDelete(accounts);
                                      setmodal_delete(true);
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          setmodal_list(!modal_list);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_list(!modal_list);
          }}
        >
          Add Accounts
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddaccounts}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-6">
                <label htmlFor="parent-field" className="form-label">
                  Head
                </label>
                <select
                  className="form-control"
                  name="head"
                  id="head"
                  value={selectedParent}
                  onChange={handleParentChange}
                >
                  <option value="0">Choose Head</option>
                  {ChartAccount.filter((account) => !account.parent_account) // Filter only parent accounts
                    .map((parent) => (
                      <option key={parent.id} value={parent.id}>
                        {parent.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-sm-6">
                <label htmlFor="child-field" className="form-label">
                  Sub Head
                </label>
                {console.log(
                  "Filtered Child Accounts:",
                  ChartAccount.filter(
                    (account) =>
                      account.parent_account === parseInt(selectedParent)
                  )
                )}
                <select
                  className="form-control"
                  name="sub_head"
                  id="sub_head"
                  value={selectedChild}
                  onChange={handleChildChange}
                  disabled={!selectedParent}
                >
                  <option value="0">Choose Sub Head</option>
                  {ChartAccount.filter(
                    (account) =>
                      account.parent_account === parseInt(selectedParent)
                  ).map((child) => (
                    <option key={child.id} value={child.id}>
                      {child.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label htmlFor="name-field" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="text-field"
                  className="form-control"
                  placeholder="Enter Name"
                  required
                  value={newaccounts.name}
                  onChange={(e) => handleaccountsChange(e, "name")}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Edit Modal */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Accounts
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editaccounts && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-6">
                    <label htmlFor="parent-field" className="form-label">
                      Head
                    </label>
                    <select
                      className="form-control"
                      name="head"
                      id="head"
                      value={editaccounts.head}
                      onChange={(e) =>
                        setEditaccounts({
                          ...editaccounts,
                          head: e.target.value,
                        })
                      }
                    >
                      <option value="0">Choose Parent</option>
                      {ChartAccount.filter(
                        (account) => !account.parent_account
                      ).map((parent) => (
                        <option key={parent.id} value={parent.id}>
                          {parent.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-6">
                    <label htmlFor="sub-head-field" className="form-label">
                      Sub Head
                    </label>
                    <select
                      className="form-control"
                      name="sub_head"
                      id="sub_head"
                      value={editaccounts.sub_head}
                      onChange={(e) =>
                        setEditaccounts({
                          ...editaccounts,
                          sub_head: e.target.value,
                        })
                      }
                      disabled={!editaccounts.head}
                    >
                      <option value="0">Choose Child</option>
                      {ChartAccount.filter(
                        (account) =>
                          account.parent_account === parseInt(editaccounts.head)
                      ).map((child) => (
                        <option key={child.id} value={child.id}>
                          {child.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-sm-6">
                    <label htmlFor="name-field" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editaccounts.name}
                      onChange={(e) =>
                        setEditaccounts({
                          ...editaccounts,
                          name: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditaccounts(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (accountsToDelete) {
                  handleDeleteaccounts(accountsToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Your other JSX components */}

      {/* Success Alert Modal */}
      {successMessage && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setSuccessMessage(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Saved</h4>
                    <p className="mb-0">accounts Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Accounts;
