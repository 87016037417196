import React, { useState, useEffect } from "react";
import {
  // Import necessary components and styles
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const UsersList = () => {
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [userlist, setUsersList] = useState([]);
  const [editUserlist, setEditUsersList] = useState([]);
  const [UserToDelete, setUsersListToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [allPermissions, setAllPermissions] = useState([]);
  const [selectedUserPermissions, setSelectedUserPermissions] = useState([]);

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    // console.log("Checking permission:", permission);
    // console.log("Permissions Array:", permissionsArray);
    // console.log("Is permission present?", result);

    permissionsArray.forEach((p) => {
      // console.log(`Permission in the array: ${p}`);
    });

    return result;
  };

  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/users/`);
      if (response.ok) {
        const data = await response.json();

        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group && item.group.toString() === groupId
        );

        setUsersList(data);
        console.log("filterd data");
      } else {
        console.error("Error fetching User List:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching User List:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  
  const fetchPermissions = async () => {
    try {
      const response = await fetch("http://127.0.0.1:8000/api/permissions/");
      if (response.ok) {
        const permissionsData = await response.json();
        setAllPermissions(permissionsData);
      } else {
        console.error("Error fetching permissions:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    fetchPermissions();
  }, []);



  // Function to search the Table Data
  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Function to handle editing a packages
  const handleEditUser = async (users) => {
    try {
      const response = await fetch(`${baseUrl}/users/${users.id}`);
      if (response.ok) {
        const data = await response.json();

        // Map the fetched plot_status value to match form options
        const editedUserData = { ...data };

        setEditUsersList(editedUserData);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the Packages for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the Packages:", error);
    }
  };

  // Function to save edited Category
  const handleSaveEdit = async () => {
    if (!editUserlist) return;

    try {
      const response = await fetch(`${baseUrl}/users/${editUserlist.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(editUserlist),
      });
      
      if (response.status === 200) {
        setmodal_edit(false);
        const updatedUser = await response.json();
        const updatedUsers = UsersList.map((users) =>
          users.id === updatedUser.id ? updatedUser : users
        );
        setUsersList(updatedUsers);
        setEditUsersList(null);
      } else {
        console.error("Failed to edit the User:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the User:", error);
    }
  };

  // Function to handle deleting a packages
  const handleDeleteUser = async (users) => {
    if (users) {
      try {
        const response = await fetch(`${baseUrl}/users/${users.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("packages deleted:", users.id);

          // Remove the deleted packages from the packagess state
          setUsersList((prevdata) => prevdata.filter((r) => r.id !== users.id));

          setmodal_delete(false); // Close the delete modal
          setShowAlertDelete(true);

          // Show the alert after successful deletion
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000); // Hide the alert after 5 seconds (adjust the duration as needed)
        } else {
          console.error("Failed to delete the packages:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the packages:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="Users List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4"></div>
                  </div>

                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr#
                            </th>

                            <th className="sort" data-sort="name">
                              First Name
                            </th>

                            <th className="sort" data-sort="name">
                              Last Name
                            </th>

                            <th className="sort" data-sort="name">
                              UserName
                            </th>

                            <th className="sort" data-sort="name">
                              Email
                            </th>

                            <th className="sort" data-sort="name">
                              Phone
                            </th>
                            <th
                              className="no-print"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {userlist.map((users, index) => (
                            <tr key={users.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>
                              <td className="first_name">{users.first_name}</td>
                              <td className="last_name">{users.last_name}</td>
                              <td className="username">{users.username}</td>
                              <td className="email">{users.email}</td>
                              <td className="phone">{users.phone}</td>
                              <td className="">
                                <div className="d-flex gap-2">
                                  {hasPermission("change_package") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => handleEditUser(users)}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {/* {hasPermission("delete_package") && ( */}
                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn action-button"
                                    onClick={() => {
                                      setUsersListToDelete(users);
                                      setmodal_delete(true);
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                                  {/* )}  */}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Edit Modal */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit Users
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editUserlist && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editUserlist.first_name}
                      onChange={(e) =>
                        setEditUsersList({
                          ...editUserlist,
                          first_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      Last Name
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editUserlist.last_name}
                      onChange={(e) =>
                        setEditUsersList({
                          ...editUserlist,
                          last_name: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      UserName
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editUserlist.username}
                      onChange={(e) =>
                        setEditUsersList({
                          ...editUserlist,
                          username: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter your current password"
                      required
                      value={editUserlist.password}
                      onChange={(e) =>
                        setEditUsersList({
                          ...editUserlist,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>

                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      id="text-field"
                      className="form-control"
                      placeholder="Enter Name"
                      required
                      value={editUserlist.email}
                      onChange={(e) =>
                        setEditUsersList({
                          ...editUserlist,
                          email: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-12">
  <label htmlFor="name-field" className="form-label">
    Permissions
  </label>
  <select
    multiple  // Add the multiple attribute to allow multiple selections
    className="form-select"
    id="permissions-field"
    value={editUserlist.user_permissions}
    onChange={(e) =>
      setEditUsersList({
        ...editUserlist,
        user_permissions: Array.from(e.target.selectedOptions, option => option.value)
      })
    }
  >
    <option value="">Select Permissions</option>
    {allPermissions.map((permission) => (
      <option key={permission.id} value={permission.id}>
        {permission.name}
      </option>
    ))}
  </select>
</div>

                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditUsersList(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                
                disabled={!hasPermission("change_category")}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (UserToDelete) {
                  handleDeleteUser(UserToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Your other JSX components */}

      {/* Success Alert Modal */}
      {successMessage && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setSuccessMessage(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Saved</h4>
                    <p className="mb-0">Category Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default UsersList;