import React, { useState, useEffect } from "react";
import { Link, useNavigate ,useLocation } from "react-router-dom";

// import './PrintPlotReport.css'
import { env } from "../../config";
import saharalogo from "../../assets/images/saharalogo.png";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
  } from "reactstrap";
const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const SalesReportPrint = () => {
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [addPlotData, setPlotData] = useState([]);
  const [setBooking] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Customer, setCustomer] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [error, setError] = useState(false);
  const [searchClicked, setSearchClicked] = useState(false);
  const { Booking } = location.state || {};
  const groupId = sessionStorage.getItem("groupId");
  const userApiKey = `${baseUrl}/users/`;




  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formattedDate = now.toLocaleDateString();
      const formattedTime = now.toLocaleTimeString();
      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateDateTime();

    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const response = await fetch(`${baseUrl}/users/${groupId}/`, {
          method: "GET",
          headers: {
            Authorization: `Api-Key ${userApiKey}`,
          },
        });

        if (response.ok) {
          const userData = await response.json();
          setUsername(userData.username);
        } else {
          console.error("Failed to fetch username:", response.status);
          // Handle error when fetching username
        }
      } catch (error) {
        console.error("Fetch username error:", error);
        // Handle fetch username error
      }
    };

    if (groupId) {
      fetchUsername();
    }
  }, [groupId, userApiKey]);


  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const fetchPlotData = await fetch(`${baseUrl}/plot/`);
      if (fetchPlotData.ok) {
        const data = await fetchPlotData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlotData(filteredData);
      } else {
        console.error("Error fetching data:", fetchPlotData.statusText);
      }
      const fetchCustomerData = await fetch(`${baseUrl}/accounts/`);
      if (fetchCustomerData.ok) {
        const data = await fetchCustomerData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomer(filteredData);
      } else {
        console.error("Error fetching data:", fetchCustomerData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // get Plot Name
  const getPlotName = (plotID) => {
    const plot = addPlotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // get Customer Name

  const getCustomerName = (customerID) => {
    const CustomerName = Customer.find(
      (customer) => customer.id === customerID
    );
    return CustomerName ? CustomerName.name : "N/A";
  };





  useEffect(() => {
    if (Booking.length > 0) {
      window.print();
    }
  }, [Booking]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className=""></CardHeader>
              <CardBody className="print-visible">
                <div id="customerList">
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div className="text-center">
                        <Link to="/">
                          <img
                            src={saharalogo}
                            alt=""
                            height="80"
                            className="auth-logo logo-dark mx-auto"
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-5 print-visible">
                      <div style={{ textAlign: "center" }}>
                        <h3>
                          <strong>{username && <p>{username}</p>}</strong>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4 text-center print-visible">
                      <p>
                        <strong>Date:</strong> {currentDate}{" "}
                        <strong>Time:</strong> {currentTime}
                      </p>
                    </div>
                  </div>
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                    <div className="col-md-5 print-visible">
                      <p
                        style={{ textAlign: "center" }}
                        className="print-visible"
                      ></p>
                    </div>
                    <div className="col-md-1 print-visible"></div>
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                  </div>
                  <div className="table-responsive table-card mt-3">
                    <h5
                      className="p-2 text-center mt-4"
                      style={{ color: "white", background: "black" }}
                    >
                      Sales Report
                    </h5>
                  </div>
                  <table
            className="table align-middle table-nowrap"
            id="customer-table"
          >
            <thead className="table-light">
              <tr>
                <th>Sr#</th>
                <th>Name</th>
                <th>CNIC</th>
                <th>Plot#</th>
                <th>SizeValue</th>
                <th>Total Amount</th>
                <th>Advance</th>
              </tr>
            </thead>

            <tbody>
              {Booking.map((booking, index) => (
                <tr key={index + 1}>
                  <td>{index + 1}</td>
                  <td className="customer_id">
                    {getCustomerName(booking.customer)}
                  </td>
                  <td>{booking.cnic}</td>
                  <td>{getPlotName(booking.plot)}</td>
                  <td>
                    {booking.size && booking.size + " " + booking.size_unit}
                  </td>
                  <td>{booking.total}</td>
                  <td>{booking.advance}</td>
                </tr>
              ))}
            </tbody>
          </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SalesReportPrint;
