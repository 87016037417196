import React, { useState, useEffect } from "react";
import {
  // Import necessary components and styles
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledAlert,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Packages = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [packagess, setpackages] = useState([]);
  const [packagesToDelete, setpackagesToDelete] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [newpackages, setNewpackages] = useState({
    name: "",
  });
  const [editpackages, setEditPackages] = useState(null);

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));
  

  
    permissionsArray.forEach((p) => {
    });
  
    return result;
  };



  // Fetch packagess data when the component mounts
  const fetchData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/packages/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setpackages(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };
  useEffect(() => {
  fetchData();
}, []);

  // Function to search the Table Data
  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false; // Initialize a flag to check if any match is found in this row
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true; // Assume the row should be shown by default

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Function to handle adding a new packages
  const handleAddpackages = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);

    const formData = new FormData(e.target);
    const token = sessionStorage.getItem("token");
    const groupId = sessionStorage.getItem("groupId");
    const loginId = sessionStorage.getItem("login");

    if (!loginId) {
      console.error("User not logged in.");
      // Handle the case where the user is not logged in
      return;
    }

    const formDataJSON = {
      user: loginId,
      group: groupId,
    };
    formData.forEach((value, key) => {
      formDataJSON[key] = value;
    });

    try {
      const response = await fetch(`${baseUrl}/packages/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formDataJSON),
      });

      if (response.status === 201) {
        const data = await response.json();
        // console.log("Category added:", data);
        setSuccessMessage("Category Added", data);
        setmodal_list(false);
        setEditPackages([...packagess, data]);
        setNewpackages({
          name: "",
        });

        setTimeout(() => {
          setSuccessMessage(false);
        }, 1000);
        fetchData();
      } else {
        // Handle other response statuses if needed
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  useEffect(() => {
    let timeout;
    if (showAlert || successMessage) {
      timeout = setTimeout(() => {
        setShowAlert(false);
        setSuccessMessage(null);
      }, 3000);
    }
    return () => clearTimeout(timeout); // Clear timeout on unmount or state change
  }, [showAlert, successMessage]);

  // Function to handle editing a packages
  const handleEditpackages = async (packages) => {
    try {
      const response = await fetch(`${baseUrl}/packages/${packages.id}`);
      if (response.ok) {
        const data = await response.json();

        // Map the fetched plot_status value to match form options
        const editedPackagesData = { ...data };

        setEditPackages(editedPackagesData);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the Packages for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the Packages:", error);
    }
  };

  // Function to save edited packages
  const handleSaveEdit = async () => {
    if (!editpackages) return;

    // Map the plot_status value to match Django choices
    const updatedPackagesData = { ...editpackages };

    try {
      const response = await fetch(`${baseUrl}/packages/${editpackages.id}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedPackagesData), // Use updatedPackagesData instead of editpackages
      });

      if (response.status === 200) {
        const updatedpackages = await response.json();
        console.log("packages updated:", updatedpackages);

        const updatedpackagess = packagess.map((packages) =>
          packages.id === updatedpackages.id ? updatedpackages : packages
        );

        setpackages(updatedpackagess);
        setmodal_edit(false);
        setEditPackages(null);
      } else {
        console.error("Failed to edit the packages:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the packages:", error);
    }
  };

  // Function to handle deleting a packages
  const handleDeletepackages = async (packages) => {
    if (packages) {
      try {
        const response = await fetch(`${baseUrl}/packages/${packages.id}/`, {
          method: "DELETE",
        });

        if (response.status === 204) {
          console.log("packages deleted:", packages.id);

          // Remove the deleted packages from the packagess state
          setpackages((prevpackagess) =>
            prevpackagess.filter((r) => r.id !== packages.id)
          );

          setmodal_delete(false); // Close the delete modal
          setShowAlertDelete(true);

          // Show the alert after successful deletion
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000); // Hide the alert after 5 seconds (adjust the duration as needed)
        } else {
          console.error("Failed to delete the packages:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the packages:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Packages" breadcrumbItem="Package Management" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">packages</h1> */}
                </CardHeader>
                <CardBody>
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4"></div>
                  </div>

                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                        {hasPermission("add_package") && (

                          <Button
                            color="success"
                            className="add-btn"
                            onClick={() => setmodal_list(true)}
                            id="create-btn"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Package
                          </Button>
                        )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              ID #
                            </th>

                            <th className="sort" data-sort="name">
                              Name
                            </th>

                            <th
                              className="no-print"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {packagess.map((packages, index) => (
                            <tr key={packages.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="name">
                                {packages.package_choice}
                              </td>
                              <td className="">
                                <div className="d-flex gap-2">
                                {hasPermission("change_package") && (
                                  <div className="edit">
                                    <button
                                      className="btn btn-sm btn-success edit-item-btn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#showModal"
                                      onClick={() =>
                                        handleEditpackages(packages)
                                      }
                                      color="soft-success"
                                    >
                                      <i className="ri-edit-2-line"></i>
                                    </button>
                                  </div>
                                )}
                                 {hasPermission("delete_package") && (
                                  <Button
                                    className="btn btn-sm btn-danger edit-item-btn action-button"
                                    onClick={() => {
                                      setpackagesToDelete(packages);
                                      setmodal_delete(true);
                                    }}
                                    color="soft-danger"
                                  >
                                    <i className="ri-delete-bin-2-line"></i>
                                  </Button>
                                 )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_list}
        toggle={() => {
          setmodal_list(!modal_list);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_list(!modal_list);
          }}
        >
          Add packages
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddpackages}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-12">
                <label htmlFor="package_choice-field" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  id="text-field"
                  name="package_choice"
                  className="form-control"
                  placeholder="Add package"
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => setmodal_list(false)}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={!hasPermission("add_package") || isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Edit Modal */}
      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit packages
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editpackages && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-12">
                    <label htmlFor="name-field" className="form-label">
                      Name
                    </label>
                    <input
                      className="form-control"
                      name="package_choice"
                      id="package_choice"
                      value={editpackages.package_choice}
                      onChange={(e) =>
                        setEditPackages({
                          ...editpackages,
                          package_choice: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditPackages(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>
      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (packagesToDelete) {
                  handleDeletepackages(packagesToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Your other JSX components */}

      {/* Success Alert Modal */}
      {successMessage && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setSuccessMessage(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Saved</h4>
                    <p className="mb-0">Package Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Data Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Packages;
