import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const FileTransfer = () => {
  const [modal_list, setmodal_list] = useState(false);
  const [modal_edit, setmodal_edit] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [showAlertDelete, setShowAlertDelete] = useState(false);
  const [plotData, setPlotData] = useState([]);
  const [CustomerData, setCustomerData] = useState([]);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [editFileTransfer, setEditFileTransfer] = useState(null);
  const [fileTransfer, setFileTransfer] = useState([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [getplots, setPlots] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedPlot, setSelectedPlot] = useState("");
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [addFileTransfer, setAddFileTransfer] = useState({
    transfer_date: "",
    from_customer: "",
    plot: "",
    to_customer: "",
    transfer_fee: "",
  });
  const [plotDetails, setPlotDetails] = useState({
    price: "",
    total: "",
    size: "",
    size_unit: "",
    advance: "",
    amount_package: "",
  });
  function tog_list() {
    setmodal_list(!modal_list);
  }
  // for data
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Fetch permissions from the session
  const userPermissions =
    JSON.parse(sessionStorage.getItem("permissions")) || [];
  // console.log("user psermissions:", userPermissions);
  const hasPermission = (permission) => {
    const permissionsArray = userPermissions?.permissions || [];
    const result = permissionsArray.some((p) => p.includes(permission));

    permissionsArray.forEach((p) => {});

    return result;
  };

  const fetchCustomerData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/accounts/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomerData(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };

  const fetchPlotData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/addplot/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        console.log("plot data:", filteredData);
        setPlotData(filteredData);
      } else {
        console.error("Error fetching Categorys:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching Categorys:", error);
    }
  };

  // Function to fetch data
  const fetchFileTransferData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      const response = await fetch(`${baseUrl}/file-transfer/`);
      if (response.ok) {
        const data = await response.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setFileTransfer(filteredData);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchCustomerData();
    setCurrentDate(getCurrentDate());
    fetchPlotData();
    fetchFileTransferData();
  }, []);

  const handleCustomerSelect = async (customerId) => {
    setSelectedCustomer(customerId);

    if (customerId) {
      try {
        const response = await fetch(
          `${baseUrl}/receipt-booking/?customer_id=${customerId}`
        );
        const data = await response.json();
        console.log("plot dataa:", data);
        if (data.message) {
          console.warn(data.message);
          setPlots([]);
        } else {
          // Assuming the response directly contains booking data
          setPlots(data);
        }
      } catch (error) {
        console.error("Error fetching plots:", error);
        setPlots([]);
      }
    } else {
      setPlots([]);
    }
  };
  const fetchPlotDetails = async (selectedBookingId) => {
    if (selectedBookingId) {
      try {
        // Reset plot details before making the new API call
        setPlotDetails({
          price: null,
          size: null,
          size_unit: null,
          advance: null,
          total: null,
          amount_package: null,
        });

        const response = await fetch(
          `${baseUrl}/booking/?plotId=${selectedBookingId}`
        );
        const data = await response.json();

        if (data.length > 0) {
          const bookingData = data.find(
            (booking) => booking.plot == selectedBookingId
          );
          if (bookingData) {
            setPlotDetails((prevDetails) => ({
              ...prevDetails,
              price: bookingData.price,
              total: bookingData.total,
              size: bookingData.size,
              size_unit: bookingData.size_unit,
              advance: bookingData.advance,
              amount_package: bookingData.amount_package,
            }));
          } else {
            console.log("No booking found for the selected plot");
          }
        } else {
          console.log(
            "No data or empty response received for the selected plot"
          );
        }
      } catch (error) {
        console.error("Error fetching plot details:", error);
      }
    }
  };

  useEffect(() => {
    fetchPlotDetails(selectedPlot);
  }, [selectedPlot]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddFileTransfer({ ...addFileTransfer, [name]: value });
  };

  const getCustomerName = (customerID) => {
    const customer = CustomerData.find(
      (customer) => customer.id === customerID
    );
    return customer ? customer.name : "N/A";
  };

  const getPlotName = (plotID) => {
    const plot = plotData.find((plot) => plot.id === plotID);
    return plot ? plot.name : "N/A";
  };

  // search Functionality

  const searchfunc = () => {
    let filter = document.getElementById("myInput").value.toUpperCase();
    let myTable = document.getElementById("myTable");
    let tr = myTable.getElementsByTagName("tr");

    for (var i = 0; i < tr.length; i++) {
      let found = false;
      let tds = tr[i].getElementsByTagName("td");
      let showRow = true;

      // Check if the search filter is not empty
      if (filter !== "") {
        for (var j = 0; j < tds.length; j++) {
          let td = tds[j];
          if (td) {
            let textValue = td.textContent || td.innerHTML;
            if (textValue.toUpperCase().indexOf(filter) > -1) {
              found = true; // Mark the row as found
              break; // No need to check other columns if a match is found
            }
          }
        }

        // If no match was found in the <td> elements, hide the row
        if (!found) {
          showRow = false;
        }
      }

      // Show or hide the entire row based on the showRow flag
      tr[i].style.display = showRow ? "" : "none";
    }
  };

  // Save data in database
  const handleAddFileTransfer = async (e) => {
    e.preventDefault();
    setIsAddButtonDisabled(true);

    const token = sessionStorage.getItem(`token`);
    const groupId = sessionStorage.getItem(`groupId`);
    const loginId = sessionStorage.getItem(`login`);

    if (!loginId) {
      console.error("User not logged in.");
      // Handle the case where the user is not logged in
      return;
    }

    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("transfer_date", currentDate);
    formData.append("from_customer", selectedCustomer);
    formData.append("plot", selectedPlot);
    formData.append("to_customer", addFileTransfer.to_customer);
    formData.append("transfer_fee", addFileTransfer.transfer_fee);

    try {
      const response = await fetch(`${baseUrl}/file-transfer/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        setFileTransfer([...fileTransfer, data]);
        setAddFileTransfer({
          transfer_date: "",
          from_customer: "",
          plot: "",
          to_customer: "",
          transfer_fee: "",
        });
        fetchFileTransferData();
        setmodal_list(false);
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000);
      } else {
        console.error("Failed to add the Customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsAddButtonDisabled(false);
    }
  };

  const handleEditFileTransfer = async (filetransfer) => {
    try {
      const response = await fetch(
        `${baseUrl}/file-transfer/${filetransfer.id}`
      );
      if (response.ok) {
        const data = await response.json();
        setEditFileTransfer(data);
        setmodal_edit(true);
      } else {
        console.error(
          "Failed to fetch the customer for editing:",
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error editing the customer:", error);
    }
  };
  //  Save edit or update

  const handleSaveEdit = async () => {
    if (!editFileTransfer) return;

    try {
      const token = sessionStorage.getItem("token");
      const groupId = sessionStorage.getItem("groupId");
      const loginId = sessionStorage.getItem("login");

      if (!groupId || !loginId) {
        console.error("Missing token, groupId, or loginId in session");
        return;
      }

      const formData = new FormData();
      formData.append("group", groupId);
      formData.append("user", loginId);
      formData.append("transfer_date", editFileTransfer.transfer_date);
      formData.append("from_customer", editFileTransfer.from_customer);
      formData.append("plot", editFileTransfer.plot);
      formData.append("to_customer", editFileTransfer.to_customer);
      formData.append("transfer_fee", editFileTransfer.transfer_fee);

      const response = await fetch(
        `${baseUrl}/file-transfer/${editFileTransfer.id}/`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.ok) {
        const updatedFileData = await response.json();
        console.log("Customer updated:", updatedFileData);

        setFileTransfer((prevFileTransfer) =>
          prevFileTransfer.map((filetransfer) =>
            filetransfer.id === updatedFileData.id
              ? updatedFileData
              : filetransfer
          )
        );

        setmodal_edit(false);
        setEditFileTransfer(null);
      } else {
        console.error("Failed to edit the Customer:", response.statusText);
      }
    } catch (error) {
      console.error("Error editing the Customer:", error);
    }
  };

  // Delete plot

  const handleDeleteData = async (filetransfer) => {
    if (filetransfer) {
      try {
        const response = await fetch(
          `${baseUrl}/file-transfer/${filetransfer.id}/`,
          {
            method: "DELETE",
          }
        );

        if (response.status === 204) {
          // Remove the deleted plot from the plot state
          setFileTransfer((prevFileTransfer) =>
            prevFileTransfer.filter((c) => c.id !== filetransfer.id)
          );

          setmodal_delete(false); // Close the delete modal
          setShowAlertDelete(true);
          setTimeout(() => {
            setShowAlertDelete(false);
          }, 1000);
        } else {
          console.error("Failed to delete the plot:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting the plot:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="File Transfer"
            breadcrumbItem="Add File Transfer"
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  {/* <h1 className="card-title mb-0">plot</h1> */}
                </CardHeader>
                <CardBody>
                  <div id="plotList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div className="d-flex gap-1">
                          {hasPermission("add_filetransfer") && (
                            <Button
                              color="success"
                              className="add-btn"
                              onClick={() => setmodal_list(true)}
                              id="create-btn"
                            >
                              <i className="ri-add-line align-bottom me-1"></i>{" "}
                              File Transfer
                            </Button>
                          )}
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              id="myInput"
                              onKeyUp={searchfunc}
                              className="form-control search"
                              placeholder="Search..."
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th scope="col" style={{ width: "50px" }}></th>
                            <th className="sort" data-sort="id">
                              Sr #
                            </th>
                            <th className="sort" data-sort="name">
                              Date
                            </th>
                            <th className="sort" data-sort="name">
                              Customer
                            </th>

                            <th className="sort" data-sort="business_type">
                              Plot Name
                            </th>
                            <th className="sort" data-sort="title">
                              Customer
                            </th>

                            <th
                              className="sort"
                              data-sort="action"
                              style={{ width: "50px" }}
                            >
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {fileTransfer.map((filetransfer, index) => (
                            <tr key={fileTransfer.id}>
                              <th scope="row"></th>
                              <td className="srNumber">{index + 1}</td>

                              <td className="block">
                                {filetransfer.transfer_date}
                              </td>

                              <td className="customer">
                                {getCustomerName(filetransfer.from_customer)}
                              </td>
                              <td className="plot">
                                {getPlotName(filetransfer.plot)}
                              </td>
                              <td className="customer">
                                {getCustomerName(filetransfer.to_customer)}
                              </td>

                              <td>
                                <div className="d-flex gap-2">
                                  {hasPermission("change_filedelivered") && (
                                    <div className="edit">
                                      <button
                                        className="btn btn-sm btn-success edit-item-btn"
                                        data-bs-toggle="modal"
                                        data-bs-target="#showModal"
                                        onClick={() => {
                                          handleEditFileTransfer(filetransfer);
                                        }}
                                        color="soft-success"
                                      >
                                        <i className="ri-edit-2-line"></i>
                                      </button>
                                    </div>
                                  )}
                                  {hasPermission("delete_filedelivered") && (
                                    <Button
                                      className="btn btn-sm btn-danger edit-item-btn"
                                      onClick={() => {
                                        setFileToDelete(filetransfer);
                                        setmodal_delete(true);
                                      }}
                                      color="soft-danger"
                                    >
                                      <i className="ri-delete-bin-2-line"></i>
                                    </Button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end">
                      <div className="pagination-wrap hstack gap-2">
                        <Link
                          className="page-item pagination-prev disabled"
                          to="#"
                        >
                          Previous
                        </Link>
                        <ul className="pagination listjs-pagination mb-0"></ul>
                        <Link className="page-item pagination-next" to="#">
                          Next
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}

      <Modal
        isOpen={modal_list}
        toggle={tog_list}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            tog_list();
          }}
        >
          File Transfer
        </ModalHeader>
        <form className="tablelist-form" onSubmit={handleAddFileTransfer}>
          <ModalBody>
            <div className="row mb-3">
              <div className="col-sm-3">
                <label htmlFor="name-field" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  id="text-field"
                  name="transfer_date"
                  className="form-control"
                  placeholder="Enter Date "
                  required
                  value={currentDate}
                  onChange={(e) => setCurrentDate(e.target.value)}
                />
              </div>
              <div className="col-md-3">
                <label htmlFor="customer" className="form-label">
                  Transfer From
                </label>
                <select
                  className="form-control"
                  name="from_customer"
                  id="customer"
                  value={selectedCustomer}
                  required
                  onChange={(e) => {
                    handleCustomerSelect(e.target.value);
                    handleInputChange(e);
                  }}
                >
                  <option value="">Choose Customer</option>
                  {CustomerData.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3">
                <label htmlFor="customer" className="form-label">
                  Plot
                </label>
                <select
                  id="plot"
                  className="form-control"
                  name="plot"
                  // onChange={(e) => setSelectedPlot(e.target.value)}
                  onChange={(e) => {
                    setSelectedPlot(e.target.value);
                    handleInputChange(e);
                  }}
                  value={selectedPlot}
                >
                  <option value="">Choose Plot</option>
                  {getplots && getplots.length > 0 ? (
                    getplots.map((booking) => (
                      <option key={booking.plot.id} value={booking.plot.id}>
                        {booking.plot.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No plots available
                    </option>
                  )}
                </select>
              </div>

              <div className="col-md-3">
                <label htmlFor="customer" className="form-label">
                  Transfer To
                </label>
                <select
                  className="form-control"
                  name="to_customer"
                  id="to_customer"
                  value={addFileTransfer.to_customer}
                  required
                  onChange={handleInputChange}
                >
                  <option value="">Choose Customer</option>
                  {CustomerData.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Transfer Fee
                </label>
                <input
                  type="text"
                  id="transfer_fee"
                  name="transfer_fee"
                  className="form-control"
                  placeholder="Transafer Fee"
                  value={addFileTransfer.transfer_fee}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Price/Marla
                </label>
                <input
                  type="text"
                  id="price"
                  name="price"
                  className="form-control"
                  placeholder="Price"
                  value={plotDetails.price || ""}
                  readOnly
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Amount/Package
                </label>
                <input
                  type="text"
                  id="amount_package"
                  name="amount_package"
                  className="form-control"
                  placeholder="Amount"
                  value={plotDetails.amount_package || ""}
                  readOnly
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Size
                </label>
                <input
                  type="text"
                  id="size"
                  name="size"
                  className="form-control"
                  placeholder="Size Marla"
                  value={`${plotDetails.size || ""} ${
                    plotDetails.size_unit || ""
                  }`}
                  readOnly
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Total
                </label>
                <input
                  type="text"
                  id="total"
                  name="total"
                  className="form-control"
                  placeholder="Total"
                  value={plotDetails.total || ""}
                  readOnly
                />
              </div>
            
              <div className="col-sm-2">
                <label htmlFor="name-field" className="form-label">
                  Advance
                </label>
                <input
                  type="text"
                  id="advance"
                  name="advance"
                  className="form-control"
                  placeholder="Advance"
                  value={plotDetails.advance || ""}
                  readOnly
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={tog_list}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn btn-success"
                id="add-btn"
                disabled={isAddButtonDisabled}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Add modal end  */}

      {/* Edit Modal start */}

      <Modal
        isOpen={modal_edit}
        toggle={() => {
          setmodal_edit(!modal_edit);
        }}
        centered
        className="modal-lg"
      >
        <ModalHeader
          className="bg-light p-3"
          id="exampleModalLabel"
          toggle={() => {
            setmodal_edit(!modal_edit);
          }}
        >
          Edit FileTransfer
        </ModalHeader>
        <form className="tablelist-form">
          <ModalBody>
            {editFileTransfer && (
              <>
                <div className="row mb-3">
                  <div className="col-sm-3">
                    <label htmlFor="credit-field" className="form-label">
                      Date
                    </label>
                    <input
                      type="text"
                      id="transfer_date"
                      name="transfer_date"
                      className="form-control"
                      placeholder="Enter Plot Name"
                      required
                      value={editFileTransfer.transfer_date}
                      onChange={(e) =>
                        setEditFileTransfer({
                          ...editFileTransfer,
                          transfer_date: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="from_customer-field" className="form-label">
                      Customer
                    </label>
                    <select
                      className="form-control"
                      name="to_customer"
                      id="to_customer"
                      value={editFileTransfer.from_customer}
                      onChange={(e) =>
                        setEditFileTransfer({
                          ...editFileTransfer,
                          to_customer: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Customer</option>
                      {CustomerData.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="from_customer-field" className="form-label">
                      Plot Name
                    </label>
                    <select
                      className="form-control"
                      name="plot"
                      id="plot"
                      value={editFileTransfer.plot}
                      onChange={(e) =>
                        setEditFileTransfer({
                          ...editFileTransfer,
                          plot: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Plot</option>
                      {plotData.map((plot) => (
                        <option key={plot.id} value={plot.id}>
                          {plot.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="from_customer-field" className="form-label">
                      Customer
                    </label>
                    <select
                      className="form-control"
                      name="to_customer"
                      id="to_customer"
                      value={editFileTransfer.to_customer}
                      onChange={(e) =>
                        setEditFileTransfer({
                          ...editFileTransfer,
                          to_customer: e.target.value,
                        })
                      }
                    >
                      <option value="">Choose Customer</option>
                      {CustomerData.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3"></div>
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setmodal_edit(false);
                  setEditFileTransfer(null);
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSaveEdit}
                id="edit-btn"
              >
                Save Changes
              </button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* Edit modal end */}

      {/* Remove Modal */}
      <Modal
        isOpen={modal_delete}
        toggle={() => {
          setmodal_delete(!modal_delete);
        }}
        centered
      >
        <div className="modal-header">
          <Button
            type="button"
            onClick={() => setmodal_delete(!modal_delete)}
            className="btn-close"
            aria-label="Close"
          ></Button>
        </div>
        <ModalBody>
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#f7b84b,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you Sure ?</h4>
              <p className="text-muted mx-4 mb-0">
                Are you Sure You want to Remove this Record ?
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-light"
              onClick={() => setmodal_delete(!modal_delete)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger"
              onClick={() => {
                if (fileToDelete) {
                  handleDeleteData(fileToDelete);
                }
              }}
              id="delete-record"
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Success Alert Modal */}
      {showSuccessModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Business Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Delte Modal here  */}

      {showAlertDelete && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-danger">
                  <h5 className="modal-title text-danger">Danger Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlertDelete(null)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                    </div>
                    <h4 className="alert-heading">Deleted</h4>
                    <p className="mb-0">Customer Deleted successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FileTransfer;
