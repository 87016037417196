import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Modal,
    ModalBody,
    ModalFooter,
    Row,
    ModalHeader,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { Link, useNavigate } from "react-router-dom";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;
const InvestorProfile = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_edit, setmodal_edit] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [investors, setinvestors] = useState([]);
    const [type, Settype] = useState([]);
    const [Customer, setCustomer] = useState([]);
    const [addinvestor, setAddinvestor] = useState([]);
    const [investorToDelete, setinvestorToDelete] = useState(null);
    const [EditInvestor, setEditInvestor] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showAlertDelete, setShowAlertDelete] = useState(false);
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
    const [Addinvestor, setAddNewinvestor] = useState({

        name: "",
        f_name: "",
        cnic: "",
        phone: "",
        whatsapp: "",
        city: "",
        email: "",
        gender: "",
        dob: "",
        image: null,
        document: null,
        address: "",
        type: "",
        total_investment: "",
        share: "",
        tenure: "",
        investment_date: "",
        remarks: "",
    });

    function tog_list() {
        setmodal_list(!modal_list);
    }



    //Add Category

    const fetchAccountData = async () => {
        try {
            const response = await fetch(
                `${baseUrl}/chart-of-accounts/`
            );
            if (response.ok) {
                const data = await response.json();
                Settype(data);
                console.log("Fetched data:", data);
            } else {
                console.error("Error fetching investor:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching investor:", error);
        }
    };


    // Fetch accounts data when the component mounts
    const fetchData = async () => {
        try {
            const response = await fetch(
                `${baseUrl}/accounts/`
            );
            if (response.ok) {
                const data = await response.json();
                setinvestors(data);
                console.log("Fetched data:", data);
            } else {
                console.error("Error fetching investor:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching investor:", error);
        }
    };
    // Fetch accounts data when the component mounts
    const fetchCustomerData = async () => {
        try {
            const response = await fetch(
                `${baseUrl}/accounts/`
            );
            if (response.ok) {
                const data = await response.json();
                setCustomer(data);
                console.log("Fetched data:", data);
            } else {
                console.error("Error fetching Customer:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching Customer:", error);
        }
    };

    useEffect(() => {
        fetchData();
        fetchAccountData();
        fetchCustomerData();
    }, []);



    function getInvestmentType(typeID) {
        const type_name = type.find((type) => type.id == typeID);
        console.log(type_name)
        return type_name ? type_name.name : "N/A";
    }
    const getCustomerName = (customerID) => {
        console.log("Customer", customerID);
        const customerName = Customer.find((customer) => customer.id === customerID);

        return customerName ? customerName.name : "N/A";
    };



    // search Functionality

    const searchfunc = () => {
        let filter = document.getElementById("myInput").value.toUpperCase();
        let myTable = document.getElementById("myTable");
        let tr = myTable.getElementsByTagName("tr");

        for (var i = 0; i < tr.length; i++) {
            let found = false; // Initialize a flag to check if any match is found in this row
            let tds = tr[i].getElementsByTagName("td");
            let showRow = true; // Assume the row should be shown by default

            // Check if the search filter is not empty
            if (filter !== "") {
                for (var j = 0; j < tds.length; j++) {
                    let td = tds[j];
                    if (td) {
                        let textValue = td.textContent || td.innerHTML;
                        if (textValue.toUpperCase().indexOf(filter) > -1) {
                            found = true; // Mark the row as found
                            break; // No need to check other columns if a match is found
                        }
                    }
                }

                // If no match was found in the <td> elements, hide the row
                if (!found) {
                    showRow = false;
                }
            }

            tr[i].style.display = showRow ? "" : "none";
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setAddNewinvestor({ ...Addinvestor, [name]: value });
    };



    // Save data in database
    const handleAddinvestor = async (e) => {
        e.preventDefault();
        setIsAddButtonDisabled(true);

        const token = sessionStorage.getItem("token");
        const groupId = sessionStorage.getItem(`groupId`);
        const loginId = sessionStorage.getItem(`login`);

        if (!loginId) {
            console.error("User not logged in.");
            return;
        }
        console.log("type:", Addinvestor.type);
        const formData = new FormData();
        formData.append("group", groupId);
        formData.append("user", loginId);
        formData.append("name", Addinvestor.name);
        formData.append("f_name", Addinvestor.f_name);
        formData.append("cnic", Addinvestor.cnic);
        formData.append("phone", Addinvestor.phone);
        formData.append("whatsapp", Addinvestor.whatsapp);
        formData.append("city", Addinvestor.city);
        formData.append("email", Addinvestor.email);
        formData.append("gender", Addinvestor.gender);
        formData.append("dob", Addinvestor.dob);
        formData.append("address", Addinvestor.address);
        formData.append("type", Addinvestor.type);
        formData.append("total_investment", Addinvestor.total_investment);
        formData.append("share", Addinvestor.share);
        formData.append("tenure", Addinvestor.tenure);
        formData.append("investment_date", Addinvestor.investment_date);
        formData.append("remarks", Addinvestor.remarks);

        if (Addinvestor.image !== null) {
            formData.append("image", Addinvestor.image);
        }
        if (Addinvestor.document !== null) {
            formData.append("document", Addinvestor.document);
        }


        try {
            const response = await fetch(`${baseUrl}/accounts/`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.status === 201) {
                const data = await response.json();
                setAddinvestor([...addinvestor, data]);
                setAddNewinvestor({
                    name: "",
                    f_name: "",
                    cnic: "",
                    phone: "",
                    whatsapp: "",
                    city: "",
                    email: "",
                    gender: "",
                    dob: "",
                    image: null,
                    document: null,
                    address: "",
                    type: "",
                    total_investment: "",
                    share: "",
                    tenure: "",
                    investment_date: "",
                    remarks: "",
                });
                setmodal_list(false);
                setShowSuccessModal(true);
                fetchData();
                setTimeout(() => {
                    setShowSuccessModal(false);
                }, 1000);
            } else {
                console.error("Failed to add the investor:", response.statusText);
            }
        } catch (error) {
            console.error("Error adding the investor:", error);
        } finally {
            setIsAddButtonDisabled(false);
        }
    };
    // fetch data to edit investor

    const handleEditInvestor = async (investor) => {
        console.log(investor)
        try {
            const response = await fetch(
                `${baseUrl}/accounts/${investor.id}`
            );
            if (response.ok) {
                const data = await response.json();


                setEditInvestor(data);
                setmodal_edit(true);
            } else {
                console.error(
                    "Failed to fetch the investor for editing:",
                    response.statusText
                );
            }
        } catch (error) {
            console.error("Error editing the investor:", error);
        }
    };

    // fecth data to Save edit or update
    const handleSaveEdit = async () => {
        if (!EditInvestor) return;

        const formData = new FormData();
        // Include login_id in the form data
        formData.append("login_id", EditInvestor.login_id);
        formData.append("name", EditInvestor.name);
        formData.append("f_name", EditInvestor.f_name);
        formData.append("cnic", EditInvestor.cnic);
        formData.append("phone", EditInvestor.phone);
        formData.append("whatsapp", EditInvestor.whatsapp);
        formData.append("city", EditInvestor.city);
        formData.append("email", EditInvestor.email);
        formData.append("dob", EditInvestor.dob);
        formData.append("gender", EditInvestor.gender);
        formData.append("address", EditInvestor.address);

        // Check if a new document is selected, if not, append the existing document
        if (EditInvestor.document instanceof File) {
            formData.append("document", EditInvestor.document);
        }

        // Check if a new image is selected, if not, append the existing image
        if (EditInvestor.image instanceof File) {
            formData.append("image", EditInvestor.image);
        }
        formData.append("type", EditInvestor.type);
        formData.append("total_investment", EditInvestor.total_investment);
        formData.append("share", EditInvestor.share);
        formData.append("tenure", EditInvestor.tenure);
        formData.append("investment_date", EditInvestor.investment_date);
        formData.append("remarks", EditInvestor.remarks);


        try {
            const response = await fetch(
                `${baseUrl}/accounts/${EditInvestor.id}/`,
                {
                    method: "PUT",
                    body: formData,
                }
            );

            if (response.ok) {
                const updatedinvestorData = await response.json();
                console.log("investor updated:", updatedinvestorData);

                setinvestors((previnvestors) =>
                    previnvestors.map((investor) =>
                        investor.id === updatedinvestorData.id
                            ? updatedinvestorData
                            : investor
                    )
                );

                setmodal_edit(false);
                setEditInvestor(null);
            } else {
                console.error("Failed to edit the investor:", response.statusText);
            }
        } catch (error) {
            console.error("Error editing the investor:", error);
        }
    };

    // Delete investor

    const handleDeleteinvestor = async (investor) => {
        if (investor) {
            try {
                const response = await fetch(
                    `${baseUrl}/accounts/${investor.id}/`,
                    {
                        method: "DELETE",
                    }
                );

                if (response.status === 204) {
                    console.log("investor Deleted:", investor.id);

                    // Remove the deleted investor from the investors state
                    setinvestors((previnvestor) =>
                        previnvestor.filter((c) => c.id !== investor.id)
                    );

                    setmodal_delete(false);
                    setShowAlertDelete(true);

                    setTimeout(() => {
                        setShowAlertDelete(false);
                    }, 1000);
                } else {
                    console.error("Failed to delete the investor:", response.statusText);
                }
            } catch (error) {
                console.error("Error deleting the investor:", error);
            }
        }
    };

    // Print Function
    const navigate = useNavigate();
    // const handlePrint = () => {
    //     // Pass the data to the Print investor list component
    //     navigate("/investor/investor-print/", { state: { data: investors } });
    // };



    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Investor Profile" breadcrumbItem=" Investor" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    {/* <h1 className="card-title mb-0">investor</h1> */}
                                </CardHeader>
                                <CardBody>
                                    <div id="investorList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button
                                                        color="success"
                                                        className="add-btn"
                                                        onClick={() => setmodal_list(true)}
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>{" "}
                                                        Investor
                                                    </Button>
                                                    {/* <Button
                                                        color="primary"
                                                        className="add-btn "
                                                        // onClick={handlePrint}
                                                    >
                                                        <span class="mdi mdi-printer"></span>
                                                    </Button> */}
                                                </div>
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2">
                                                        <input
                                                            type="text"
                                                            id="myInput"
                                                            onKeyUp={searchfunc}
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                        />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table
                                                className="table align-middle table-nowrap"
                                                id="table"
                                            >
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}></th>
                                                        <th className="sort" data-sort="id">
                                                            Sr#
                                                        </th>
                                                        <th className="sort" data-sort="code">
                                                            Name
                                                        </th>
                                                        <th className="sort" data-sort="code">
                                                            Phone#
                                                        </th>
                                                        <th className="sort" data-sort="code">
                                                            Cnic
                                                        </th>
                                                        <th className="sort" data-sort="code">
                                                            Type
                                                        </th>
                                                        <th className="sort" data-sort="name">
                                                            Total Investment
                                                        </th>
                                                        <th className="sort" data-sort="credi_limit">
                                                            Share
                                                        </th>
                                                        <th className="sort" data-sort="bill_limit">
                                                            Tenure
                                                        </th>
                                                        <th className="sort" data-sort="bill_limit">
                                                            Investment Date
                                                        </th>


                                                        <th
                                                            className="sort"
                                                            data-sort="action"
                                                            style={{ width: "50px" }}
                                                        >
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all" id="myTable">
                                                    {investors.map((investor, index) => (
                                                        // Check if total_investment is greater than 0
                                                        investor.total_investment > 0 ? (
                                                            <tr key={investor.id}>
                                                                <th scope="row"></th>
                                                                <td className="srNumber">{index + 1}</td>
                                                                <td className="name">{investor.name}</td>
                                                                <td className="phone">{investor.phone}</td>
                                                                <td className="share">{investor.cnic}</td>
                                                                <td className="block">{getInvestmentType(investor.type)}</td>
                                                                <td className="total_investment">{investor.total_investment}</td>
                                                                <td className="share">{investor.share}</td>
                                                                <td className="tenure">{investor.tenure}</td>
                                                                <td className="investment_date">{investor.investment_date}</td>
                                                                <td>
                                                                    <div className="d-flex gap-2">
                                                                        <div className="edit">
                                                                            <button
                                                                                className="btn btn-sm btn-success edit-item-btn"
                                                                                data-bs-toggle="modal"
                                                                                data-bs-target="#showModal"
                                                                                onClick={() => {
                                                                                    handleEditInvestor(investor); // Open the Edit modal
                                                                                }}
                                                                                color="soft-success"
                                                                            >
                                                                                <i className="ri-edit-2-line"></i>
                                                                            </button>
                                                                        </div>
                                                                        <Button
                                                                            className="btn btn-sm btn-danger edit-item-btn"
                                                                            onClick={() => {
                                                                                setinvestorToDelete(investor); // Set the investor to be deleted
                                                                                setmodal_delete(true); // Open the delete modal
                                                                            }}
                                                                            color="soft-danger"
                                                                        >
                                                                            <i className="ri-delete-bin-2-line"></i>
                                                                        </Button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ) : null
                                                    ))}
                                                </tbody>

                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link
                                                    className="page-item pagination-prev disabled"
                                                    to="#"
                                                >
                                                    Previous
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" to="#">
                                                    Next
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={4}>
                            <Card style={{ display: "none" }}>
                                <CardBody>
                                    <div id="users">
                                        <SimpleBar style={{ height: "242px" }} className="mx-n3">
                                            <ListGroup className="list mb-0" flush>
                                                <ListGroupItem data-id="4">
                                                    <div className="d-flex"></div>
                                                </ListGroupItem>
                                            </ListGroup>
                                        </SimpleBar>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* Add Modal */}

            <Modal
                isOpen={modal_list}
                toggle={tog_list}
                centered
                className="modal-lg"
            >
                <ModalHeader
                    className="bg-light p-3"
                    id="exampleModalLabel"
                    toggle={() => {
                        tog_list();
                    }}
                >
                    Add Investor
                </ModalHeader>
                <form
                    className="tablelist-form"
                    encType="multipart/form-data"
                    onSubmit={handleAddinvestor}
                >
                    <ModalBody>

                        <div className="row mb-3">
                            <div className="col-sm-4">
                                <label htmlFor="name-field" className="form-label">
                                    Investor Name
                                </label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className="form-control"
                                    placeholder="Enter Customer Name"

                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-sm-4">
                                <label htmlFor="f_name-field" className="form-label">
                                    Father Name
                                </label>
                                <input
                                    type="text"
                                    id="f_name"
                                    name="f_name"
                                    className="form-control"
                                    placeholder="Enter Father Name"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-4">
                                <label htmlFor="f_name-field" className="form-label">
                                    CNIC#
                                </label>
                                <input
                                    type="text"
                                    id="cnic"
                                    name="cnic"
                                    className="form-control"
                                    placeholder="Enter CNIC"

                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="f_name-field" className="form-label">
                                    Phone#
                                </label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    className="form-control"
                                    placeholder="Enter Phone"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <label htmlFor="whatsapp-field" className="form-label">
                                    Whatsapp
                                </label>
                                <input
                                    type="text"
                                    id="whatsapp"
                                    name="whatsapp"
                                    className="form-control"
                                    placeholder="Enter Whatsapp"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <label htmlFor="whatsapp-field" className="form-label">
                                    City
                                </label>
                                <input
                                    type="text"
                                    id="city"
                                    name="city"
                                    className="form-control"
                                    placeholder="Enter City"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <label htmlFor="name-field" className="form-label">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email-field"
                                    name="email"
                                    className="form-control"
                                    placeholder="Enter Email"

                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className="row mb-3">

                            <div className="col-sm-2">
                                <label htmlFor="gender-field" className="form-label">
                                    Gender
                                </label>
                                <select
                                    className="form-control"
                                    name="gender"
                                    id="gender-field"
                                    onChange={handleInputChange}
                                    value={Addinvestor.gender}
                                >
                                    <option value="">Select</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                    <option value="O">Other</option>
                                </select>
                            </div>
                            <div className="col-sm-3">
                                <label htmlFor="dob-field" className="form-label">
                                    DOB
                                </label>
                                <input
                                    type="date"
                                    id="dob"
                                    name="dob"
                                    className="form-control"
                                    placeholder="Enter Date Birth"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-7">
                                <label htmlFor="name-field" className="form-label">
                                    Address
                                </label>
                                <input
                                    type="text"
                                    id="address-field"
                                    name="address"
                                    className="form-control"
                                    placeholder="Enter Address"

                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>


                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <label htmlFor="type" className="form-label">
                                    Type
                                </label>
                                <select
                                    className="form-control"
                                    name="type"
                                    id="type-field"
                                    value={Addinvestor.type}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Choose Type</option>
                                    {type.map((types) => (
                                        <option key={types.id} value={types.id}>
                                            {types.name}
                                        </option>
                                    ))}
                                </select>

                            </div>

                            <div className="col-sm-3">
                                <label htmlFor="total_investment-field" className="form-label">
                                    Total Investment
                                </label>
                                <input
                                    type="text"
                                    id="total_investment"
                                    name="total_investment"
                                    className="form-control"
                                    placeholder="0.00"

                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="col-sm-3">
                                <label htmlFor="share-field" className="form-label">
                                    Share%
                                </label>
                                <input
                                    type="text"
                                    id="share"
                                    name="share"
                                    className="form-control"
                                    placeholder="0.00"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <label htmlFor="tenure-field" className="form-label">
                                    Tenure
                                </label>
                                <input
                                    type="text"
                                    id="tenure"
                                    name="tenure"
                                    className="form-control"
                                    placeholder=" Enter Tenure"

                                    onChange={handleInputChange}
                                />
                            </div>

                        </div>
                        <div className="row mb-3">

                            <div className="col-sm-3">
                                <label htmlFor="dob-field" className="form-label">
                                    Investment Date
                                </label>
                                <input
                                    type="date"
                                    id="investment_date"
                                    name="investment_date"
                                    className="form-control"
                                    placeholder="Enter Date Birth"

                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="col-sm-9">
                                <label htmlFor="remarks-field" className="form-label">
                                    Remarks
                                </label>
                                <input
                                    type="text"
                                    id="remarks-field"
                                    name="remarks"
                                    className="form-control"
                                    placeholder="Enter Remarks"

                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={tog_list}
                            >
                                Close
                            </button>
                            <button type="submit" className="btn btn-success" id="add-btn" disabled={isAddButtonDisabled}>
                                Save
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Add modal end  */}

            {/* Edit Modal start */}

            <Modal
                isOpen={modal_edit}
                toggle={() => {
                    setmodal_edit(!modal_edit);
                }}
                centered
                className="modal-lg"
            >
                <ModalHeader
                    className="bg-light p-3"
                    id="exampleModalLabel"
                    toggle={() => {
                        setmodal_edit(!modal_edit);
                    }}
                >
                    Edit Investor
                </ModalHeader>
                <form className="tablelist-form" encType="multipart/form-data">
                    <ModalBody>
                        {EditInvestor && (
                            <>

                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <label htmlFor="name-field" className="form-label">
                                            Investor Name
                                        </label>
                                        <input
                                            type="text"
                                            id="name-field"
                                            className="form-control"
                                            placeholder="Enter Customer"

                                            value={EditInvestor.name}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div className="col-sm-4">
                                        <label htmlFor="f_name-field" className="form-label">
                                            Father Name
                                        </label>
                                        <input
                                            type="text"
                                            id="f_name-field"
                                            className="form-control"
                                            placeholder="Enter Customer"

                                            value={EditInvestor.f_name}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    f_name: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="cnic-field" className="form-label">
                                            CNIC
                                        </label>
                                        <input
                                            type="text"
                                            id="cnic-field"
                                            className="form-control"
                                            placeholder="Enter Customer"

                                            value={EditInvestor.cnic}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    cnic: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <label htmlFor="cnic-field" className="form-label">
                                            Phone
                                        </label>
                                        <input
                                            type="text"
                                            id="cnic-field"
                                            className="form-control"
                                            placeholder="Enter Customer"

                                            value={EditInvestor.phone}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    phone: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                    <div className="col-sm-4">
                                        <label htmlFor="whatsapp-field" className="form-label">
                                            Whatsapp
                                        </label>
                                        <input
                                            type="text"
                                            id="whatsapp-field"
                                            className="form-control"
                                            placeholder="Enter Customer"

                                            value={EditInvestor.whatsapp}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    whatsapp: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="whatsapp-field" className="form-label">
                                            City
                                        </label>
                                        <input
                                            type="text"
                                            id="whatsapp-field"
                                            className="form-control"
                                            placeholder="Enter Customer"

                                            value={EditInvestor.city}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    city: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-4">
                                        <label htmlFor="whatsapp-field" className="form-label">
                                            Email
                                        </label>
                                        <input
                                            type="text"
                                            id="email-field"
                                            className="form-control"
                                            placeholder="Enter Email"

                                            value={EditInvestor.email}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="gender-field" className="form-label">
                                            Gender
                                        </label>
                                        <select
                                            className="form-control"
                                            name="gender"
                                            id="gender-field"
                                            value={EditInvestor.gender}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    gender: e.target.value, // Update the status value
                                                })
                                            }
                                        >
                                            <option value="">Choose Gender</option>
                                            <option value="M"> Male</option>
                                            <option value="F"> Female</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-4">
                                        <label htmlFor="dob-field" className="form-label">
                                            DOB
                                        </label>
                                        <input
                                            type="date"
                                            id="dob-field"
                                            className="form-control"
                                            placeholder="Enter Date "

                                            value={EditInvestor.dob}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    dob: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-sm-3">
                                        <label htmlFor="type-field" className="form-label">
                                            Type
                                        </label>
                                        <select
                                            className="form-control"
                                            name="type"
                                            id="type"
                                            value={EditInvestor.type}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    type: e.target.value,
                                                })
                                            }
                                        >
                                            <option value="">Choose Type</option>
                                            {investors.map((investor) => (
                                                <option key={investor.id} value={investor.id}>
                                                    {investor.type}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-sm-3">
                                        <label htmlFor="total_investment-field" className="form-label">
                                            Total Investment
                                        </label>
                                        <input
                                            type="text"
                                            id="total_investment-field"
                                            className="form-control"
                                            placeholder="0.00"

                                            value={EditInvestor.total_investment}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    total_investment: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label htmlFor="share-field" className="form-label">
                                            Share%
                                        </label>
                                        <input
                                            type="text"
                                            id="share-field"
                                            name="share"
                                            className="form-control"
                                            placeholder="0.00"

                                            value={EditInvestor.share}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    share: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-3">
                                        <label htmlFor="tenure-field" className="form-label">
                                            Tenure
                                        </label>
                                        <input
                                            type="text"
                                            id="tenure-field"
                                            className="form-control"
                                            placeholder="Enter Tenure"

                                            value={EditInvestor.tenure}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    tenure: e.target.value,
                                                })
                                            }
                                        />
                                    </div>


                                </div>
                                <div className="row mb-3">

                                    <div className="col-sm-3">
                                        <label htmlFor="dob-field" className="form-label">
                                            Investment Date
                                        </label>
                                        <input
                                            type="date"
                                            id="investment-field"
                                            className="form-control"
                                            value={EditInvestor.investment_date}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    investment_date: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-sm-9">
                                        <label htmlFor="remarks-field" className="form-label">
                                            Remarks
                                        </label>
                                        <input
                                            type="text"
                                            id="remarks-field"
                                            className="form-control"
                                            placeholder="Enter Remarks"

                                            value={EditInvestor.remarks}
                                            onChange={(e) =>
                                                setEditInvestor({
                                                    ...EditInvestor,
                                                    remarks: e.target.value,
                                                })
                                            }
                                        />
                                    </div>

                                </div>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                            <button
                                type="button"
                                className="btn btn-light"
                                onClick={() => {
                                    setmodal_edit(false);
                                    setEditInvestor(null);
                                }}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                className="btn btn-success"
                                onClick={handleSaveEdit}
                                id="edit-btn"
                            >
                                Save Changes
                            </button>
                        </div>
                    </ModalFooter>
                </form>
            </Modal>

            {/* Edit modal end */}

            {/* Remove Modal */}
            <Modal
                isOpen={modal_delete}
                toggle={() => {
                    setmodal_delete(!modal_delete);
                }}
                centered
            >
                <div className="modal-header">
                    <Button
                        type="button"
                        onClick={() => setmodal_delete(!modal_delete)}
                        className="btn-close"
                        aria-label="Close"
                    ></Button>
                </div>
                <ModalBody>
                    <div className="mt-2 text-center">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="loop"
                            colors="primary:#f7b84b,secondary:#f06548"
                            style={{ width: "100px", height: "100px" }}
                        ></lord-icon>
                        <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                            <h4>Are you Sure ?</h4>
                            <p className="text-muted mx-4 mb-0">
                                Are you Sure You want to Remove this Record ?
                            </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button
                            type="button"
                            className="btn w-sm btn-light"
                            onClick={() => setmodal_delete(!modal_delete)}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn w-sm btn-danger"
                            onClick={() => {
                                if (investorToDelete) {
                                    handleDeleteinvestor(investorToDelete);
                                }
                            }}
                            id="delete-record"
                        >
                            Yes, Delete It!
                        </button>
                    </div>
                </ModalBody>
            </Modal>

            {/* Success Alert Modal */}
            {showSuccessModal && (
                <div className="modal-backdrop" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}>
                    <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-soft-success">
                                    <h5 className="modal-title text-success">Success Alert</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={() => setShowSuccessModal(false)}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="mb-4">
                                            <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                                        </div>
                                        <h4 className="alert-heading">Well done!</h4>
                                        <p className="mb-0">Data Added successfully</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* Delte Modal here  */}

            {/* Danger Alert Modal */}
            {showAlertDelete && (
                <div className="modal-backdrop" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}>
                    <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block" }}>
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <div className="modal-header bg-soft-danger">
                                    <h5 className="modal-title text-danger">Danger Alert</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={() => setShowAlertDelete(false)}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="mb-4">
                                            <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-danger"></i>
                                        </div>
                                        <h4 className="alert-heading">Deleted</h4>
                                        <p className="mb-0">Data Deleted successfully</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </React.Fragment>
    );
};
export default InvestorProfile;
