import React, { useEffect, useState } from "react"; // Add useState
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios"; // Import axios
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import logolight from "../../assets/images/saharalogo.png";
import logodark from "../../assets/images/saharalogo.png";
import { registerUser, apiError } from "../../store/actions";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Register = (props) => {
  document.title = "Register | Allied Tajar (ERP)";

  const dispatch = useDispatch();
  const [registrationMessage, setRegistrationMessage] = useState(""); // Define registrationMessage

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
      password: "",
      // password2: "",
      business_name: "",
      phone_no: "",
      address: "",
      city: "",
      contact_no: "",
      name: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      // password2: Yup.string().required("Please Confirm Your Password"),
      business_name: Yup.string().required("Please Enter Business Name"),
      phone_no: Yup.string().required("Please Enter Phone Number"),
      address: Yup.string().required("Please Enter Address"),
      city: Yup.string().required("Please Enter City"),
      contact_no: Yup.string().required("Please Enter Contact Number"),
      name: Yup.string().required("Please Enter Customer Name"),
     
    }),
    
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${baseUrl}/register/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });

        if (response.ok) {
          const responseData = await response.json();
          setRegistrationMessage("Register User Successfully");
          dispatch(registerUser(values));
        } else {
          const errorData = await response.json();
          setRegistrationMessage("Registration Error: " + errorData.detail);
          dispatch(apiError(errorData.detail));
        }
      } catch (error) {
        setRegistrationMessage("An unknown error occurred.");
        dispatch(apiError("An unknown error occurred."));
      }
    },
  });

  const { user, registrationError } = useSelector((state) => ({
    user: state.account.user,
    registrationError: state.account.registrationError,
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  return (
    <div className="bg-pattern" style={{ height: "100vh" }}>
      <div className="bg-overlay"></div>
      <div className="account-pages pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={10} xl={8}>
              <Card className="mt-5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <Link to="" className="">
                      {/* <img src={logodark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                      <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" /> */}
                    </Link>
                  </div>

                  <h4 className="font-size-18 text-muted text-center mt-2">
                    Register
                  </h4>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {user && user ? (
                      <Alert color="success">{registrationMessage}</Alert>
                    ) : null}

                    {registrationError && registrationError ? (
                      <Alert color="danger">
                        <div>{registrationError}</div>
                      </Alert>
                    ) : null}

                    <Row>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Email</Label>
                          <Input
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.email}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Username</Label>
                          <Input
                            name="username"
                            type="text"
                            placeholder="Enter username"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.username || ""}
                            invalid={
                              validation.touched.username &&
                              validation.errors.username
                                ? true
                                : false
                            }
                          />
                          {validation.touched.username &&
                          validation.errors.username ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.username}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Confirm Password</Label>
                          <Input
                            name="password2"
                            type="password"
                            placeholder="Confirm your  Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password2 || ""}
                            invalid={
                              validation.touched.password2 &&
                              validation.errors.password2
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password2 &&
                          validation.errors.password2 ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password2}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Customer Name</Label>
                          <Input
                            name="name"
                            type="text"
                            placeholder="Enter Customer Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                              validation.touched.name && validation.errors.name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.name && validation.errors.name ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Business Name</Label>
                          <Input
                            name="business_name"
                            type="text"
                            placeholder="Enter Business Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.business_name || ""}
                            invalid={
                              validation.touched.business_name &&
                              validation.errors.business_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.business_name &&
                          validation.errors.business_name ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.business_name}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Phone Number</Label>
                          <Input
                            name="phone_no"
                            type="text"
                            placeholder="Enter Phone Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.phone_no || ""}
                            invalid={
                              validation.touched.phone_no &&
                              validation.errors.phone_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.phone_no &&
                          validation.errors.phone_no ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.phone_no}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Address</Label>
                          <Input
                            name="address"
                            type="text"
                            placeholder="Enter Address"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.address || ""}
                            invalid={
                              validation.touched.address &&
                              validation.errors.address
                                ? true
                                : false
                            }
                          />
                          {validation.touched.address &&
                          validation.errors.address ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.address}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">City</Label>
                          <Input
                            name="city"
                            type="text"
                            placeholder="Enter City"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.city || ""}
                            invalid={
                              validation.touched.city && validation.errors.city
                                ? true
                                : false
                            }
                          />
                          {validation.touched.city && validation.errors.city ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.city}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-4">
                          <Label className="form-label">Contact Number</Label>
                          <Input
                            name="contact_no"
                            type="text"
                            placeholder="Enter Contact Number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.contact_no || ""}
                            invalid={
                              validation.touched.contact_no &&
                              validation.errors.contact_no
                                ? true
                                : false
                            }
                          />
                          {validation.touched.contact_no &&
                          validation.errors.contact_no ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.contact_no}</div>
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    <Row>
                   
                    </Row>

                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="term-conditionCheck"
                      />
                      <label
                        className="form-check-label fw-normal"
                        htmlFor="term-conditionCheck"
                      >
                        I accept{" "}
                        <Link to="#" className="text-primary">
                          Terms and Conditions
                        </Link>
                      </label>
                    </div>
                    <div className="d-grid mt-4">
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Register
                      </button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">
                  Already have an account ?
                  <Link to="/login" className="fw-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p className="text-white-50">
                  © {new Date().getFullYear()} Upzet. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Themesdesign
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Register;
