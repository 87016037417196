import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import SimpleBar from "simplebar-react";
import { env } from "../../config";
import { Link, useNavigate } from "react-router-dom";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;
const Booking = () => {
  const [modal_list, setModalList] = useState(false);
  const [addBlockData, setBlockData] = useState([]);
  const [AddBookingData, setBookingData] = useState([]);
  const [addPlotData, setPlotData] = useState([]);
  const [addPackageData, setPackageData] = useState([]);
  const [addAgentData, setAgentData] = useState([]);
  const [BookingNumber, setBookingNumber] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [installments, setInstallments] = useState([]);
  const [InstallmentModal, setInstallmentModal] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showAnotherModal, setShowAnotherModal] = useState(false);
  const [printButtonDisabled, setPrintButtonDisabled] = useState(true);
  const [isBookingButtonDisabled, setIsBookingButtonDisabled] = useState(true);
  const [isGenerateButtonDisabled, setIsGenerateButtonDisabled] = useState(false);
  const [bookings, setbookings] = useState([]);
  const [Customer, setCustomer] = useState([]);
  const [lastSavedData, setLastSavedData] = useState(null);

  const [AddBooking, setAddNewBooking] = useState({
    type: "",
    code: "",
    receipt_no: "",
    hs_code: "",
    company_id: "",
    group_id: "",
    sub_group_id: "",
    name: "",
    unit_id: "",
    advance: "",
    no_Of_installment: "",
    plot: "unsold",
    package_choice: "quarterly",
    size: "",
    size_unit: "",
    dimension: "",
    price: "",
    advance: "",
    total: "",
    document: null,
  });

  function tog_list() {
    setModalList(!modal_list);
  }

  const handleAnotherModalButtonClick = () => {
    setIsBookingButtonDisabled(true);
    setShowAnotherModal(true);
  };

  // Print Function
  const navigate = useNavigate();
  const handlePrintModalButtonClick = () => {
    // Pass the data and booking number to the PrintChannel component
    navigate("/booking/single-booking-print/", {
      state: { data: bookings, bookingNumber: BookingNumber },
    });
  };

  const fetchBookingData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");

      console.log("Group Id Data ", groupId);

      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }

      // Fetch all booking data
      const response = await fetch(`${baseUrl}/booking/`);
      if (response.ok) {
        const data = await response.json();
        setbookings(data);

        // Find the last inserted record based on the max id
        const maxId = Math.max(...data.map((item) => item.id));
        const lastInsertedRecord = data.find((item) => item.id === maxId);

        // Set the last inserted record as the lastSavedData state
        setLastSavedData(lastInsertedRecord);
      } else {
        console.error("Error fetching bookings:", response.statusText);
      }

      const agentdata = await fetch(`${baseUrl}/agent/`);
      if (agentdata.ok) {
        const data = await agentdata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setAgentData(filteredData);
      } else {
        console.error("Error fetching agent data:", agentdata.statusText);
      }

      const packagedata = await fetch(`${baseUrl}/packages/`);
      if (packagedata.ok) {
        const data = await packagedata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPackageData(filteredData);
      } else {
        console.error("Error fetching package data:", packagedata.statusText);
      }

      const plotdata = await fetch(`${baseUrl}/plot/`);
      if (plotdata.ok) {
        const data = await plotdata.json();

        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setPlotData(filteredData);
      } else {
        console.error("Error fetching plot data:", plotdata.statusText);
      }

      const blockdata = await fetch(`${baseUrl}/block/`);
      if (blockdata.ok) {
        const data = await blockdata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setBlockData(filteredData);
      } else {
        console.error("Error fetching block data:", blockdata.statusText);
      }

      const customerdata = await fetch(`${baseUrl}/accounts/`);
      if (customerdata.ok) {
        const data = await customerdata.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setCustomer(filteredData);
      } else {
        console.error("Error fetching customer data:", customerdata.statusText);
      }

      const InstallmentsData = await fetch(`${baseUrl}/installment-data/`);
      if (InstallmentsData.ok) {
        const data = await InstallmentsData.json();
        // Filter data based on the current groupId
        const filteredData = data.filter(
          (item) => item.group.toString() === groupId
        );
        setInstallmentModal(filteredData);
      } else {
        console.error(
          "Error fetching customer data:",
          InstallmentsData.statusText
        );
      }

      const CustomerData = await fetch(`${baseUrl}/accounts/`);
      if (CustomerData.ok) {
        const Customer = await CustomerData.json();
        const filteredCustomerData = Customer.filter(
          (item) => item.group.toString() === groupId
        );
        setBookingData(filteredCustomerData);
      } else {
        console.error("Error fetching Plot:", CustomerData.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchBookingData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
  
    setAddNewBooking((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  
    // Handle the agent field separately
    if (name === 'agent') {
      // If the user didn't choose any option, set the value to 0
      const agentValue = value === '0' ? 0 : value;
  
      setAddNewBooking((prevFormData) => ({
        ...prevFormData,
        [name]: agentValue,
      }));
    } else {
      // For other fields, update the state as usual
      setAddNewBooking((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
  
      // Calculate other fields as needed
      const size = parseFloat(name === 'size' ? value : AddBooking.size) || 0;
      const price = parseFloat(name === 'price' ? value : AddBooking.price) || 0;
      const advance =
        parseFloat(name === 'advance' ? value : AddBooking.advance) || 0;
      const commissionPercent =
        parseFloat(
          name === 'commission_percent' ? value : AddBooking.commission_percent
        ) || 0;
  
      const total = size * price;
      const balance = total - advance;
      const commissionAmount = (balance * commissionPercent) / 100;
  
      setAddNewBooking((prevFormData) => ({
        ...prevFormData,
        total: total.toFixed(2),
        balance: balance.toFixed(2),
        commission_amount: commissionAmount.toFixed(2),
      }));
    }
  };
  
  const handleInputChangeInstallment = (e) => {
    const { name, value } = e.target;

    setAddNewBooking((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const balanceInstallment = parseFloat(AddBooking.balance) || 0;
    const no_Of_installment = parseFloat(value) || 0;

    const InstallmentAmount =
      no_Of_installment !== 0 ? balanceInstallment / no_Of_installment : 0;

    setAddNewBooking((prevFormData) => ({
      ...prevFormData,
      amount_package: InstallmentAmount.toFixed(2),
      no_Of_installment: value, // Update to the input value
    }));
  };

  const handleInputChangeInstallmentAmount = (e) => {
    const { name, value } = e.target;

    setAddNewBooking((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    const balanceInstallment = parseFloat(AddBooking.balance) || 0;
    const amount_package = parseFloat(value) || 0;

    const InstallmentAmount =
      amount_package !== 0 ? balanceInstallment / amount_package : 0;

    setAddNewBooking((prevFormData) => ({
      ...prevFormData,
      no_Of_installment: InstallmentAmount.toFixed(2),
      amount_package: value, // Update to the input value
    }));
  };

  const generateInstallments = () => {
    const { no_Of_installment, amount_package, promise_date } = AddBooking;
    const newInstallments = [];

    // Convert promise_date to Date object
    const promiseDate = new Date(promise_date);

    for (let i = 1; i <= parseInt(no_Of_installment, 10); i++) {
      // Calculate the next month
      const nextMonth = new Date(promiseDate);
      nextMonth.setMonth(promiseDate.getMonth() + i);

      const formattedMonth = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(nextMonth);

      newInstallments.push({
        id: i,
        promise_date: formattedMonth,
        amount: parseFloat(amount_package).toFixed(2),
      });
    }

    setInstallments(newInstallments);
  };

  const getTotalAmount = () => {
    return installments
      .reduce((total, installment) => total + parseFloat(installment.amount), 0)
      .toFixed(2);
  };


  const handleGenerateAndAdd = () => {
    setIsGenerateButtonDisabled(true);
    setIsBookingButtonDisabled(false);
    generateInstallments();
  };

  const handleInputChangeCustomer = async (e) => {
    const { name, value } = e.target;

    const fetchCustomerDetails = async (customerId) => {
      try {
        const response = await fetch(`${baseUrl}/accounts/${customerId}`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    };

    switch (name) {
      case "customer":
        const customerDetails = await fetchCustomerDetails(value);

        if (customerDetails) {
          setAddNewBooking((prevFormData) => ({
            ...prevFormData,
            customer: value,
            name: customerDetails.name,
            phone: customerDetails.phone,
            cnic: customerDetails.cnic,
            city: customerDetails.city,
            address: customerDetails.address,
          }));
        }
        break;
      default:
        break;
    }
  };

  const handleInputChangePlot = async (e) => {
    const { name, value } = e.target;

    const fetchPlotDetails = async (plotId) => {
      try {
        const response = await fetch(`${baseUrl}/plot/${plotId}`);
        const data = await response.json();
        return data;
      } catch (error) {
        console.error("Error fetching Plot details:", error);
      }
    };

    switch (name) {
      case "plot":
        const PlotDetails = await fetchPlotDetails(value);

        if (PlotDetails) {
          setAddNewBooking((prevFormData) => ({
            ...prevFormData,
            plot: value,
            size: PlotDetails.size,
            size_unit: PlotDetails.size_unit,

            dimension: PlotDetails.dimension,
          }));
        }
        break;
      default:
        break;
    }
  };

  const toggleList = () => {
    setModalList(!modal_list);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setAddNewBooking({ ...AddBooking, document: file });
  };
  // Save data in database

  const handleAddBooking = async (e) => {
    e.preventDefault();
    setSaveButtonDisabled(true);
    const token = sessionStorage.getItem("token");
    const groupId = sessionStorage.getItem(`groupId`);
    const loginId = sessionStorage.getItem(`login`);

    if (!loginId) {
      console.error("User not logged in.");
      return;
    }

    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("user", loginId);
    formData.append("booking_no", BookingNumber);
    formData.append("receipt_no", AddBooking.receipt_no);
    formData.append("booking_date", AddBooking.booking_date);
    formData.append("customer", AddBooking.customer);
    formData.append("phone", AddBooking.phone);
    formData.append("cnic", AddBooking.cnic);
    formData.append("city", AddBooking.city);
    formData.append("address", AddBooking.address);
    formData.append("block", AddBooking.block);
    formData.append("plot", AddBooking.plot);
    formData.append("size", AddBooking.size);
    formData.append("size_unit", AddBooking.size_unit);
    formData.append("no_Of_installment", AddBooking.no_Of_installment  || 0);
    formData.append("dimension", AddBooking.dimension);
    formData.append("price", AddBooking.price);
    formData.append("total", AddBooking.total);
    formData.append("advance", AddBooking.advance  || 0);
    formData.append("balance", AddBooking.balance);
    formData.append("package_choice", AddBooking.package_choice  || '');
    formData.append("amount_package", AddBooking.amount_package);
    formData.append("promise_date", AddBooking.promise_date);
    formData.append("agent", AddBooking.agent || '');
    formData.append("commission_percent", AddBooking.commission_percent || 0);
    formData.append("commission_amount", AddBooking.commission_amount);
    formData.append("remarks", AddBooking.remarks);
    if (AddBooking.document !== null) {
      formData.append("document", AddBooking.document);
    }

    try {
      const response = await fetch(`${baseUrl}/booking/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (response.status === 201) {
        const data = await response.json();
        console.log("Installment Data After Added",data)
        // Fetch data from the table
        const tableRows = document.querySelectorAll("#inst_table tbody tr");
        const installmentsData = Array.from(tableRows).map((row) => {
          const columns = row.querySelectorAll("td");
          return {
            promise_date: new Date(columns[1].innerText.trim())
              .toISOString()
              .split("T")[0],
            amount: parseFloat(columns[2].innerText.trim()),
          };
        });

        // Prepare the data for the API request
        try {
          await Promise.all(
            installmentsData.map(async (installment) => {
              const installmentsResponse = await fetch(
                `${baseUrl}/installment-data/`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    promise_date: installment.promise_date,
                    amount: installment.amount,
                    booking: data.id,
                    plot: data.plot,
                    customer: data.customer,
                    user: data.user,
                    group: data.group,
                  }),
                }
              );

              if (installmentsResponse.status === 201) {
                console.log("Installment added successfully");
              } else {
                console.error(
                  "Failed to add installment:",
                  installmentsResponse.statusText
                );
              }
            })
          );

          setPrintButtonDisabled(false);
          setShowAnotherModal(false);
          setShowAlert(true);
          setSaveButtonDisabled(true);

          setTimeout(() => {
            setShowAnotherModal(true);
            setSaveButtonDisabled(true);
          }, 1000);

          setInstallments([]);
          setShowAlert(true);
          setModalList(false);
          setIsDataSubmitted(true);

          // Resetting form fields to initial values
          setAddNewBooking({
            // ... (rest of your initial form values)
          });
        } catch (error) {
          console.error("Error adding installments:", error);
        }
      } else {
        console.error("Failed to add the Booking:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding the Booking:", error);
    } finally {
      setSaveButtonDisabled(false);
    }
  };

  useEffect(() => {
    // Fetch necessary data based on active groupId
    const fetchData = async () => {
      try {
        const groupId = sessionStorage.getItem("groupId");
  
        if (!groupId) {
          console.error("Group ID not found in the session.");
          return;
        }
  
        // Fetch last inserted ID based on the active groupId
        const response = await fetch(
          `${baseUrl}/booking/max_booking_id/?group=${groupId}`
        );
        const data = await response.json();
        const maxId = data.max_id !== undefined ? parseInt(data.max_id) : 0;
        console.log("Maximum Booking Number",maxId)
        setBookingNumber(maxId + 1);
        console.log("Maximum Booking Number after Edit ",BookingNumber)
   
      } catch (error) {
        console.error("Error fetching Last Quotation ID:", error);
      }
    };
  
    fetchData();
  }, []);
  

  useEffect(() => {
    let timeout;
    if (showAlert) {
      timeout = setTimeout(() => {
        setShowAlert(false);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [showAlert]);

  const sortedInstallments = installments.sort((a, b) => {
    const dateA = new Date(a.promise_date);
    const dateB = new Date(b.promise_date);
    return dateB - dateA;
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Booking" breadcrumbItem="Generate Booking" />

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="AddBookingList">
                    <Row className="g-4 mb-3" style={{ padding: "20px" }}>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <form
                            className="tablelist-form"
                            onSubmit={handleAddBooking}
                            encType="multipart/form-data"
                          >
                            <div id="AddBookingList">
                              <Row className="g-4 mb-3">
                                <div className="row mb-3">
                                  <div className="col-md-2">
                                    <label
                                      htmlFor="booking_no"
                                      className="form-label"
                                    >
                                      Booking#
                                    </label>
                                    <input
                                      type="text"
                                      id="booking_no"
                                      name="booking_no"
                                      value={BookingNumber}
                                      className="form-control"
                                      placeholder="Booking Number"
                                      required
                                      onChange={handleInputChange} // Add this line
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <label
                                      htmlFor="p_type"
                                      className="form-label"
                                    >
                                      Receipt#
                                    </label>
                                    <input
                                      type="number"
                                      id="receipt_no"
                                      name="receipt_no"
                                      className="form-control"
                                      placeholder="Receipt#"
                                      required
                                      onChange={handleInputChange} // Add this line
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <label
                                      htmlFor="booking_date"
                                      className="form-label"
                                    >
                                      Booking Date
                                    </label>
                                    <input
                                      type="date"
                                      id="booking_date"
                                      name="booking_date"
                                      className="form-control"
                                      required
                                      value={AddBooking.booking_date}
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label
                                      htmlFor="company"
                                      className="form-label"
                                    >
                                      Customer
                                    </label>
                                    <select
                                      className="form-control"
                                      name="customer"
                                      id="customer"
                                      value={AddBooking.customer}
                                      required
                                      onChange={handleInputChangeCustomer}
                                    >
                                      <option value="">Choose Customer</option>
                                      {AddBookingData.map((customer) => (
                                        <option
                                          key={customer.id}
                                          value={customer.id}
                                        >
                                          {customer.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-3">
                                    <label
                                      htmlFor="name-field"
                                      className="form-label"
                                    >
                                      Address
                                    </label>
                                    <input
                                      type="text"
                                      id="address"
                                      name="address"
                                      value={AddBooking.address}
                                      className="form-control"
                                      readOnly
                                      placeholder="Address "
                                      required
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <div className="col-md-3">
                                    <label
                                      htmlFor="block-field"
                                      className="form-label"
                                    >
                                      Block
                                    </label>
                                    <select
                                      className="form-control"
                                      name="block"
                                      id="block"
                                      value={AddBooking.block}
                                      required
                                      onChange={handleInputChange}
                                    >
                                      <option value="">Choose Block</option>
                                      {addBlockData.map((block) => (
                                        <option key={block.id} value={block.id}>
                                          {block.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-2">
                                    <label
                                      htmlFor="plot-field"
                                      className="form-label"
                                    >
                                      Plot#
                                    </label>
                                    <select
                                      className="form-control"
                                      name="plot"
                                      id="plot"
                                      value={AddBooking.plot}
                                      required
                                      onChange={handleInputChangePlot}
                                    >
                                      <option value="">Choose Plot</option>
                                      {addPlotData.map((plot) => (
                                        <option key={plot.id} value={plot.id}>
                                          {plot.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                  <div className="col-md-2">
                                    <label
                                      htmlFor="size-field"
                                      className="form-label"
                                    >
                                      Size Value
                                    </label>
                                    <input
                                      type="number"
                                      id="size"
                                      name="size"
                                      value={AddBooking.size}
                                      className="form-control"
                                      placeholder="0.0"
                                      readOnly
                                      required
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label
                                      htmlFor="size_unit"
                                      className="form-label"
                                    >
                                      Size Unit
                                    </label>
                                    <input
                                      type="text"
                                      id="size_unit"
                                      name="size_unit"
                                      value={AddBooking.size_unit}
                                      className="form-control"
                                      placeholder="Marla"
                                      readOnly
                                      required
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-md-3">
                                    <label htmlFor="height">Dimension </label>
                                    <input
                                      type="text"
                                      id="dimension"
                                      name="dimension"
                                      className="form-control"
                                      value={AddBooking.dimension}
                                      placeholder="Dimension"
                                      required
                                      readOnly
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                </div>

                                <div className="row mb-3">
                                  <div className="col-md-2">
                                    <label htmlFor="price">Price</label>
                                    <input
                                      type="number"
                                      id="price"
                                      name="price"
                                      className="form-control"
                                      value={AddBooking.price}
                                      placeholder="0.00"
                                      onChange={handleInputChange}
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <label htmlFor="total">Total</label>
                                    <input
                                      type="number"
                                      id="total"
                                      name="total"
                                      className="form-control"
                                      value={AddBooking.total}
                                      placeholder="0.00"
                                      onChange={handleInputChange}
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <label htmlFor="advance">Advance</label>
                                    <input
                                      type="number"
                                      id="advance"
                                      name="advance"
                                      className="form-control"
                                      value={AddBooking.advance}
                                      placeholder="0.00"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-md-2">
                                    <label htmlFor="balance">Balance</label>
                                    <input
                                      type="number"
                                      id="balance"
                                      name="balance"
                                      className="form-control"
                                      value={AddBooking.balance}
                                      placeholder="0.00"
                                      readOnly
                                      onChange={handleInputChangeInstallment}
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <label
                                      htmlFor="no_Of_installment"
                                      className="form-label"
                                    >
                                      No Of Installment
                                    </label>
                                    <input
                                      type="text"
                                      id="no_Of_installment"
                                      name="no_Of_installment"
                                      value={AddBooking.no_Of_installment}
                                      className="form-control"
                                      placeholder="0.00"
                                      onChange={handleInputChangeInstallment}
                                    />
                                  </div>

                                  <div className="col-md-2">
                                    <label htmlFor="amount_package">
                                      Per/Installment
                                    </label>
                                    <input
                                      type="text"
                                      id="amount_package"
                                      name="amount_package"
                                      className="form-control"
                                      value={AddBooking.amount_package}
                                      placeholder="0.00"
                                      onChange={
                                        handleInputChangeInstallmentAmount
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-2">
                                    <label
                                      htmlFor="plot-field"
                                      className="form-label"
                                    >
                                      Package
                                    </label>
                                    <select
                                      className="form-control"
                                      name="package_choice"
                                      id="package_choice"
                                      value={AddBooking.package_choice}
                                      required
                                      onChange={handleInputChange}
                                    >
                                      <option value="">Choose Package</option>
                                      {addPackageData.map((data) => (
                                        <option key={data.id} value={data.id}>
                                          {data.package_choice}
                                        </option>
                                      ))}
                                    </select>
                                  </div>

                                  <div className="col-md-2">
                                    <label
                                      htmlFor="promise_date"
                                      className="form-label"
                                    >
                                      Promise Date
                                    </label>
                                    <input
                                      type="date"
                                      id="promise_date"
                                      name="promise_date"
                                      value={AddBooking.promise_date}
                                      className="form-control"
                                      onChange={handleInputChange}
                                    />
                                  </div>
                                  <div className="col-md-4  align-self-end">
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleGenerateAndAdd}
                                      disabled={
                                        isGenerateButtonDisabled ||
                                        !AddBooking.plot ||
                                        !AddBooking.customer ||
                                        !AddBooking.block ||
                                        !AddBooking.promise_date ||
                                        !AddBooking.no_Of_installment ||
                                        !AddBooking.booking_date
                                      }
                                    >
                                      Generate Schedule
                                    </button>
                                  </div>
                                  <div className="row"></div>
                                </div>
                                <div className="row mb-3">
                                  <div className="col-md-6">
                                    <div className="row mb-3">
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="agent"
                                          className="form-label"
                                        >
                                          Reffer By
                                        </label>
                                        <select
                                          className="form-control"
                                          name="agent"
                                          id="agent"
                                          value={AddBooking.agent}
                                          onChange={handleInputChange}
                                        >
                                          <option value="0">
                                            Choose Refferal
                                          </option>
                                          {addAgentData.map((agent) => (
                                            <option
                                              key={agent.id}
                                              value={agent.id}
                                            >
                                              {agent.name}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="promise_date"
                                          className="form-label"
                                        >
                                          Commision%
                                        </label>
                                        <input
                                          type="text"
                                          id="commission_percent"
                                          name="commission_percent"
                                          value={AddBooking.commission_percent}
                                          className="form-control"
                                          placeholder="0.00"
                                          onChange={handleInputChange}
                                      
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label
                                          htmlFor="promise_date"
                                          className="form-label"
                                        >
                                          Commision Amount
                                        </label>
                                        <input
                                          type="text"
                                          id="commission_amount"
                                          name="commission_amount"
                                          value={AddBooking.commission_amount}
                                          className="form-control"
                                          onChange={handleInputChange}
                                          placeholder="0.00"
                                        />
                                      </div>

                                      <div className="col-md-6">
                                        <label
                                          htmlFor="promise_date"
                                          className="form-label"
                                        >
                                          Remarks
                                        </label>
                                        <input
                                          type="text"
                                          id="remarks"
                                          name="remarks"
                                          value={AddBooking.remarks}
                                          className="form-control"
                                          onChange={handleInputChange}
                                        />
                                      </div>
                                      <div className="col-md-8">
                                        <label htmlFor="p_img">
                                        Attachement
                                        </label>
                                        <input
                                          type="file"
                                          id="document"
                                          name="document"
                                          onChange={handleImageChange}
                                          className="form-control"
                                          placeholder="Choose Document"
                                        />
                                      </div>
                                      <div className="col-md-4">
                                        <Button
                                          color="success"
                                          className="add-btn mt-4"
                                          id="create-btn"
                                          onClick={
                                            handleAnotherModalButtonClick
                                          }
                                          disabled={isBookingButtonDisabled}
                                        >
                                          Booking
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="col-md-12 align-self-end">
                                      <div
                                        className="table-responsive table-card mt-3 mb-1"
                                        id="inst_table"
                                      >
                                        <table
                                          className="table align-middle table-nowrap"
                                          id="table"
                                        >
                                          <thead className="table-light">
                                            <tr>
                                              <th
                                                className="sort"
                                                data-sort="id"
                                              >
                                                Sr#
                                              </th>
                                              <th
                                                className="sort"
                                                data-sort="code"
                                              >
                                                Months
                                              </th>
                                              <th
                                                className="sort"
                                                data-sort="name"
                                              >
                                                Amount
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody
                                            className="list form-check-all"
                                            id="myTable"
                                          >
                                            {sortedInstallments.map((installment) => (
                                              <tr key={installment.id}>
                                                <td>{installment.id}</td>
                                                <td>
                                                  {installment.promise_date}
                                                </td>
                                                <td>{installment.amount}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                          <tr>
                                            <td></td>
                                            <td style={{ color: "red" }}>
                                              Total Amount
                                            </td>
                                            <td style={{ color: "red" }}>
                                              {getTotalAmount()}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row mb-4">
                                  <div className="col-md-4"></div>
                                </div>
                              </Row>
                            </div>
                          </form>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <Card style={{ display: "none" }}>
                <CardBody>
                  <div id="users">
                    <SimpleBar style={{ height: "242px" }} className="mx-n3">
                      <ListGroup className="list mb-0" flush>
                        <ListGroupItem data-id="4">
                          <div className="d-flex"></div>
                        </ListGroupItem>
                      </ListGroup>
                    </SimpleBar>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal of Success  */}

      {/* Modal of Success */}
      {showAlert && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header bg-soft-success">
                  <h5 className="modal-title text-success">Success Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAlert(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    <div className="mb-4">
                      <i className="mdi mdi-checkbox-marked-circle-outline display-4 text-success"></i>
                    </div>
                    <h4 className="alert-heading">Well done!</h4>
                    <p className="mb-0">Booking Added successfully</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showAnotherModal && (
        <div
          className="modal-backdrop"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", display: "block" }}
        >
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirmation Alert</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowAnotherModal(false)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="d-flex justify-content-between">
                    <p>Which Kind Of Operation You Perform ?</p>
                    <Button
                      color="success"
                      className="add-btn mt-4"
                      id="create-btn"
                      onClick={handlePrintModalButtonClick}
                      disabled={printButtonDisabled}
                    >
                      Print
                    </Button>

                    <Button
                      color="primary"
                      className="add-btn mt-4"
                      id="create-btn"
                      onClick={handleAddBooking}
                      disabled={saveButtonDisabled} // Use the state variable to disable the button
                    >
                      Save
                    </Button>
                    <Button
                      color="danger"
                      className="add-btn mt-4"
                      id="create-btn"
                      onClick={() => setShowAnotherModal(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Booking;
