import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const RadialChart = ({ soldPlots, unsoldPlots, cancelPlots }) => {
  const data = [
    { angle: soldPlots, label: "Sold Plots", color: "#099680" },
    { angle: unsoldPlots, label: "Unsold Plots", color: "#4aa3ff" },
    { angle: cancelPlots, label: "Cancel Plots", color: "#FF0000" },
  ];
  const [plotData, setPlotData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/addplot/`);
        if (!response.ok) {
          throw new Error("Failed to fetch plot data");
        }
        const data = await response.json();
        setPlotData(data);
      } catch (error) {
        console.error("Error fetching plot data:", error);
      }
    };

    fetchData();
  }, []);

  const getPlotCount = (status) => {
    return plotData.filter((plot) => plot.status === status).length;
  };

  const soldCount = getPlotCount("Sold");
  const unsoldCount = getPlotCount("Unsold");
  const cancelCount = getPlotCount("Cancel");
  const totalPlots = plotData.length;

  const series = [soldCount, unsoldCount, cancelCount];
  const labels = [];

  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 10,
          size: "45%",
        },
        track: {
          show: true,
          strokeWidth: "70%",
          margin: 12,
        },
        dataLabels: {
          name: {
            fontSize: "27px",
          },
          value: {
            fontSize: "20px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: function () {
              return totalPlots;
            },
          },
        },
      },
    },
    labels,
    colors: (() => {
      const colorMap = {
        "Sold": "#4CAF50", // Green
        "Unsold": "#2196F3", // Blue
        "Cancel": "#FF0000", // Red
      };
      return labels.map(label => colorMap[label]);
    })(),
  };

  return (
    <React.Fragment>
      <div>Total Plots: {totalPlots}</div>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={350}
        className="apex-charts"
      />
    </React.Fragment>
  );
};

export default RadialChart;
