import React, { useState, useEffect } from "react";
// import logolight from "../../assets/images/saharalogo.png";
// import logodark from "../../assets/images/saharalogo.png";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { Spinner } from "react-bootstrap";

import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { env } from "../../config";
const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const Login = (props) => {
  document.title = "Login";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = Yup.object({
    email: Yup.string().required("Please Enter Your username"),
    password: Yup.string().required("Please Enter Your Password"),
  });

  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    let logoutTimer; 
  
    const setLogoutTimer = () => {

      logoutTimer = setTimeout(() => {
        sessionStorage.clear();
        navigate("/login");
      }, 20 * 60 * 1000);
    };
  
    try {
      const response = await fetch(`${baseUrl}/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
  
      if (response.ok) {
        const data = await response.json();
        // console.log("Login success:", data);
  
        // Store user data in session
        sessionStorage.setItem("login", data.id);
        sessionStorage.setItem("email", values.email);
        sessionStorage.setItem("admin", data.admin);
        sessionStorage.setItem("groupId", data.admin);
        sessionStorage.setItem("user", JSON.stringify(data));
        // console.log("loginId:", data.id);
  
        // Fetch user permissions
        const permissionsResponse = await fetch(
          `${baseUrl}/users/${data.id}/permissions/`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${data.id}`,
            },
          }
        );
  
        if (permissionsResponse.ok) {
          const permissionsData = await permissionsResponse.json();
          // console.log("User permissions:", permissionsData);
          sessionStorage.setItem(
            "permissions",
            JSON.stringify(permissionsData)
          );
        } else {
          console.error(
            "Failed to fetch user permissions:",
            permissionsResponse.status
          );
        }
  
        // Set the logout timer after successful login
        setLogoutTimer();
  
    
  
        // Redirect to the dashboard after successful login
        console.log("Redirecting to dashboard...");
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        console.error("Login failed:", errorData);
        setErrorMessage("User not found or incorrect username/password.");
        setTimeout(() => {
          setErrorMessage("");
        }, 2000);
      }
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage("Login error occurred.");
    } finally {
      setLoading(false);
    }
  
    // Clear the existing timer and set a new one on user activity
    document.addEventListener("mousemove", () => {
      clearTimeout(logoutTimer);
      setLogoutTimer();
    });
  
    document.addEventListener("keydown", () => {
      clearTimeout(logoutTimer);
      setLogoutTimer();
    });
  };
  

  // Function to check if the user is already logged in
  const isLoggedIn = () => {
    const token = sessionStorage.getItem("token");
    return !!token; // Returns true if the token is present
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    document.body.className = "bg-pattern";
    return function cleanup() {
      document.body.className = "";
    };
  }, []);

  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account pages my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={8} xl={4}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <div className="text-center">
                      {/* <Link to="/">
                        <img
                          src={logodark}
                          alt=""
                          height="40"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="24"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link> */}
                    </div>
                    <h4 className="font-size-18 text-muted mt-2 text-center">
                      Sign in
                    </h4>

                    <Form
                      className="form-horizontal"
                      onSubmit={formik.handleSubmit}
                    >
                      {errorMessage && (
                        <div className="mb-3">
                          <Alert color="danger">{errorMessage}</Alert>
                        </div>
                      )}

                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              invalid={
                                formik.touched.email && formik.errors.email
                              }
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <FormFeedback type="invalid">
                                {formik.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Label className="form-label">Password</Label>
                            <Input
                              name="password"
                              type="password"
                              placeholder="Enter Password"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.password}
                              invalid={
                                formik.touched.password &&
                                formik.errors.password
                              }
                            />
                            {formik.touched.password &&
                              formik.errors.password ? (
                              <FormFeedback type="invalid">
                                {formik.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-label form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col className="col-7">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link
                                  to="/auth-recoverpw"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock"></i> Forgot your
                                  password?
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div className="d-grid mt-4">
                            <button
                              className="btn btn-primary waves-effect waves-light"
                              type="submit"
                              disabled={loading} // Disable the button during loading
                            >
                              {loading ? <Spinner animation="border" size="sm" /> : "Log In"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">
                  © {new Date().getFullYear()} Developed by Allied Biz Tech
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Login;
