import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import saharalogo from "../../assets/images/saharalogo.png";
import { Link, useLocation } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "./print.css";
import { env } from "../../config";

const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const PrintCustomer = () => {
  const [Customer, setCustomer] = useState([]);
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");
  const [dataFetched, setDataFetched] = useState(false);

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const formattedDate = now.toLocaleDateString();
      const formattedTime = now.toLocaleTimeString();
      setCurrentDate(formattedDate);
      setCurrentTime(formattedTime);
    };

    updateDateTime();

    const fetchData = async () => {
      try {
        const response = await fetch(`${baseUrl}/customer/`);
        if (response.ok) {
          const data = await response.json();
          setCustomer(data);
          setDataFetched(true);
          console.log("Fetched data:", data);
        } else {
          console.error("Error fetching Customer:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching Customer:", error);
      }
    };

    updateDateTime();
    fetchData();

    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (dataFetched) {
      window.print();
    }
  }, [dataFetched]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="">
                <div className="d-flex">
                  <ReactHTMLTableToExcel
                    id="table-to-excel"
                    table="table"
                    className="btn btn-primary d-print-none"
                    filename="Customer Data"
                    sheet="sheet1"
                    buttonText="Export to Excel"
                  />
                </div>
              </CardHeader>
              <CardBody className="print-visible">
                <div id="customerList">
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div className="text-center">
                        <Link to="/">
                          <img
                            src={saharalogo}
                            alt=""
                            height="80"
                            className="auth-logo logo-dark mx-auto"
                          />
                        </Link>
                      </div>
                    </div>

                    <div className="col-md-5 print-visible">
                      <div style={{ textAlign: "center" }}>
                        <h3>
                          <strong>UgiDeveloper</strong>
                        </h3>
                      </div>
                    </div>
                    <div className="col-md-4 text-center print-visible">
                      <p>
                        <strong>Date:</strong> {currentDate}{" "}
                        <strong>Time:</strong> {currentTime}
                      </p>

                      <p>
                        <strong>Contact Us:</strong>
                      </p>
                    </div>
                  </div>
                  <div className="row print-visible">
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                    <div className="col-md-5 print-visible">
                      <div style={{ textAlign: "center" }}>
                        <h5>
                          <strong>Customer List</strong>
                        </h5>
                      </div>
                      <p
                        style={{ textAlign: "center" }}
                        className="print-visible"
                      ></p>
                    </div>
                    <div className="col-md-1 print-visible"></div>
                    <div className="col-md-3 print-visible">
                      <div
                        className="border-div"
                        style={{ marginTop: "1rem" }}
                      ></div>
                    </div>
                  </div>
                  <div className="table-responsive table-card mt-3 mb-1 print-visible">
                    <table
                      className="table align-middle table-nowrap m-auto"
                      id="table"
                    >
                      <thead className="table-light">
                        <tr>
                          <th scope="col" style={{ width: "50px" }}></th>
                          <th className="sort" data-sort="id">
                            Sr #
                          </th>
                          <th className="sort" data-sort="code">
                            Customer Name
                          </th>
                          <th className="sort" data-sort="name">
                            Father Name
                          </th>
                          <th className="sort" data-sort="credi_limit">
                            CNIC
                          </th>
                          <th className="sort" data-sort="bill_limit">
                            Phone
                          </th>
                          <th className="sort" data-sort="bill_limit">
                            Email
                          </th>
                          <th className="sort" data-sort="bill_limit">
                            Image
                          </th>
                          <th className="sort" data-sort="bill_limit">
                            Document
                          </th>
                        </tr>
                      </thead>
                      <tbody className="list form-check-all" id="myTable">
                        {Customer.map((customer) => (
                          <tr key={customer.id}>
                            <th scope="row"></th>
                            <td className="id">{customer.id}</td>
                            <td className="c_name">{customer.c_name}</td>
                            <td className="f_name">{customer.f_name}</td>
                            <td className="name">{customer.cnic}</td>
                            <td className="name">{customer.phone}</td>
                            <td className="name">{customer.email}</td>
                            <td className="img">
                              <img
                                src={customer.image}
                                alt="Customer"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  marginTop: "20px",
                                }}
                              />
                            </td>
                            <td className="img">
                              <img
                                src={customer.document}
                                alt="Customer"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  borderRadius: "50%",
                                  marginTop: "20px",
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrintCustomer;
