import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { env } from "../../config";
import { Link, useNavigate } from "react-router-dom";
const baseUrl = env.NEXT_PUBLIC_API_BASE_URL;

const JournalReport = () => {
  const [AccountData, setAccountData] = useState([]);
  const [TransactionData, setTransactionData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState(true);
  const [searchClicked, setSearchClicked] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [printDisabled, setPrintDisabled] = useState(true);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleAccountChange = (e) => {
    setSelectedAccount(e.target.value);
  };

  function getAccountName(accountId) {
    const Account = AccountData.find((account) => account.id === accountId);
    return Account ? Account.name : "N/A";
  }

  const fetchData = async () => {
    try {
      const fetchAccounts = await fetch(`${baseUrl}/accounts/`);
      if (fetchAccounts.ok) {
        const data = await fetchAccounts.json();
        setAccountData(data);
      } else {
        console.error("Error fetching account data:", fetchAccounts.statusText);
      }
    } catch (error) {
      console.error("Error fetching account data:", error);
    }
  };

  const fetchTransactionData = async () => {
    try {
      const groupId = sessionStorage.getItem("groupId");
      if (!groupId) {
        console.error("Group ID not found in the session.");
        return;
      }
      const fetchTransactions = await fetch(`${baseUrl}/transactions/`);
      if (fetchTransactions.ok) {
        const data = await fetchTransactions.json();
        const filteredData = data.filter(
          (item) =>
            item.group.toString() === groupId &&
            (!startDate || new Date(item.entry_date) >= new Date(startDate)) &&
            (!endDate || new Date(item.entry_date) <= new Date(endDate)) &&
            (!selectedAccount ||
              item.account.toString() === selectedAccount.toString())
        );
        setTransactionData(filteredData);
      } else {
        console.error(
          "Error fetching transaction data:",
          fetchTransactions.statusText
        );
      }
    } catch (error) {
      console.error("Error fetching transaction data:", error);
    }
  };

  const handleSearch = async () => {
    try {
      if (!selectedAccount) {
        console.error("No account selected.");
        return;
      }
      const response = await fetch(`${baseUrl}/transactions/`);
      if (response.ok) {
        const data = await response.json();
        const filteredData = data.filter((item) => {
          const entryDate = new Date(item.entry_date);
          const startDateObj = startDate && new Date(startDate);
          const endDateObj = endDate && new Date(endDate);
          return (
            (!startDateObj || entryDate >= startDateObj) &&
            (!endDateObj || entryDate <= endDateObj) &&
            item.account === selectedAccount.toString()
          );
        });
        setTransactionData(filteredData);
        setPrintDisabled(false);
        setSearchClicked(true);
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    if (searchClicked) {
      fetchTransactionData();
    }
  }, [selectedAccount, startDate, endDate, searchClicked]);

  //   // Print Function
  const navigate = useNavigate();
  const handlePrint = () => {
    if (selectedAccount) {
      navigate("/journal-report/account-report-print/", {
        state: {
          filteredData: TransactionData,
        },
      });
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Report" breadcrumbItem="Account Report" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <div className="row">
                      <div className="col-md-4">
                        <label htmlFor="account" className="form-label">
                          Account
                        </label>
                        <select
                          className="form-control"
                          name="account"
                          id="account"
                          onChange={handleAccountChange}
                        >
                          <option value="">Choose Account</option>
                          {AccountData.map((account) => (
                            <option key={account.id} value={account.id}>
                              {account.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-3">
                        <label htmlFor="start_date">Start Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="start_date"
                          value={startDate}
                          onChange={handleStartDateChange}
                        />
                      </div>

                      <div className="col-md-3">
                        <label htmlFor="end_date">End Date</label>
                        <input
                          type="date"
                          className="form-control"
                          name="end_date"
                          value={endDate}
                          onChange={handleEndDateChange}
                        />
                      </div>
                      <div className="col-md-1">
                        <label htmlFor="end_date">
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </label>
                        <Button
                          color="success"
                          className="add-btn"
                          id="create-btn"
                          type="submit"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>
                      <div className="col-md-1">
                        <label>&nbsp;&nbsp;&nbsp;&nbsp;</label>
                        <Button
                          color="primary"
                          className="add-btn"
                          onClick={handlePrint}
                          disabled={printDisabled}
                        >
                          <span>Print</span>
                        </Button>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <h4>
                        <strong>Account Report</strong>
                      </h4>
                    </div>
                    <div className="table-responsive table-card mt-3 mb-1">
                      <table
                        className="table align-middle table-nowrap"
                        id="table"
                      >
                        <thead className="table-light">
                          <tr>
                            <th>Sr #</th>
                            <th>Account</th>
                            <th>Plot</th>
                            <th>Receipt</th>
                            <th>Installment</th>
                            <th>Voucher#</th>
                            <th>B_Account</th>
                            <th>Type</th>
                            <th>Ref# </th>
                            <th>Remarks</th>
                            <th>Amount</th>
                            <th>Entry Date</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all" id="myTable">
                          {TransactionData.length > 0 ? (
                            TransactionData.map((transaction, index) => (
                              <tr key={transaction.id}>
                                <td>{index + 1}</td>
                                <td className="id">
                                  {getAccountName(transaction.account)}
                                </td>
                                <td>{transaction.plot}</td>
                                <td>{transaction.receipt}</td>
                                <td>{transaction.installment}</td>
                                <td>{transaction.voucher_no}</td>
                                <td>{getAccountName(transaction.b_account)}</td>
                                <td>{transaction.type}</td>
                                <td>{transaction.ref_no}</td>
                                <td>{transaction.remarks}</td>
                                <td>{transaction.amount}</td>
                                <td>{transaction.entry_date}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="12" className="text-center">
                                No data available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JournalReport;
